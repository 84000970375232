import { isNil } from 'lodash-es';
import { ModQuizViewQuizData, ModQuizViewQuizQuery } from '../moodleTypes/_functions/mod_quiz_view_quiz';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizViewQuiz(quizid?: number) {
  return useMoodleAPI<ModQuizViewQuizQuery, ModQuizViewQuizData>(
    !isNil(quizid) && {
      wsfunction: 'mod_quiz_view_quiz',
      quizid
    }
  );
}
