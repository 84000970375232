import React from 'react';
import clsx from 'clsx';
import { ButtonPropsWithoutRef } from 'utils/types';
import s from './PaginationNumberButton.module.scss';

export enum PaginationNumberButtonSize {
  Medium = 'medium',
  Small = 'small'
}

export interface PaginationNumberButtonProps extends Pick<ButtonPropsWithoutRef, 'disabled' | 'onClick'> {
  className?: string;
  selected?: boolean;
  completed?: boolean;
  size?: PaginationNumberButtonSize;
  children?: React.ReactNode;
}

export const PaginationNumberButton = ({
  className,
  selected,
  completed,
  disabled,
  size = PaginationNumberButtonSize.Medium,
  onClick,
  children
}: PaginationNumberButtonProps) => {
  return (
    <button
      className={clsx(s.Button, s[`Button_${size}`], className, {
        [s.Button_selected]: selected,
        [s.Button_completed]: completed,
        [s.Button_disabled]: disabled
      })}
      disabled={disabled}
      onClick={onClick}>
      <span>{children}</span>
    </button>
  );
};
