import { useCallback, useState } from 'react';
import { IQuiz } from '../moodleTypes/_functions/mod_quiz_get_quizzes_by_courses';
import { IAttempt } from '../moodleTypes/Attempt';
import { useQuizStartAttempt } from './useQuizStartAttempt';

export interface UseStartQuizProps {
  quizId?: IQuiz['id'];
  attemptInProgress?: IAttempt | null;
}

export function useStartQuiz({ quizId, attemptInProgress }: UseStartQuizProps) {
  const [error, setError] = useState<string>('');

  const { mutate, isLoading, error: queryError } = useQuizStartAttempt();

  const startQuiz = useCallback(async () => {
    if (attemptInProgress) {
      return attemptInProgress;
    } else {
      if (quizId) {
        const result = await mutate({ quizid: quizId });

        if (result?.data?.attempt.id) {
          setError('');
          return result.data.attempt;
        } else if (result?.data?.warnings?.length) {
          setError(result.data.warnings.map(({ message }) => message).join(', '));
        }
      }
    }
  }, [attemptInProgress, mutate, quizId]);

  return {
    startQuiz,
    isLoading,
    error: error || queryError
  };
}
