import React, { forwardRef, Ref, useEffect, useRef } from 'react';
import clsx from 'clsx';
import s from './ScormContent.module.scss';

export interface ScormContentProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Заголовок лекции
   */
  title?: string;
  moduleId: number;
  scoId: number;
  iframeRef: Ref<HTMLIFrameElement>;
  hidden?: boolean;
}

export const ScormContent = forwardRef<HTMLDivElement, ScormContentProps>(function ScormContent(
  { className, title, moduleId, scoId, iframeRef, hidden },
  ref
) {
  const buttonRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    buttonRef.current?.click();
  }, []);

  return (
    <div className={clsx(s.ScormContent, className)} ref={ref}>
      <form
        target="scormIframe"
        action="https://lms.idevo.ru/mod/scorm/player.php"
        method="post"
        style={{ display: 'none' }}>
        <input type="hidden" name="scoid" value={scoId} />
        <input type="hidden" name="cm" value={moduleId} />
        <input type="submit" ref={buttonRef} />
      </form>

      <iframe
        title={title}
        allowFullScreen={true}
        name={'scormIframe'}
        src={`https://lms.idevo.ru/mod/scorm/player.php`}
        ref={iframeRef}
        style={{ opacity: hidden ? '0' : '1' }}
      />
    </div>
  );
});
