import React, { ComponentProps } from 'react';
import clsx from 'clsx';
import { CardCourse, CardFile, CardLesson, CardSize } from '../index';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { mediaQuery, useMediaQueryResult } from '../../../hooks/useMediaQueryResult';
import s from './CardGrid.module.scss';

export interface CardGridProps<ComponentType extends typeof CardCourse | typeof CardLesson | typeof CardFile> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы
   */
  classes?: {
    card?: string;
  };
  /**
   * Состояние загрузки
   */
  loading?: boolean;
  /**
   * Компонент карточки, который необходимо отображать (CardCourse, CardLesson)
   */
  component: ComponentType;
  /**
   * Элементы карточек в виде пропсов переданного component
   */
  items?: (ComponentProps<ComponentType> & { component?: ComponentType })[];
}

export function CardGrid<ComponentType extends typeof CardCourse | typeof CardLesson | typeof CardFile>(
  props: CardGridProps<ComponentType>
) {
  const { className, classes, loading, items, component } = props;

  const isTablet = useMediaQueryResult(mediaQuery.desktopMediumDown);
  const isMobile = useIsMobile();

  const Component = component as React.ElementType;

  return (
    <div className={clsx(s.CardGrid, className)}>
      {loading
        ? Array(isMobile ? 4 : isTablet ? 8 : 12)
            .fill(null)
            .map((_, index) => (
              <Component
                className={clsx(s.CardGrid__card, classes?.card)}
                size={isMobile ? CardSize.medium : CardSize.large}
                loading={true}
                key={index}
              />
            ))
        : items?.map(({ component: itemComponent, ...item }, index) => {
            const ItemComponent = (itemComponent as React.ElementType) || Component;

            return (
              <ItemComponent {...item} className={clsx(s.CardGrid__card, item.className, classes?.card)} key={index} />
            );
          })}
    </div>
  );
}
