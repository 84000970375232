import { generatePath } from 'react-router-dom';
import { COURSE_PAGE_ROUTE, FILE_PAGE_ROUTE, LESSON_PAGE_ROUTE, QUIZ_PAGE_ROUTE } from './routes';

export class PathBuilder {
  static getCoursePath = (courseId: string | number) =>
    generatePath(COURSE_PAGE_ROUTE, {
      courseId
    });

  static getLessonPath = (courseId: string | number, lessonId: string | number) =>
    generatePath(LESSON_PAGE_ROUTE, {
      courseId,
      lessonId
    });

  static getFilePath = (fileId: string | number) =>
    generatePath(FILE_PAGE_ROUTE, {
      fileId
    });

  static getQuizAttemptPath = (
    courseId: string | number,
    quizId: string | number,
    attemptId: number,
    moduleId: number,
    page?: number
  ) => generatePath(QUIZ_PAGE_ROUTE, { courseId, quizId }) + `?attempt=${attemptId}&cmid=${moduleId}&page=${page}`;
}
