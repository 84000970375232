import { isNil } from 'lodash-es';
import {
  ModQuizGetQuizAccessInformationData,
  ModQuizGetQuizAccessInformationQuery
} from '../moodleTypes/_functions/mod_quiz_get_quiz_access_information';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizAccessInformation(quizid?: number) {
  return useMoodleAPI<ModQuizGetQuizAccessInformationQuery, ModQuizGetQuizAccessInformationData>(
    !isNil(quizid) && {
      wsfunction: 'mod_quiz_get_quiz_access_information',
      quizid
    }
  );
}
