/* --------- auth routes --------- */

export const LOGIN_PAGE_ROUTE = '/login';

/* --------- user routes --------- */

export const HOME_PAGE_ROUTE = '/';
export const COURSES_PAGE_ROUTE = '/courses';
export const TESTS_PAGE_ROUTE = '/tests';
export const MESSAGES_PAGE_ROUTE = '/messages';
export const VIDEO_PAGE_ROUTE = '/video';
export const PROFILE_PAGE_ROUTE = '/me';

export const COURSE_PAGE_PARAM = 'courseId';
export const COURSE_PAGE_ROUTE = `/courses/:${COURSE_PAGE_PARAM}`;

export const LESSON_PAGE_PARAM = 'lessonId';
export const LESSON_PAGE_ROUTE = `${COURSE_PAGE_ROUTE}/lessons/:${LESSON_PAGE_PARAM}`;

export const FILE_PAGE_PARAM = 'fileId';
export const FILE_PAGE_ROUTE = `/files/:${FILE_PAGE_PARAM}`;

export const QUIZ_PAGE_PARAM = 'quizId';
export const QUIZ_PAGE_ROUTE = `${COURSE_PAGE_ROUTE}/quiz/:${QUIZ_PAGE_PARAM}`;

/* --------- info routes --------- */

export const POLICY_PAGE_ROUTE = '/policy';
export const RULES_PAGE_ROUTE = '/rules';
export const SERVER_ERROR_PAGE_ROUTE = '/500';
export const NOT_FOUND_ERROR_PAGE_ROUTE = '/404';
