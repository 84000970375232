import { Dispatch, SetStateAction } from 'react';

export enum QuizQuestionType {
  MULTI_CHOICE_SET = 'multichoiceset', // почему-то не работает, вместо него приходит multichoice
  MULTI_CHOICE = 'multichoice',
  DDW_TO_SHARD = 'ddwtoshard',
  MATCH = 'match',
  SHORT_ANSWER = 'shortanswer',
  NUMERICAL = 'numerical',
  TRUE_FALSE = 'truefalse',
  ESSAY = 'essay',
  DESCRIPTION = 'description'
}

export interface QuizQuestionTypeProps {
  id: string;
  title: string;
  prompt: string;
  description: string;
  content: Element | null;
  sequenceCheck: { name: string; value: string } | null;
  setPageProcessData: Dispatch<SetStateAction<QuizAnswerType[]>>;
  settings: object | null;
}

export type QuizAnswerType = {
  name: string;
  value: string;
  label?: string;
};
