import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { isFunction } from 'lodash-es';
import { useFactoryRef } from '../../../hooks/useFactoryRef';
import { usePrevious } from '../../../hooks/usePrevious';
import { notNull } from '../../../utils/notNull';
import { QueryParamContext } from './QueryParamContext';

export interface UseBatchedQueryParamOptions<T> {
  defaultValue?: T;
  getter?: (value: string | null) => T;
  setter?: (value: T) => string | null;
}

export type UseBatchedQueryParamReturn<T> = [T, Dispatch<SetStateAction<T>>];

export const defaultSetter = <T = string | null>(value: T) => String(value);

export const useBatchedQueryParam = <T = string | null>(
  key: string,
  props: UseBatchedQueryParamOptions<T> = {}
): UseBatchedQueryParamReturn<T> => {
  const context = useContext(QueryParamContext);
  const { searchParams, setParamValue } = notNull(context);

  const { defaultValue, getter, setter } = useFactoryRef(() => ({
    defaultValue: props.defaultValue,
    getter: props.getter,
    setter: props.setter || defaultSetter
  }));

  const transformedDefaultValue = useMemo(
    () => (defaultValue !== undefined ? setter(defaultValue) : null),
    [defaultValue, setter]
  );

  const value = useMemo(() => {
    const paramValue = searchParams.get(key) || transformedDefaultValue;
    if (getter) {
      return getter(searchParams.get(key) || transformedDefaultValue);
    }
    return paramValue;
  }, [searchParams, key, transformedDefaultValue, getter]);

  const prevValue = usePrevious(value) as T;

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (_value: T | ((prevValue: T) => T)) => {
      let newValue = _value as T;
      if (isFunction(_value)) {
        newValue = _value(prevValue);
      }

      let transformedValue: string | null = newValue ? String(newValue) : null;
      if (newValue !== null) {
        transformedValue = setter(newValue);
      }

      if (transformedValue) {
        setParamValue(key, transformedValue);
      } else {
        setParamValue(key, null);
      }
    },
    [key, prevValue, setParamValue, setter]
  );

  return [value as T, setValue];
};
