import React, { ComponentProps, ElementType, JSX, ReactNode } from 'react';
import clsx from 'clsx';
import { PolyExtends } from '../../../utils/types';
import { ButtonDefaultComponent } from '../../Button';
import s from './AttachmentBase.module.scss';

export const AttachmentDefaultComponent = 'div' as const;
export type AttachmentDefaultComponentType = typeof AttachmentDefaultComponent;

export interface AttachmentBasePropsSelf<ComponentType extends ElementType = AttachmentDefaultComponentType> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Слот для превью/иконки
   */
  preview?: ReactNode;
  /**
   * Слот для кнопок с действиями
   */
  actions?: ReactNode;
  /**
   * Название
   */
  title?: string;
  /**
   * Имя файла
   */
  filename?: string;
  /**
   * Слот для доп. информации (размер и т.д.)
   */
  caption?: string;
  /**
   * Интерактивность карточки
   */
  interactive?: boolean;
  /**
   * Реф на корневой DOM-элемент
   */
  innerRef?: React.ComponentProps<ComponentType>['ref'];
}

export type AttachmentBaseProps<ComponentType extends ElementType = AttachmentDefaultComponentType> = PolyExtends<
  ComponentType,
  AttachmentBasePropsSelf<ComponentType>,
  ComponentProps<ComponentType>
>;

export function AttachmentBase(props: AttachmentBaseProps<'div'>): JSX.Element;
export function AttachmentBase<ComponentType extends ElementType>(
  props: AttachmentBaseProps<ComponentType>
): JSX.Element;
export function AttachmentBase<ComponentType extends ElementType>(props: AttachmentBaseProps<ComponentType>) {
  const { className, component, preview, actions, title, filename, caption, interactive, ...restProps } = props;

  const Component = (component || ButtonDefaultComponent) as ElementType;

  return (
    <Component
      className={clsx(s.AttachmentBase, className, {
        [s.AttachmentBase_interactive]: interactive
      })}
      {...restProps}>
      <div className={s.AttachmentBase__preview}>{preview}</div>

      <div className={s.AttachmentBase__content}>
        {title && <span className={s.AttachmentBase__title}>{title}</span>}
        {filename && <span className={s.AttachmentBase__filename}>{filename}</span>}
        <span className={s.AttachmentBase__caption}>{caption}</span>
      </div>

      <div className={s.AttachmentBase__actions}>{actions}</div>
    </Component>
  );
}
