import React, { forwardRef } from 'react';
import { MoodleContent } from 'components/MoodleContent';
import { Loader, LoaderSize } from 'components/Loader';
import s from './LessonPageContentPage.module.scss';

export interface LessonPageContentPageProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Контент модуля лекции
   */
  content?: string;
  /**
   * Состояние загрузки
   */
  isLoading?: boolean;
}

export const LessonPageContentPage = forwardRef<HTMLDivElement, LessonPageContentPageProps>(
  function LessonPageContentLesson({ className, content, isLoading }, ref) {
    if (!content || isLoading) {
      return null;
    }

    return (
      <div className={className}>
        {isLoading ? (
          <Loader className={s.Loader} size={LoaderSize.large} />
        ) : (
          content && (
            <MoodleContent className={s.Content} ref={ref}>
              {content}
            </MoodleContent>
          )
        )}
      </div>
    );
  }
);
