import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModQuizGetAttemptDataData } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_data';
import { QuizAnswerType } from 'store/quiz/quiz.types';
import { useQuizProcessAttempt } from 'store/moodleAPI/hooks/useQuizProcessAttempt';
import { SERVER_ERROR_PAGE_ROUTE } from '../../../app/routes';

export type UseQuizSubmitProps = {
  attemptData: ModQuizGetAttemptDataData | null;
  pageProcessData: QuizAnswerType[];
};

export type SubmitQuizProps = {
  finish?: boolean;
};

export function useQuizSubmit({ attemptData, pageProcessData }: UseQuizSubmitProps) {
  const navigate = useNavigate();

  const { mutate: processAttemptRun, isLoading } = useQuizProcessAttempt();
  const [error, setError] = useState<any | null>(null);

  /**
   * Так как при вызове мутации processAttemptRun происходит ревалидация ВСЕХ запросов, а тест уже сдад,
   * то возникает ошибка, и обработчик ошибки ниже редиректит на 404, поэтому такой костыль
   * Возможно стоит добавить в мутацию параметр revalidate, который будет определять, рефетчить ли квери после
   * запроса или нет
   */
  const finishedRef = useRef(false);

  const submitQuiz = async ({ finish }: SubmitQuizProps = {}) => {
    if (attemptData?.attempt.id) {
      try {
        finishedRef.current = !!finish;

        await processAttemptRun({
          attemptid: attemptData.attempt.id,
          data: [
            ...pageProcessData.map(({ name, value }) => ({
              name,
              value
            }))
          ],
          finishattempt: finish ? 1 : 0
        });
      } catch (e) {
        setError(e);
        navigate(SERVER_ERROR_PAGE_ROUTE);
      }
    }
  };

  const isFinished = finishedRef.current;

  return {
    submitQuiz,
    isLoading,
    error,
    isFinished
  };
}
