import { ElementType } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import { AttachmentBase } from '../AttachmentBase';
import { ReactComponent as DocIcon } from '../assets/doc.svg';
import { ReactComponent as XlsIcon } from '../assets/xls.svg';
import { ReactComponent as PdfIcon } from '../assets/pdf.svg';
import { ReactComponent as FileIcon } from '../assets/file.svg';
import { sizeFormat } from '../../../utils/sizeFormat';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../IconButton';
import { Icons } from '../../Icons';
import { useHover } from '../../../hooks/useHover';
import s from './AttachmentDownload.module.scss';

export interface AttachmentDownloadProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Имя файла
   */
  filename: string;
  /**
   * Ссылка на скачивание файла
   */
  url: string;
  /**
   * Размер файла
   */
  size?: number;
}

const iconExtensions = new Map<ElementType, string[]>([
  [DocIcon, ['doc', 'docx']],
  [XlsIcon, ['xls', 'xlsx']],
  [PdfIcon, ['pdf']]
]);

const extensionIcon: { [key: string]: ElementType } = {};

iconExtensions.forEach((item, icon) => {
  item.forEach((ext) => {
    extensionIcon[ext] = icon;
  });
});

export const AttachmentDownload: ReactFCC<AttachmentDownloadProps> = (props) => {
  const { className, filename, size, url } = props;

  const ext = filename.split('.')[filename.split('.').length - 1];
  const IconComponent = extensionIcon[ext] || FileIcon;

  const { hovered, ...hoverProps } = useHover();

  return (
    <AttachmentBase
      className={clsx(s.AttachmentDownload, className)}
      component={'a'}
      href={url}
      download={filename}
      preview={<IconComponent className={s.AttachmentDownload__extIcon} />}
      title={filename}
      caption={size ? sizeFormat(size) : ''}
      interactive={true}
      actions={
        <IconButton
          component={'div'}
          icon={Icons.DOWNLOAD}
          variant={IconButtonVariant.ghost}
          size={IconButtonSize.small}
          hovered={hovered}
        />
      }
      {...hoverProps}
    />
  );
};
