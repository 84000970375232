import { isNil } from 'lodash-es';
import {
  ModQuizGetUserAttemptsData,
  ModQuizGetUserAttemptsQuery,
  ModQuizGetUserAttemptsQueryArgs
} from '../moodleTypes/_functions/mod_quiz_get_user_attempts';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizUserAttempts({ quizid, ...props }: Partial<ModQuizGetUserAttemptsQueryArgs> = {}) {
  return useMoodleAPI<ModQuizGetUserAttemptsQuery, ModQuizGetUserAttemptsData>(
    !isNil(quizid) && {
      wsfunction: 'mod_quiz_get_user_attempts',
      quizid,
      ...props
    }
  );
}
