import { useMemo } from 'react';
import { useMoodleAPI } from '../useMoodleAPI';
import {
  CoreCourseGetCoursesByFieldData,
  CoreCourseGetCoursesByFieldQuery
} from '../moodleTypes/_functions/core_course_get_courses_by_field';
import { htmlToInlineText, htmlToPageContent } from '../../../utils/stringUtils';
import {
  CoreCourseGetContentsData,
  CoreCourseGetContentsQuery
} from '../moodleTypes/_functions/core_course_get_contents';
import { ModuleName } from '../moodleTypes/Module';
import { insertAccessKeyIntoUrls } from '../../../utils/insertAccessKeyIntoUrls';
import { useSiteInfo } from '../../../app/providers/siteInfo';
import { FAKE_USER_TOKEN } from '../../../config';

export interface UsePageContentProps {
  pageName: string;
  hasModules?: boolean;
  inline?: boolean;
}

const options = {
  wstoken: FAKE_USER_TOKEN
};

export const usePageContent = (props: UsePageContentProps) => {
  const { pageName, hasModules, inline } = props;

  const { data: coursesData, isLoading: isCoursesLoading } = useMoodleAPI<
    CoreCourseGetCoursesByFieldQuery,
    CoreCourseGetCoursesByFieldData
  >(
    {
      wsfunction: 'core_course_get_courses_by_field',
      field: 'shortname',
      value: pageName
    },
    options
  );

  const course = coursesData?.courses[0];

  const { data: courseData, isLoading: courseLoading } = useMoodleAPI<
    CoreCourseGetContentsQuery,
    CoreCourseGetContentsData
  >(
    !!course?.id &&
      hasModules && {
        wsfunction: 'core_course_get_contents',
        courseid: course.id
      },
    options
  );

  const { data } = useSiteInfo();
  const userprivateaccesskey = data?.userprivateaccesskey;

  const resources = useMemo(() => {
    if (!courseData || !userprivateaccesskey) {
      return [];
    }

    return courseData
      .flatMap((item) => item.modules)
      .filter(
        (item) =>
          // 1. Проверяем, что в модуле имеет тип "Файл"
          // 2. В модуле есть файлы
          // 3. Есть ссылка на файл
          item.modname === ModuleName.resource && item.contents.length !== 0
      )
      .map((item) => ({
        ...item,
        contents: item.contents.filter((i) => i.type === 'file' && !!i.fileurl)
      }));
  }, [courseData, userprivateaccesskey]);

  const attachments = useMemo(() => {
    return resources
      .filter((item) => item.contents[0].mimetype?.startsWith('image') && item.contents[0].fileurl)
      .map((resource) => {
        const attachment = resource.contents[0];
        return {
          name: resource.name,
          description: htmlToInlineText(resource.description || ''),
          url: insertAccessKeyIntoUrls(attachment.fileurl as string, userprivateaccesskey)
        };
      });
  }, [resources, userprivateaccesskey]);

  const content = inline ? htmlToInlineText(course?.summary || '') : htmlToPageContent(course?.summary || '');

  return {
    course,
    resources,
    attachments,
    title: course?.fullname,
    content,
    isLoading: isCoursesLoading || courseLoading
  };
};
