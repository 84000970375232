import { RefObject } from 'react';
import { useToggle } from '../../hooks/useToggle';
import { ContextMenuItem } from './ContextMenu';

export interface UseContextMenuProps {
  items: ContextMenuItem[];
  referenceRef: RefObject<any>;
}

export const useContextMenu = (props: UseContextMenuProps) => {
  const { items, referenceRef } = props;

  const [contextMenuOpen, { unset: closeContextMenu, toggle: toggleContextMenu }] = useToggle(false);

  return {
    items,
    referenceRef,
    isOpen: contextMenuOpen,
    onClose: closeContextMenu,
    toggleContextMenu
  };
};
