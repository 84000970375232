import React from 'react';
import { AppRoutes } from 'pages';
import { Head } from '../components/Head';

export default function App() {
  return (
    <React.Fragment>
      <Head />
      <AppRoutes />
    </React.Fragment>
  );
}
