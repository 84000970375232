import { useMemo } from 'react';
import { isNil } from 'lodash-es';
import { useMoodleAPI } from '../useMoodleAPI';
import {
  CoreCourseGetContentsData,
  CoreCourseGetContentsQuery
} from '../moodleTypes/_functions/core_course_get_contents';
import {
  ModScormGetScormsByCoursesData,
  ModScormGetScormsByCoursesQuery
} from '../moodleTypes/_functions/mod_scorm_get_scorms_by_courses';
import { IModule, ModuleName } from '../moodleTypes/Module';
import { insertAccessKeyIntoUrls } from '../../../utils/insertAccessKeyIntoUrls';
import { useSiteInfo } from '../../../app/providers/siteInfo';
import {
  ModQuizGetQuizzesByCoursesData,
  ModQuizGetQuizzesByCoursesQuery
} from '../moodleTypes/_functions/mod_quiz_get_quizzes_by_courses';

export interface ReadableModule extends IModule {
  intro?: string;
  launch?: number;
  revision?: number;
  scormId?: number;
  resources?: ModuleResource[];
}

export interface ModuleResource {
  name: string;
  url: string;
  size: number;
}

export const useReadableModules = (courseId?: number) => {
  const { data } = useSiteInfo();
  const userprivateaccesskey = data?.userprivateaccesskey;

  const {
    data: courseData,
    isLoading: courseLoading,
    isFetched: courseFetched,
    error: courseError
  } = useMoodleAPI<CoreCourseGetContentsQuery, CoreCourseGetContentsData>(
    !!courseId && {
      wsfunction: 'core_course_get_contents',
      courseid: courseId
    }
  );

  const {
    data: scormsData,
    isLoading: scormsLoading,
    isFetched: scormsFetched,
    error: scormsError
  } = useMoodleAPI<ModScormGetScormsByCoursesQuery, ModScormGetScormsByCoursesData>(
    !!courseId && {
      wsfunction: 'mod_scorm_get_scorms_by_courses',
      courseids: [courseId]
    }
  );

  const {
    data: quizzesData,
    isLoading: quizzesLoading,
    isFetched: quizzesFetched,
    error: quizzesError
  } = useMoodleAPI<ModQuizGetQuizzesByCoursesQuery, ModQuizGetQuizzesByCoursesData>(
    !isNil(courseId) && {
      wsfunction: 'mod_quiz_get_quizzes_by_courses',
      courseids: [courseId]
    }
  );

  const modules = useMemo(() => {
    if (!courseData || !scormsData || !quizzesData) {
      return [];
    }

    return courseData
      .flatMap((item) => item.modules)
      .filter(
        (item) =>
          item.modname === ModuleName.page ||
          item.modname === ModuleName.scorm ||
          item.modname === ModuleName.resource ||
          item.modname === ModuleName.quiz
      )
      .map((item) => {
        if (item.modname === ModuleName.page) {
          return {
            ...item,
            intro: item.description
          };
        } else if (item.modname === ModuleName.scorm) {
          const scorm = scormsData.scorms.find((scorm) => scorm.coursemodule === item.id);
          return {
            ...item,
            intro: scorm?.intro,
            scormId: item.instance,
            revision: scorm?.revision,
            launch: scorm?.launch
          } as ReadableModule;
        } else if (item.modname === ModuleName.resource) {
          const resources = item.contents
            .filter((i) => i.type === 'file' && !!i.fileurl)
            .map((item) => {
              const { filename, fileurl, filesize } = item;

              return {
                name: filename,
                url: insertAccessKeyIntoUrls(fileurl as string, userprivateaccesskey),
                size: filesize
              };
            });

          return {
            ...item,
            resources
          };
        } else {
          return item;
        }
      }) as unknown as ReadableModule[];
  }, [courseData, scormsData, quizzesData, userprivateaccesskey]);

  return {
    courseData: courseData,
    modules,
    isLoading: courseLoading || scormsLoading || quizzesLoading,
    isFetched: courseFetched && scormsFetched && quizzesFetched,
    error: courseError || scormsError || quizzesError
  };
};
