import { Helmet } from 'react-helmet-async';

export interface HeadProps {
  title?: string;
  description?: string;
}

export const siteTitle = 'Аудит-Центр Групп';

export const Head = ({ title = '', description = '' }: HeadProps) => {
  return (
    <Helmet title={title ? `${title} — ${siteTitle}` : undefined} defaultTitle={siteTitle}>
      <meta name="description" content={description} />
    </Helmet>
  );
};
