import { useMoodleAPILazyQuery } from '../useMoodleAPILazyQuery';
import { ModScormViewScormData, ModScormViewScormQuery } from '../moodleTypes/_functions/mod_scorm_view_scorm';

export const useViewScorm = () => {
  const requestMoodleApi = useMoodleAPILazyQuery();

  return (id?: number | string | false) => {
    if (id) {
      return requestMoodleApi<ModScormViewScormQuery, ModScormViewScormData>({
        wsfunction: 'mod_scorm_view_scorm',
        scormid: id
      });
    }
  };
};
