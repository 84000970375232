import React from 'react';
import { Head } from '../../components/Head';
import { Heading, HeadingSize } from '../../components/Heading';
import { Text } from '../../components/Text';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Button, ButtonStretch, ButtonVariant } from '../../components/Button';
import { Icons } from '../../components/Icons';
import { useAuth } from '../../app/providers/auth';
import { useSiteInfo } from '../../app/providers/siteInfo';
import s from './ProfilePage.module.scss';

export function ProfilePage() {
  const { logOut, loading } = useAuth();

  const { data } = useSiteInfo();
  const email = data?.username;
  const username = data?.fullname;

  const isMobile = useIsMobile();

  return (
    <>
      <Head title={'Профиль'} />

      <div className={s.ProfilePage}>
        <div className={s.ProfilePage__header}>
          <Heading size={isMobile ? HeadingSize.H2 : HeadingSize.H3}>Профиль</Heading>
        </div>

        <div className={s.ProfilePage__content}>
          <Heading className={s.ProfilePage__username} size={HeadingSize.H4}>
            {username}
          </Heading>
          <Text className={s.ProfilePage__email}>{email}</Text>
          <Button
            variant={ButtonVariant.tertiary}
            leftIcon={Icons.LOGOUT}
            stretch={isMobile ? ButtonStretch.fill : ButtonStretch.fit}
            isLoading={loading}
            onClick={() => logOut()}>
            Выход
          </Button>
        </div>
      </div>
    </>
  );
}
