import React from 'react';
import { Loader, LoaderSize } from 'components/Loader/Loader';
import s from './FullScreenLoader.module.scss';

export const FullScreenLoader = () => {
  return (
    <div className={s.FullScreenLoader}>
      <Loader size={LoaderSize.large} />
    </div>
  );
};
