import { MouseEvent, ReactNode } from 'react';
import clsx, { ClassValue } from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import s from './TabItem.module.scss';

export interface TabItemProps {
  /**
   * Дополнительный css-класс
   */
  className?: ClassValue;
  /**
   * Идентификатор вкладки
   */
  id: string;
  /**
   * Состояние активность
   */
  active?: boolean;
  /**
   * Содержимое вкладки
   */
  children?: ReactNode | string;
  /**
   * Обработчик нажатия на вкладку
   */
  onClick?: (id: TabItemProps['id'], e: MouseEvent<HTMLButtonElement>) => void;
}

export const TabItem: ReactFCC<TabItemProps> = (props) => {
  const { children, className, active, id, onClick } = props;

  return (
    <button
      className={clsx(s.TabItem, className, {
        [s.TabItem_active]: active
      })}
      onClick={(e) => onClick?.(id, e)}>
      {children}
    </button>
  );
};
