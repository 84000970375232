import { CompletionState } from '../moodleTypes/Module';
import { ModQuizGetQuizRequiredQtypesData } from '../moodleTypes/_functions/mod_quiz_get_quiz_required_qtypes';
import { QuizQuestionType } from '../../quiz/quiz.types';
import { useQuizAccessEnabled } from './useQuizAccessEnabled';

export type UseQuizAccessOptionsProps = {
  error?: string | null;
  activerulenames: string[];
  accessrules: string[];
  preventaccessreasons: string[];
  completionState?: CompletionState;
  questionTypesData?: ModQuizGetQuizRequiredQtypesData | null;
};

const RULE_QUIZACCESS_ONESESSION = 'quizaccess_onesession';

const AVAILABLE_QUIZ_TYPES = [
  QuizQuestionType.MULTI_CHOICE,
  QuizQuestionType.TRUE_FALSE,
  QuizQuestionType.SHORT_ANSWER,
  QuizQuestionType.NUMERICAL,
  QuizQuestionType.ESSAY,
  QuizQuestionType.DESCRIPTION
];

export function useQuizAccessOptions({
  error,
  activerulenames,
  completionState,
  questionTypesData,
  accessrules,
  preventaccessreasons
}: UseQuizAccessOptionsProps) {
  let accessDisabledOneSession =
    error ??
    (activerulenames.includes(RULE_QUIZACCESS_ONESESSION)
      ? `Данный тест не может быть пройден в приложении, потому что он имеет правила доступа, не поддерживаемые приложением: ${RULE_QUIZACCESS_ONESESSION}`
      : null);

  if (completionState !== CompletionState.Incomplete) {
    accessDisabledOneSession = null;
  }

  const accessDisabledRequiredTypesArray: string[] = [];

  questionTypesData?.questiontypes.forEach((type) => {
    if (!AVAILABLE_QUIZ_TYPES.includes(type as QuizQuestionType)) {
      accessDisabledRequiredTypesArray.push(type);
    }
  });

  const accessDisabledRequiredTypes = accessDisabledRequiredTypesArray.length
    ? `Данный тест не может быть пройден в приложении, потому что он имеет типы заданий, не поддерживаемые приложением: ${accessDisabledRequiredTypesArray.join(
        ', '
      )}`
    : null;

  const { isAccessEnabled, accessClosedReason } = useQuizAccessEnabled({
    preventaccessreasons,
    accessrules
  });

  return {
    isAccessEnabled,
    accessDisabledByOptions: accessDisabledOneSession ?? accessDisabledRequiredTypes,
    accessClosedReason
  };
}
