import { useCallback, useState } from 'react';
import {
  ModQuizProcessAttemptData,
  ModQuizProcessAttemptQuery,
  ModQuizProcessAttemptQueryArgs
} from '../moodleTypes/_functions/mod_quiz_process_attempt';
import { useMoodleAPILazyQuery } from '../useMoodleAPILazyQuery';

export const useQuizProcessAttempt = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const requestMoodleApi = useMoodleAPILazyQuery();

  const mutate = useCallback(
    async (props: ModQuizProcessAttemptQueryArgs) => {
      try {
        setIsLoading(true);
        return await requestMoodleApi<ModQuizProcessAttemptQuery, ModQuizProcessAttemptData>({
          wsfunction: 'mod_quiz_process_attempt',
          ...props
        });
      } catch (err) {
        setError(err as string);
      } finally {
        setIsLoading(false);
      }
    },
    [requestMoodleApi]
  );

  return {
    mutate,
    isLoading,
    error
  };
};
