import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import { Icon, IconSize } from '../Icon';
import { Icons } from '../Icons';
import { ButtonPropsWithoutRef } from '../../utils/types';
import s from './Tag.module.scss';

export enum TagVariant {
  primary = 'primary',
  secondary = 'secondary'
}

export enum TagSize {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export interface TagProps extends ButtonPropsWithoutRef {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Вариант тега ("primary", "secondary")
   */
  variant?: TagVariant;
  /**
   * Размер тега ("large", "medium", "small")
   */
  size?: TagSize;
  /**
   * Состояние выбранности
   */
  selected?: boolean;
  /**
   * Состояние дизейбла
   */
  disabled?: boolean;
  /**
   * Отображает галочку слева
   */
  checked?: boolean;
  /**
   * Делает тег интерактивным
   */
  interactive?: boolean;
}

export const Tag: ReactFCC<TagProps> = (props) => {
  const {
    children,
    className,
    variant = TagVariant.primary,
    size = TagSize.large,
    selected,
    disabled,
    checked,
    interactive,
    ...restProps
  } = props;

  return (
    <button
      className={clsx(s.Tag, className, s[`Tag_size_${size}`], s[`Tag_variant_${variant}`], {
        [s.Tag_selected]: selected,
        [s.Tag_disabled]: disabled,
        [s.Tag_hasCheck]: checked
      })}
      disabled={disabled || !interactive}
      {...restProps}>
      {checked && (
        <Icon
          className={s.Tag__icon}
          icon={Icons.CHECK}
          size={size === TagSize.large ? IconSize.medium : IconSize.small}
        />
      )}

      <div className={s.Tag__content}>{children}</div>
    </button>
  );
};
