import { useCallback, useState } from 'react';
import {
  ModQuizStartAttemptData,
  ModQuizStartAttemptQuery,
  ModQuizStartAttemptQueryArgs
} from '../moodleTypes/_functions/mod_quiz_start_attempt';
import { useMoodleAPILazyQuery } from '../useMoodleAPILazyQuery';

export function useQuizStartAttempt() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const requestMoodleApi = useMoodleAPILazyQuery();

  const mutate = useCallback(
    async (props: ModQuizStartAttemptQueryArgs) => {
      try {
        setIsLoading(true);
        return await requestMoodleApi<ModQuizStartAttemptQuery, ModQuizStartAttemptData>({
          wsfunction: 'mod_quiz_start_attempt',
          ...props
        });
      } catch (err) {
        setError(err as string);
      } finally {
        setIsLoading(false);
      }
    },
    [requestMoodleApi]
  );

  return {
    mutate,
    isLoading,
    error
  };
}
