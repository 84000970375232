import { useCallback, useEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import isEqual from 'lodash-es/isEqual';
import { usePrevious } from 'hooks/usePrevious';
import { useAxiosClient } from 'app/providers/axiosClient';
import { useSingleTimeout } from 'hooks/useSingleTimeout';
import { MoodleError } from './MoodleError';

export interface IMoodleQueryResult<D> {
  isLoading: boolean;
  isFetched: boolean;
  error: MoodleError | Error | null;
  data: D | null;
}

export interface MoodleQueryOptions<D> {
  wstoken?: string;
  throwOnError?: boolean;
  refreshInterval?: (data: IMoodleQueryResult<D>['data'], error: IMoodleQueryResult<D>['error']) => number;
}

const initialQueryResult = {
  isLoading: false,
  isFetched: false,
  error: null,
  data: null
};

// запрос не выполняется, пока не передан аргумент query,
// это позволяет устраивать цепь из запросов:
// успешное выполнение (data) предыдущих запросов формирует query для последующих
export function useMoodleAPI<Q, D>(query?: Q | null | false, options: MoodleQueryOptions<D> = {}) {
  const { wstoken, throwOnError = true, refreshInterval } = options;

  const axiosClient = useAxiosClient();
  const prevQuery = usePrevious(query);
  const { showBoundary } = useErrorBoundary();

  const [queryResult, setQueryResult] = useState<IMoodleQueryResult<D>>(initialQueryResult);
  const { data: qResultData, isLoading: qResultLoading, error: qResultError } = queryResult;

  const abortController = useRef<AbortController>();

  const runQuery = useCallback(
    (cb?: () => void) => {
      if (abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      axiosClient
        .request<D>({ data: { ...query, wstoken }, signal: abortController.current.signal })
        .then(({ data }) => {
          setQueryResult((queryResult) => ({ ...queryResult, isLoading: false, isFetched: true, data }));
        })
        .catch((errorRaw) => {
          if (errorRaw.name !== 'CanceledError') {
            const error = typeof errorRaw === 'string' ? new MoodleError(errorRaw) : errorRaw;
            setQueryResult((queryResult) => ({ ...queryResult, isLoading: false, error }));
            console.error(error);
            if (throwOnError) {
              showBoundary(error);
            }
          }
        })
        .finally(() => cb?.());
    },
    [axiosClient, query, showBoundary, throwOnError, wstoken]
  );

  useEffect(() => {
    if (!query && (qResultLoading || qResultData || qResultError)) {
      // останавливает текущий запрос
      if (qResultLoading && abortController.current) {
        abortController.current.abort();
      }
      setQueryResult((queryResult) => ({ ...queryResult, isLoading: false, error: null, data: null }));
    }
  }, [qResultData, qResultError, qResultLoading, query]);

  useEffect(() => {
    const newQuery = query && prevQuery && !isEqual(query, prevQuery);

    if ((query && !qResultLoading && !qResultData && !qResultError) || newQuery) {
      // останавливает текущий запрос
      if (qResultLoading && abortController.current) {
        abortController.current.abort();
      }

      setQueryResult((queryResult) =>
        newQuery ? { ...queryResult, isLoading: true, error: null, data: null } : { ...queryResult, isLoading: true }
      );

      runQuery();
    }
  }, [prevQuery, qResultData, qResultError, qResultLoading, query, runQuery]);

  const refreshIntFn = useRef(refreshInterval);
  const refreshIntRef = useRef(0);
  const refreshTO = useSingleTimeout();

  /**
   * Переопределение интервала поллинга
   */
  useEffect(() => {
    refreshIntRef.current = refreshIntFn.current?.(qResultData, qResultError) || 0;
  }, [qResultData, qResultError]);

  /**
   * Поллинг
   */
  useEffect(() => {
    refreshTO.clear();

    if (!qResultLoading && refreshIntRef.current) {
      refreshTO.set(() => {
        runQuery();
      }, refreshIntRef.current);
    }

    return () => {
      refreshTO.clear();
    };
  }, [refreshTO, runQuery, qResultLoading]);

  const clearResult = useCallback(() => {
    setQueryResult(initialQueryResult);
  }, []);

  return {
    ...queryResult,
    clearResult
  };
}
