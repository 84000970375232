import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Logo } from '../../components/Logo';
import { Heading, HeadingSize } from '../../components/Heading';
import { Text, TextColor, TextVariant } from '../../components/Text';
import { RichInput } from '../../components/Input';
import { Button, ButtonSize } from '../../components/Button';
import { useAuth } from '../../app/providers/auth';
import { LOCAL_STORAGE_KEY_NEXT } from '../../app/localStorageKeys';
import { Icons } from '../../components/Icons';
import { Tooltip } from '../../components/Tooltip';
import { Link } from '../../components/Link';
import { COURSE_SYSTEM_AUTH, usePageContent } from '../../store/moodleAPI';
import s from './LoginPage.module.scss';

interface LoginFields {
  username: string;
  password: string;
}

export function LoginPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoggedIn, logIn } = useAuth();
  const [searchParams] = useSearchParams();
  const next = searchParams.get('next');

  const [passwordVisible, setPasswordVisible] = useState(false);

  const { title, content, isLoading } = usePageContent({ pageName: COURSE_SYSTEM_AUTH, inline: true });

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm<LoginFields>({
    defaultValues: {
      username: '',
      password: ''
    },
    reValidateMode: 'onChange'
  });

  const [username, password] = watch(['username', 'password']);

  const onSubmit: SubmitHandler<LoginFields> = useCallback(
    async (data) => {
      try {
        setLoading(true);
        clearErrors();
        await logIn(data.username, data.password);
      } catch (err: any) {
        setError('password', {
          message: err?.message
        });
      } finally {
        setLoading(false);
      }
    },
    [clearErrors, logIn, setError]
  );

  useEffect(() => {
    // устанавливает целевую страницу для возвращения после успешной авторизации
    localStorage.setItem(LOCAL_STORAGE_KEY_NEXT, next || '/');
  }, [next]);

  // редирект авторизованного пользователя на целевую страницу
  if (isLoggedIn()) return <Navigate to={next || '/'} replace />;

  if (isLoading) return null;

  return (
    <div className={s.LoginPage}>
      <Logo className={s.LoginPage__logo} />

      <div className={s.LoginPage__greeting}>
        <Heading className={s.LoginPage__heading} size={HeadingSize.H1}>
          {title}
        </Heading>

        <Text colorVariant={TextColor.SECONDARY_70}>{content}</Text>
      </div>

      <form className={s.LoginPage__form} onSubmit={handleSubmit(onSubmit)}>
        <Heading className={s.LoginPage__formHeading} size={HeadingSize.H3}>
          Авторизация
        </Heading>

        <RichInput
          className={s.LoginPage__input}
          type={'text'}
          autoCapitalize={'none'}
          placeholder={'Логин'}
          errorMessage={errors.username?.message}
          registration={register('username', {
            required: 'Поле обязательно к заполнению'
          })}
        />
        <RichInput
          className={s.LoginPage__input}
          type={passwordVisible ? 'text' : 'password'}
          autoCapitalize={'none'}
          placeholder={'Пароль'}
          errorMessage={errors.password?.message}
          registration={register('password', {
            required: 'Поле обязательно к заполнению'
          })}
          rightIcon={passwordVisible ? Icons.EYE_CONTUR_CLOSED : Icons.EYE_CONTUR_OPEN}
          onRightIconClick={() => setPasswordVisible((value) => !value)}
        />

        <div className={s.LoginPage__formControls}>
          <Tooltip
            className={s.LoginPage__tooltipContainer}
            classes={{
              content: s.LoginPage__tooltip
            }}
            title={'Восстановление пароля'}
            content={
              <div>
                Обратитесь в службу поддержки по телефону <Link href={'tel:8 (812) 448-01-48'}>8 (812) 448-01-48</Link>
              </div>
            }
            placement={'right'}>
            <Text variant={TextVariant.BODY_S} colorVariant={TextColor.SECONDARY_60}>
              Не можете войти?
            </Text>
          </Tooltip>

          <Button
            type={'submit'}
            className={s.LoginPage__button}
            size={ButtonSize.large}
            disabled={!username || !password}
            isLoading={loading}>
            Войти
          </Button>
        </div>
      </form>
    </div>
  );
}
