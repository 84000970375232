import { useCallback } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useAxiosClient } from '../../app/providers/axiosClient';

export type IMoodleLazyQueryResult<D> =
  | {
      error: null;
      data: D;
    }
  | {
      error: string;
      data: null;
    };

export function useMoodleAPILazyQuery() {
  const axiosClient = useAxiosClient();
  const { showBoundary } = useErrorBoundary();

  return useCallback(
    async <Q, D>(query: Q): Promise<IMoodleLazyQueryResult<D>> => {
      try {
        const { data } = await axiosClient.request<D>({ data: query });

        return {
          data,
          error: null
        };
      } catch (error: any) {
        console.error(error);
        showBoundary(error);

        return {
          data: null,
          error: error as string
        };
      }
    },
    [axiosClient, showBoundary]
  );
}
