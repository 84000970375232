function env<T = string | undefined>(variable: string | undefined, defaultValue?: T) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

export const API_URL = env(process.env.REACT_APP_API_URL, 'https://lms.idevo.ru')!;

export const FAKE_USER_TOKEN = '1437a08d977c78523d2ca32eeaf84122';
