import { isNil } from 'lodash-es';
import {
  ModQuizGetAttemptAccessInformationData,
  ModQuizGetAttemptAccessInformationQuery,
  ModQuizGetAttemptAccessInformationQueryArgs
} from '../moodleTypes/_functions/mod_quiz_get_attempt_access_information';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizAttemptAccessInformation({
  quizid,
  attemptid
}: Partial<ModQuizGetAttemptAccessInformationQueryArgs> = {}) {
  return useMoodleAPI<ModQuizGetAttemptAccessInformationQuery, ModQuizGetAttemptAccessInformationData>(
    !isNil(quizid) &&
      !isNil(attemptid) && {
        wsfunction: 'mod_quiz_get_attempt_access_information',
        quizid,
        attemptid
      }
  );
}
