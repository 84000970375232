import React, { useCallback, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {
  COURSE_PAGE_ROUTE,
  COURSES_PAGE_ROUTE,
  FILE_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  LESSON_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  NOT_FOUND_ERROR_PAGE_ROUTE,
  POLICY_PAGE_ROUTE,
  PROFILE_PAGE_ROUTE,
  QUIZ_PAGE_ROUTE,
  RULES_PAGE_ROUTE,
  SERVER_ERROR_PAGE_ROUTE,
  TESTS_PAGE_ROUTE,
  VIDEO_PAGE_ROUTE
} from 'app/routes/routes';
import { useAuth } from '../providers/auth';
import { useScrollTopOnRouteChange } from '../../hooks/useScrollTopOnRouteChange';
import { LoginPage } from '../../pages/login';
import { HomePage } from '../../pages/home';
import { PolicyPage } from '../../pages/policy';
import { RulesPage } from '../../pages/rules';
import { ErrorPage, ErrorVariant } from '../../pages/error';
import { TestsPage } from '../../pages/tests';
import { CoursesPage } from '../../pages/courses';
import { ProfilePage } from '../../pages/profile';
import { CoursePage } from '../../pages/course';
import { LessonPage } from '../../pages/lesson';
import { UserLayout } from '../../pages/_layouts';
import { FilePage } from '../../pages/file';
import { QuizPage } from '../../pages/quiz';
import { useResetBoundaryOnRouteChange } from '../../hooks/useResetBoundaryOnRouteChange';

export function AppRoutes() {
  const { isLoggedIn, logOut } = useAuth();
  const { pathname } = useLocation();

  useScrollTopOnRouteChange();
  useResetBoundaryOnRouteChange();

  const processMoodleMessages = useCallback(
    (e: MessageEvent<any>) => {
      if (isLoggedIn() && e?.data?.type === 'LOGOUT') {
        logOut();
      }
    },
    [isLoggedIn, logOut]
  );

  useEffect(() => {
    // Добавляем обработку системных сообщений из мудла
    window.addEventListener('message', processMoodleMessages);

    return () => window.removeEventListener('message', processMoodleMessages);
  }, [logOut, processMoodleMessages]);

  return (
    <Routes>
      <Route path={LOGIN_PAGE_ROUTE} element={<LoginPage />} />

      {isLoggedIn() ? (
        <>
          <Route element={<UserLayout />}>
            <Route path={HOME_PAGE_ROUTE} element={<HomePage />} />
            <Route path={COURSES_PAGE_ROUTE} element={<CoursesPage />} />
            <Route path={COURSE_PAGE_ROUTE} element={<CoursePage />} />
            <Route path={LESSON_PAGE_ROUTE} element={<LessonPage />} />
            <Route path={TESTS_PAGE_ROUTE} element={<TestsPage />} />
            {/* У /messages и /video пока что стоит страница с тестами, т.к. там все равно заглушка */}
            <Route path={MESSAGES_PAGE_ROUTE} element={<TestsPage />} />
            <Route path={VIDEO_PAGE_ROUTE} element={<TestsPage />} />
            <Route path={PROFILE_PAGE_ROUTE} element={<ProfilePage />} />
            <Route path={FILE_PAGE_ROUTE} element={<FilePage />} />
          </Route>

          <Route element={<UserLayout showHeader={false} showFooter={false} />}>
            <Route path={QUIZ_PAGE_ROUTE} element={<QuizPage />} />
          </Route>

          <Route path={POLICY_PAGE_ROUTE} element={<PolicyPage />} />
          <Route path={RULES_PAGE_ROUTE} element={<RulesPage />} />
          <Route path={SERVER_ERROR_PAGE_ROUTE} element={<ErrorPage variant={ErrorVariant.server_error} />} />
          <Route path={NOT_FOUND_ERROR_PAGE_ROUTE} element={<ErrorPage variant={ErrorVariant.not_found} />} />

          <Route path={'*'} element={<ErrorPage variant={ErrorVariant.not_found} />} />
        </>
      ) : (
        // auth guard
        <Route path={'*'} element={<Navigate to={`${LOGIN_PAGE_ROUTE}?next=${pathname}`} replace />} />
      )}
    </Routes>
  );
}
