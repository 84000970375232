import { ReactFCC } from '../../utils/ReactFCC';
import { Head } from '../../components/Head';
import { InfoLayout } from '../_layouts/InfoLayout';
import { HOME_PAGE_ROUTE, RULES_PAGE_ROUTE } from '../../app/routes';
import { Heading, HeadingSize } from '../../components/Heading';
import { COURSE_SYSTEM_RULES, usePageContent } from '../../store/moodleAPI';
import { FullScreenLoader } from '../../components/Loader';
import s from './RulesPage.module.scss';

const breadcrumbs = [
  {
    children: 'Главная',
    to: HOME_PAGE_ROUTE
  },
  {
    children: 'Правила пользования платформой',
    to: RULES_PAGE_ROUTE,
    selected: true
  }
];

export const RulesPage: ReactFCC = () => {
  const { content, isLoading } = usePageContent({ pageName: COURSE_SYSTEM_RULES });

  return (
    <>
      <Head title={'Правила пользования платформой'} />

      <InfoLayout breadcrumbs={breadcrumbs}>
        <div className={s.RulesPage}>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <>
              <Heading size={HeadingSize.H4}>Правила пользования платформой</Heading>

              <div dangerouslySetInnerHTML={{ __html: content }} />
            </>
          )}
        </div>
      </InfoLayout>
    </>
  );
};
