import React, { useRef } from 'react';
import clsx from 'clsx';
import composeRefs from '@seznam/compose-react-refs';
import { IntrinsicPropsWithoutRef } from 'utils/types';
import { useDelegateFocus } from 'hooks/useDelegateFocus';
import { useFocus } from 'hooks/useFocus';
import { Icon, IconProps, IconSize } from '../Icon';
import s from './Input.module.scss';

export interface InputProps extends Omit<IntrinsicPropsWithoutRef<'input'>, 'onClick'> {
  /**
   * Состояние ошибки
   */
  error?: boolean;
  /**
   * Проп для контролируемого включения состояния фокуса
   */
  focused?: boolean;
  /**
   * Ref на input-элемент
   */
  inputRef?: React.Ref<HTMLInputElement>;
  /**
   * Обработчик нажатия на Input
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  /**
   * Компонент иконки справа
   */
  rightIcon?: IconProps['icon'];
  /**
   * Клик по иконке справа
   */
  onRightIconClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
  /**
   * Обработчик нажатия на иконку очистки значения
   */
  onClear?: () => void;
  /**
   * Дополнительные css-классы элементов:
   * * root - внешний контейнер
   * * input - элемент input
   * * icon - иконки слева и справа Input
   * * iconLeft - иконка слева Input
   * * iconRight - иконка справа Input
   */
  classes?: {
    root?: string;
    icon?: string;
    iconRight?: string;
    input?: string;
  };
}

export type InputType = React.ForwardRefExoticComponent<InputProps & React.RefAttributes<HTMLDivElement>>;

const InputForwardedRef = React.forwardRef<HTMLDivElement, InputProps>(
  (
    {
      error,
      focused: focusedProp,
      className,
      classes,
      onClick,
      inputRef: inputRefProp,
      style,
      rightIcon,
      onRightIconClick,
      ...inputProps
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const delegateProps = useDelegateFocus<HTMLDivElement, HTMLInputElement>(inputRef, { onClick });
    const { focused, ...focusProps } = useFocus(inputProps);

    const iconSize = IconSize.medium;

    return (
      <div
        ref={ref}
        className={clsx(
          s.Input,
          {
            [s.Input_focus]: focusedProp ?? focused,
            [s.Input_error]: error,
            [s.Input_disabled]: inputProps.disabled
          },
          className,
          classes?.root
        )}
        style={style}
        {...delegateProps}>
        <input
          ref={composeRefs(inputRef, inputRefProp)}
          className={clsx(s.Input__input, { [s.Input__input_disabled]: inputProps.disabled }, classes?.input)}
          autoComplete={'off'}
          {...inputProps}
          {...focusProps}
        />
        {rightIcon && (
          <div
            className={clsx(
              s.Input__icon,
              { [s.Input__icon_interactive]: onRightIconClick },
              s.Input__icon_right,
              classes?.icon,
              classes?.iconRight
            )}>
            <Icon icon={rightIcon} size={iconSize} onClick={onRightIconClick} />
          </div>
        )}
      </div>
    );
  }
);

InputForwardedRef.displayName = 'Input';

export const Input: InputType = InputForwardedRef;
