import { useContext } from 'react';
import { notNull } from '../../../utils/notNull';
import { SiteInfoContext } from './SiteInfoContext';

export function useUser() {
  const { userQuery } = useContext(SiteInfoContext);
  const query = notNull(userQuery);

  return {
    ...query,
    data: query?.data?.[0] || null
  };
}
