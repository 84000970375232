import { createContext } from 'react';
import { IMoodleQueryResult } from '../../../store/moodleAPI';
import { ICoreWebserviceGetSiteInfoData } from '../../../store/moodleAPI/moodleTypes/_functions/core_webservice_get_site_info';
import { CoreUserGetUsersByFieldData } from '../../../store/moodleAPI/moodleTypes/_functions/core_user_get_users_by_field';

// todo Возможно это не совсем правильно пихать запрос на пользователя в siteInfo,
//  но не хотелось делать для этого еще один лишний провайдер или серьезно переделывать текщий siteInfo,
//  чтобы не затрагивать весь остальной код
interface ISiteInfoContext {
  siteInfoQuery: IMoodleQueryResult<ICoreWebserviceGetSiteInfoData> | null;
  userQuery: IMoodleQueryResult<CoreUserGetUsersByFieldData> | null;
}

export const SiteInfoContext = createContext<ISiteInfoContext>({
  siteInfoQuery: null,
  userQuery: null
});
