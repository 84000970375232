import React from 'react';
import { useAuth } from '../auth';
import { useMoodleAPI } from '../../../store/moodleAPI';
import {
  ICoreWebserviceGetSiteInfoData,
  ICoreWebserviceGetSiteInfoQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/core_webservice_get_site_info';
import {
  CoreUserGetUsersByFieldData,
  CoreUserGetUsersByFieldQuery
} from '../../../store/moodleAPI/moodleTypes/_functions/core_user_get_users_by_field';
import { SiteInfoContext } from './SiteInfoContext';

export function SiteInfoProvider({ children }: { children: React.ReactNode }) {
  const { isLoggedIn } = useAuth();
  const siteInfoQuery = useMoodleAPI<ICoreWebserviceGetSiteInfoQuery, ICoreWebserviceGetSiteInfoData>(
    // данные автоматически обнуляются при выходе из системы (false) / пере-запрос при смене пользователя
    isLoggedIn() && { wsfunction: 'core_webservice_get_site_info' }
  );

  const userQuery = useMoodleAPI<CoreUserGetUsersByFieldQuery, CoreUserGetUsersByFieldData>(
    typeof siteInfoQuery.data?.userid === 'number' && {
      wsfunction: 'core_user_get_users_by_field',
      field: 'id',
      values: [siteInfoQuery.data.userid]
    }
  );

  return (
    <SiteInfoContext.Provider
      value={{
        siteInfoQuery,
        userQuery
      }}>
      {children}
    </SiteInfoContext.Provider>
  );
}
