import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import s from './TextArea.module.scss';

export interface TextAreaProps
  extends Pick<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'rows' | 'onChange' | 'onBlur' | 'onFocus'> {
  className?: string;
  classes?: {
    root?: string;
    wrapper?: string;
    input?: string;
  };
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  name?: string;
  maxlength?: number;
  errorMessage?: string;
  hintMessage?: string;
}

export function TextArea({
  className,
  classes,
  placeholder,
  disabled,
  value,
  name,
  maxlength = 500,
  hintMessage,
  errorMessage,
  onChange,
  ...props
}: TextAreaProps) {
  const [count, setCount] = useState(0);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textAreaValue = event.target.value;
    setCount(textAreaValue.length);
    onChange?.(event);
  };

  const resizeTextArea = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }
  };

  useEffect(resizeTextArea, [count]);

  return (
    <div className={clsx(s.TextArea, className, classes?.root)}>
      <div className={clsx(s.TextArea__wrapper, classes?.wrapper)}>
        <textarea
          ref={textAreaRef}
          className={clsx(s.TextArea__input, classes?.input, {
            [s.TextArea__input_active]: !!value
          })}
          placeholder={placeholder}
          disabled={disabled}
          value={value || ''}
          name={name}
          onChange={handleTextAreaChange}
          maxLength={maxlength}
          {...props}
        />
        <div className={s.TextArea__counter}>
          {count}/{maxlength}
        </div>
      </div>

      <div
        className={clsx(s.TextArea__message, {
          [s.TextArea__message_error]: !!errorMessage
        })}>
        {errorMessage || hintMessage}
      </div>
    </div>
  );
}
