import React, { forwardRef, useEffect } from 'react';
import clsx from 'clsx';
import { insertAccessKeyIntoUrls } from 'utils/insertAccessKeyIntoUrls';
import { notNull } from 'utils/notNull';
import { API_URL } from '../../config';
import { useSiteInfoData } from '../../app/providers/siteInfo';
import bqSrc from './_assets/bq.svg';
import s from './MoodleContent.module.scss';

function removeWrappingParagraphTag(node: HTMLElement) {
  const parent = node.parentElement;
  // тег p не может содержать в себе div
  if (parent && parent.tagName === 'P') {
    // передаем логику text-align: center;
    if (parent.style.textAlign === 'center') {
      node.style.marginRight = node.style.marginLeft = 'auto';
    }

    parent.after(node);
    parent.remove();
  }
}

export interface IMoodleContentProps {
  textFormat?: 'small' | 'medium' | 'large';
  // Moodle HTML
  children: string;
  style?: React.CSSProperties;
  className?: string;
}

export const MoodleContent = forwardRef<HTMLDivElement, IMoodleContentProps>(function MoodleContent(
  { textFormat = 'large', children, style, className },
  ref
) {
  const handleMessage = (e: MessageEvent) => {
    // h5p embedded content is fully loaded
    if (e.data.type === 'HEIGHT') document.getElementById('h5p-content')?.setAttribute('height', e.data.payload);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  });

  let htmlString = children
    // [1.1] заменяет все эти странные пробелы на обычные / делает результат более предсказуемым
    // eslint-disable-next-line no-irregular-whitespace
    .replace(/ |&nbsp;/g, ' ')
    // [1.2] на сайте edu.hse.ru у body установлен font-size: 0.9375rem, такой же размер установлен на рандомных блоках разметки,
    // из-за чего у нас эти блоки в неожиданных местах имеют меньший размер, если не подменить этот стиль
    .replace(/font-size: 0.9375rem;/g, 'font-size: inherit;');

  // [2] вставляет siteInfoData.userprivateaccesskey
  const siteInfoData = useSiteInfoData();
  const { userprivateaccesskey } = notNull(siteInfoData);

  htmlString = insertAccessKeyIntoUrls(htmlString, userprivateaccesskey);

  const parser = new DOMParser();
  const content = parser.parseFromString(htmlString, 'text/html');

  content.querySelectorAll('video').forEach((video) => {
    // [3] исправляет неправильно вставленные видео с YouTube
    if (video.innerHTML.includes('https://youtu.be/')) {
      const id = video.innerHTML.split('/')[3].split('"')[0];
      if (id) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', `https://www.youtube.com/embed/${id}`);
        iframe.setAttribute('width', '100%');
        // iframe.setAttribute('height', '225');
        // prettier-ignore
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');

        video.replaceWith(iframe);
      }
    }

    if (video.innerHTML.includes('youtube.com/watch?v=')) {
      let src = 'https://www.youtube.com/embed/';

      const id = video.innerHTML.split('?v=')[1];
      if (id.indexOf('"')) src += id.substring(0, id.indexOf('"'));
      if (id.indexOf('<')) src += id.substring(0, id.indexOf('<'));

      if (src !== 'https://www.youtube.com/embed/') {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', src);
        iframe.setAttribute('width', '100%');
        // iframe.setAttribute('height', '225');
        // prettier-ignore
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');

        video.replaceWith(iframe);
      }
    }
  });

  content.querySelectorAll('iframe').forEach((iframe) => {
    // [4] оборачивает iframe в два контейнера, чтобы сохранить соотношение сторон
    const box = document.createElement('div');
    const container = document.createElement('div');
    const innerContainer = document.createElement('div');

    box.classList.add(s.MoodleContent__iFrameBox);
    box.appendChild(container);

    container.classList.add(s.MoodleContent__iFrameContainer);
    container.style.maxWidth = iframe.width ? `${iframe.width}px` : '';
    container.appendChild(innerContainer);

    if (iframe.width && !iframe.width.includes('%') && iframe.height && !iframe.height.includes('%')) {
      const width = parseInt(iframe.width);
      const height = parseInt(iframe.height);
      innerContainer.style.paddingBottom = (height / width) * 100 + '%';
    } else {
      innerContainer.style.paddingBottom = '56.25%';
    }

    iframe.replaceWith(box);
    innerContainer.appendChild(iframe);

    const parent = container.parentElement;
    // тег p не может содержать в себе div
    if (parent && parent.tagName === 'P') {
      // передаем логику text-align: center;
      if (parent.style.textAlign === 'center') {
        container.style.marginRight = container.style.marginLeft = 'auto';
      }

      parent.after(container);
      parent.remove();
    }
  });

  // img: убирает атрибут align + добавляет контейнер с подписью
  content.querySelectorAll('img').forEach((img) => {
    img.setAttribute('align', '');

    const box = document.createElement('div');
    box.classList.add(s.MoodleContent__imageBox);

    const boxInner = document.createElement('div');
    boxInner.classList.add(s.MoodleContent__imageBoxInner);
    boxInner.style.maxWidth = img.width ? `${img.width}px` : '';
    boxInner.style.marginLeft = img.style.marginLeft;
    boxInner.style.marginRight = img.style.marginRight;

    box.appendChild(boxInner);
    img.replaceWith(box);

    boxInner.appendChild(img);

    if (img.alt) {
      const imgAltBox = document.createElement('div');
      imgAltBox.classList.add(s.MoodleContent__imageBoxAlt);
      imgAltBox.innerHTML = img.alt;
      boxInner.appendChild(imgAltBox);
    }

    removeWrappingParagraphTag(box);
  });

  // blockquote: добавляет контейнер
  content.querySelectorAll('blockquote').forEach((bq) => {
    const box = document.createElement('div');
    box.classList.add(s.MoodleContent__blockquoteBox);

    const boxInner = document.createElement('div');
    boxInner.classList.add(s.MoodleContent__blockquoteBoxInner);

    const bqIcon = document.createElement('img');
    bqIcon.classList.add(s.MoodleContent__blockquoteIcon);
    bqIcon.setAttribute('src', bqSrc);

    // Обработка автора цитаты через специальную метку *Author*
    const authorsBox = document.createElement('div');
    authorsBox.classList.add(s.MoodleContent__blockquoteAuthors);

    const authors = Array.from(bq.innerText.matchAll(/\*Author\*.+\n/g));
    if (authors.length) {
      authors.forEach((item) => {
        const bqAuthorContent = item[0].trim();

        const bqAuthor = document.createElement('p');
        bqAuthor.classList.add(s.MoodleContent__blockquoteAuthor);
        bqAuthor.innerHTML = bqAuthorContent.replace('*Author*', '');
        // bq.innerHTML = bq.innerHTML.replace(bqAuthorContent, bqAuthor.outerHTML);
        bq.innerHTML = bq.innerHTML.replace(bqAuthorContent, '');
        authorsBox.appendChild(bqAuthor);
      });
    }

    box.append(boxInner, bqIcon);
    bq.replaceWith(box);
    boxInner.append(bq, authorsBox.childNodes.length ? authorsBox : '');
  });

  // table: добавляет контейнер
  content.querySelectorAll('table').forEach((table) => {
    const box = document.createElement('div');
    box.classList.add(s.MoodleContent__tableBox);
    const boxInner = document.createElement('div');
    boxInner.classList.add(s.MoodleContent__tableBoxInner);

    box.style.gridTemplateColumns = `minmax(min-content, ${table.style.width})`;
    table.style.width = '';

    const hasHeader = !!table.querySelector('tr:first-child')?.textContent?.trim();

    if (!hasHeader) {
      const headRow = table.querySelector('tr:first-child') as HTMLTableRowElement;
      if (headRow) {
        headRow.style.display = 'none';
      }
    }

    table.replaceWith(box);
    boxInner.appendChild(table);
    box.appendChild(boxInner);
  });

  // TODO: перейти на использование непосредственно MoodlePage
  // [5] встраивает h5p-контент
  content.querySelectorAll('.h5p-placeholder').forEach((h5p) => {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', `${API_URL}/h5p/embed.php?url=${h5p.innerHTML}`);
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('width', '100%');
    // подумать над полноценной поддержкой сразу нескольких фреймов
    iframe.setAttribute('id', `h5p-content`);
    h5p.replaceWith(iframe);
  });

  // br: не дает (совсем) сломать верстку тегами br
  content.querySelectorAll('br').forEach((br) => {
    if (!br.nextSibling || br.nextSibling.nodeName === 'BR') br.remove();
  });

  // оборачивает текст в span, чтобы можно было делать относительные отступы
  content.querySelectorAll('div, p').forEach((parent) => {
    parent.childNodes.forEach((child) => {
      if (child.nodeType === 3) {
        const span = document.createElement('span');
        child.after(span);
        span.appendChild(child);
      }
    });
  });

  // рекурсивно удаляет все пустые элементы
  (function removeEmptyElements() {
    let done = true;

    content.querySelectorAll('div:empty, p:empty, span:empty').forEach((element) => {
      element.remove();
      done = false;
    });

    if (!done) removeEmptyElements();
  })();

  const serializer = new XMLSerializer();
  return (
    <div
      ref={ref}
      style={style}
      className={clsx(
        s.MoodleContent,
        { [s.MoodleContent_small]: textFormat === 'small' },
        { [s.MoodleContent_medium]: textFormat === 'medium' },
        className
      )}
      dangerouslySetInnerHTML={{
        __html: serializer.serializeToString(content)
      }}
    />
  );
});
