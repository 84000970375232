import { isNil } from 'lodash-es';
import {
  ModQuizGetUserBestGradeData,
  ModQuizGetUserBestGradeQuery,
  ModQuizGetUserBestGradeQueryArgs
} from '../moodleTypes/_functions/mod_quiz_get_user_best_grade';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizUserBestGrade({ quizid, userid }: Partial<ModQuizGetUserBestGradeQueryArgs> = {}) {
  return useMoodleAPI<ModQuizGetUserBestGradeQuery, ModQuizGetUserBestGradeData>(
    !isNil(quizid) && {
      wsfunction: 'mod_quiz_get_user_best_grade',
      quizid,
      userid
    }
  );
}
