import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import { HOME_PAGE_ROUTE } from '../../app/routes';
import s from './Logo.module.scss';

export interface LogoProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Вариант отображения маленького логотипа (не имеет адаптива)
   */
  small?: boolean;
  /**
   * Выводит логотип в виде аббревиатуры АЦ
   */
  shrink?: boolean;
}

export const Logo: ReactFCC<LogoProps> = (props) => {
  const { className, small, shrink } = props;

  return (
    <Link className={clsx(s.Logo, small && s.Logo_small, className)} to={HOME_PAGE_ROUTE} reloadDocument>
      <span className={clsx(s.Logo__text_first)}>{shrink ? 'А' : 'Аудит-'}</span>
      <span className={clsx(s.Logo__text_second)}>{shrink ? 'Ц' : 'Центр '}</span>
      <span className={clsx(s.Logo__text_third)}>{shrink ? 'Г' : 'Групп'}</span>
    </Link>
  );
};
