import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { SkeletonItem } from '../../../Skeleton';
import s from './CardContentSkeleton.module.scss';

export interface CardContentSkeletonProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const CardContentSkeleton: ReactFCC<CardContentSkeletonProps> = (props) => {
  const { className } = props;

  return (
    <div className={clsx(s.CardContentSkeleton, className)}>
      <SkeletonItem className={s.CardContentSkeleton__title} />
      <SkeletonItem className={s.CardContentSkeleton__text} />
      <SkeletonItem className={clsx(s.CardContentSkeleton__text, s.CardContentSkeleton__text_short)} />
      <SkeletonItem className={s.CardContentSkeleton__text} />
    </div>
  );
};
