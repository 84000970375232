import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { Logo } from '../../../../components/Logo';
import { Breadcrumbs, BreadcrumbsProps } from '../../../../components/Breadcrumbs';
import s from './Header.module.scss';

export interface HeaderProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Хлебные крошки
   */
  breadcrumbs?: BreadcrumbsProps['items'];
}

export const Header: ReactFCC<HeaderProps> = (props) => {
  const { className, breadcrumbs } = props;

  return (
    <div className={clsx(s.Header, className)}>
      <Logo className={s.Header__logo} small />

      {breadcrumbs && <Breadcrumbs className={s.Header__breadcrumbs} items={breadcrumbs} />}
    </div>
  );
};
