import { usePrevious } from '../../../hooks/usePrevious';
import { MoodleError, MoodleErrorType } from '../MoodleError';
import { useCourse } from './useCourse';
import { useReadableModules } from './useReadableModules';

export const useReadableModuleData = (courseId?: number, moduleId?: number) => {
  const {
    modules,
    isLoading: modulesLoading,
    isFetched: modulesFetched,
    error: modulesError
  } = useReadableModules(courseId);
  const module = modules.find((i) => i.id === moduleId);

  const prevCourseId = usePrevious(courseId, false);

  const { course, isLoading: courseLoading, error: courseError } = useCourse(courseId ?? prevCourseId);

  const moduleIndex = modules.findIndex((lesson) => lesson.id === moduleId);
  const prevModuleId = modules[moduleIndex - 1]?.id || undefined;
  const nextModuleId = modules[moduleIndex + 1]?.id || undefined;
  const modulesLength = modules.length;

  const isLoading = modulesLoading || courseLoading;

  // Хук вызывается только для страницы лекции, поэтому обрабатывать !course нет смысла
  const notFoundError =
    modulesFetched && !module
      ? new MoodleError("The module hasn't been found", MoodleErrorType.ERR_NOT_FOUND)
      : undefined;
  const error = modulesError || courseError || notFoundError;

  return {
    course,
    courseId: courseId ?? prevCourseId,
    instanceId: module?.instance,
    title: module?.name,
    moduleIndex,
    prevModuleId,
    nextModuleId,
    modulesLength,
    isLoading,
    error,
    ...module
  };
};
