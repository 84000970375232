import { isNil } from 'lodash-es';
import { tryNumberLike } from './numbers/numberLike';
import { Optional } from './types';

const intlRules = new Intl.PluralRules('ru-RU');
const rulesUnits = ['one', 'few', 'many'];

export function formatUnits(value: Optional<number>, units: string[]): [number, string] | null {
  const valNum = tryNumberLike(value, null, true);
  if (isNil(valNum)) return null;

  const intlUnit = intlRules.select(valNum);
  const unit = units[rulesUnits.findIndex((u) => u === intlUnit)] || '';

  return [valNum, unit];
}
