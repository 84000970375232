import { Fragment } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import { Icon, IconSize } from '../Icon';
import { Icons } from '../Icons';
import { BreadcrumbItem, BreadcrumbItemProps } from './BreadcrumbItem';
import s from './Breadcrumbs.module.scss';

export interface BreadcrumbsProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * массив элементов хлебных крошек
   */
  items: Pick<BreadcrumbItemProps, 'selected' | 'to' | 'children'>[];
}

export const Breadcrumbs: ReactFCC<BreadcrumbsProps> = (props) => {
  const { className, items } = props;

  return (
    <div className={clsx(s.Breadcrumbs, className)}>
      {items.map((item, index, { length }) => (
        <Fragment key={index}>
          <BreadcrumbItem {...item} />
          {index !== length - 1 && (
            <Icon className={s.BreadcrumbItem__icon} icon={Icons.CHEVRON_RIGHT} size={IconSize.small} />
          )}
        </Fragment>
      ))}
    </div>
  );
};
