import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Heading, HeadingSize } from '../../../components/Heading';
import { Text } from '../../../components/Text';
import { AttachmentDownload } from '../../../components/Attachment';
import { ModuleResource } from '../../../store/moodleAPI';
import s from './LessonPageContentFile.module.scss';

export interface LessonPageContentFileProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Массив файлов
   */
  items: ModuleResource[];
}

export const LessonPageContentFile = forwardRef<HTMLDivElement, LessonPageContentFileProps>(
  function LessonPageContentFile({ className, items }, ref) {
    return (
      <div className={clsx(s.LessonPageContentFile, className)} ref={ref}>
        <Heading className={s.LessonPageContentFile__title} size={HeadingSize.H4}>
          Дополнительные материалы по теме
        </Heading>

        <Text className={s.LessonPageContentFile__description}>Список материалов для скачивания:</Text>

        <div className={s.LessonPageContentFile__items}>
          {items.map((item, index) => (
            <AttachmentDownload filename={item.name} size={item.size} url={item.url} key={index} />
          ))}
        </div>
      </div>
    );
  }
);
