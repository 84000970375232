import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import { Icon, IconProps, IconSize } from '../Icon';
import s from './Badge.module.scss';

export enum BadgeStatus {
  good = 'good',
  bad = 'bad',
  warning = 'warning',
  info = 'info',
  neutral = 'neutral'
}

export enum BadgeVariant {
  filled = 'filled',
  stroked = 'stroked'
}

export enum BadgeSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  large_x = 'large_x'
}

export enum BadgeBorder {
  default = 'default',
  round = 'round'
}

export interface BadgeProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Статус бейджика (good, bad, warning, info)
   */
  status?: BadgeStatus;
  /**
   * Вариант бейджика (filled, stroked)
   */
  variant?: BadgeVariant;
  /**
   * Размер бейджика (small, medium, large, large_x)
   */
  size?: BadgeSize;
  /**
   * Скругление углов бейджика (default, round)
   */
  border?: BadgeBorder;
  /**
   * Иконка слева
   */
  iconLeft?: IconProps['icon'];
  /**
   * Иконка справа
   */
  iconRight?: IconProps['icon'];
}

export const Badge: ReactFCC<BadgeProps> = (props) => {
  const {
    children,
    className,
    status = BadgeStatus.neutral,
    variant = BadgeVariant.stroked,
    size = BadgeSize.medium,
    border = BadgeBorder.round,
    iconLeft,
    iconRight
  } = props;

  const iconProps = {
    size: size === BadgeSize.large_x ? IconSize.medium : size === BadgeSize.small ? IconSize.small_x : IconSize.small
  };

  return (
    <div
      className={clsx(
        s.Badge,
        className,
        s[`Badge_variant_${variant}`],
        s[`Badge_status_${status}`],
        s[`Badge_size_${size}`],
        s[`Badge_border_${border}`]
      )}>
      {iconLeft && <Icon className={s.Badge__icon} icon={iconLeft} {...iconProps} />}
      <div className={s.Badge__content}>{children}</div>
      {iconRight && <Icon className={s.Badge__icon} icon={iconRight} {...iconProps} />}
    </div>
  );
};
