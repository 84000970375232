import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import { CardBaseProps, CardContentSkeleton, CardLayout, CardTitle } from '../_shared';
import { CardText } from '../_shared/CardText';
import { Badge, BadgeSize, BadgeStatus } from '../../Badge';
import { Icons } from '../../Icons';
import s from './CardLesson.module.scss';

export interface CardLessonProps extends CardBaseProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Статус карточки прочитано / не прочитано
   */
  completed?: boolean;
}

export const CardLesson: ReactFCC<CardLessonProps> = (props) => {
  const { className, title, content, loading, size, completed, ...layoutProps } = props;

  return (
    <CardLayout className={clsx(s.CardLesson, className)} loading={loading} size={size} {...layoutProps}>
      {!loading && (
        <Badge
          status={completed ? BadgeStatus.good : BadgeStatus.neutral}
          size={BadgeSize.medium}
          iconLeft={completed ? Icons.CHECK : undefined}>
          {completed ? 'Пройден' : 'Не пройден'}
        </Badge>
      )}

      <div className={s.CardLesson__content}>
        {loading ? (
          <CardContentSkeleton />
        ) : (
          <>
            <CardTitle className={s.CardLesson__title} size={size}>
              {title}
            </CardTitle>

            <CardText className={s.CardLesson__text} content={content} />
          </>
        )}
      </div>
    </CardLayout>
  );
};
