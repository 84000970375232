import { Link as LinkRouter } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { Link, LinkSize, LinkVariant } from '../../../../components/Link';
import { POLICY_PAGE_ROUTE, RULES_PAGE_ROUTE } from '../../../../app/routes';
import s from './Footer.module.scss';

export interface FooterProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const Footer: ReactFCC<FooterProps> = (props) => {
  const { className } = props;

  return (
    <div className={clsx(s.Footer, className)}>
      <span className={s.Footer__name}>© Аудит-Центр Групп. 2023</span>

      <div className={s.Footer__links}>
        <Link
          className={s.Footer__link}
          component={LinkRouter}
          to={POLICY_PAGE_ROUTE}
          variant={LinkVariant.SECONDARY}
          size={LinkSize.MEDIUM}
          underlined={false}
          standalone>
          Политика конфиденциальности
        </Link>

        <Link
          className={s.Footer__link}
          component={LinkRouter}
          to={RULES_PAGE_ROUTE}
          variant={LinkVariant.SECONDARY}
          size={LinkSize.MEDIUM}
          underlined={false}
          standalone>
          Правила пользования платформой
        </Link>
      </div>
    </div>
  );
};
