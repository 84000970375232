export interface IModuleContents {
  // a file or a folder or external link
  type: string;
  // filename
  filename: string;
  // filepath
  filepath: string;
  // filesize
  filesize: number;
  // downloadable file url
  fileurl?: string;
  // Raw content, will be used when type is content
  content?: string;
  // Time created
  timecreated: number;
  // Time modified
  timemodified: number;
  // Content sort order
  sortorder: number;
  // File mime type.
  mimetype?: string;
  // Whether is an external file.
  isexternalfile?: 0 | 1;
  // The repository type for external files.
  repositorytype?: string;
  // User who added this content to moodle
  userid: number;
  // Content owner
  author: string;
  // Content license
  licence: string;
  // Tags
  tags?: Array<{
    // Tag id.
    id: number;
    // Tag name.
    name: string;
    // The raw, unnormalised name for the tag as entered by users.
    rawname: string;
    // Whether this tag is standard.
    isstandard: 0 | 1;
    // Tag collection id.
    tagcollid: number;
    // Tag instance id.
    taginstanceid: number;
    // Context the tag instance belongs to.
    taginstancecontextid: number;
    // Id of the record tagged.
    itemid: number;
    // Tag ordering.
    ordering: number;
    // Whether the tag is flagged as inappropriate.
    flag: 0 | 1;
  }>;
}

export enum CompletionMode {
  None = 0,
  Manual = 1,
  Auto = 2
}

export enum CompletionState {
  Incomplete = 0,
  Complete = 1,
  CompletePass = 2,
  CompleteFail = 3
}

export interface IModuleCompletionDataDetail {
  rulename: string;
  rulevalue: {
    status: CompletionState;
    description: string;
  };
}

export interface IModuleCompletionData {
  details: IModuleCompletionDataDetail[];
  // Completion state value:
  // 0 means incomplete, 1 complete, 2 complete pass, 3 complete fail
  state: CompletionState;
  // Timestamp for completion status.
  timecompleted: number;
  // The user id who has overridden the status
  overrideby: number;
  // Whether the completion status affects the availability of another activity.
  valueused?: 0 | 1;
}

export interface IModule {
  // activity id
  id: number;
  // activity url
  url?: string;
  // activity module name
  name: string;
  // instance id
  instance?: number;
  // context id
  contextid?: number;
  // activity description
  description?: string;
  // is the module visible
  visible?: 0 | 1;
  // Is the module visible for the user?
  uservisible?: boolean;
  // Availability information.
  availabilityinfo?: string;
  // is the module visible on course page
  visibleoncoursepage?: 0 | 1;
  // activity icon url
  modicon: string;
  // activity module type
  modname: string;
  // activity module plural name
  modplural: string;
  // module availability settings
  availability?: string;
  // number of indentation in the site
  indent: number;
  // Onclick action.
  onclick?: string;
  // After link info to be displayed.
  afterlink?: string;
  // Custom data (JSON encoded).
  customdata?: string;
  // Whether the module has no view page
  noviewlink?: 0 | 1;
  // Type of completion tracking:
  // 0 means none, 1 manual, 2 automatic.
  completion?: CompletionMode;
  // Module completion data.
  completiondata?: IModuleCompletionData;
  contents: Array<IModuleContents>;
  // Contents summary information.
  contentsinfo?: {
    // Total number of files.
    filescount: number;
    // Total files size.
    filessize: number;
    // Last time files were modified.
    lastmodified: number;
    // Files mime types.
    mimetypes: Array<string>;
    // The repository type for the main file.
    repositorytype?: string;
  };
}

export enum ModuleName {
  lesson = 'lesson',
  scorm = 'scorm',
  resource = 'resource',
  page = 'page',
  quiz = 'quiz'
}
