import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import homepageGreetingSrc from 'assets/images/homepage-greeting.png';
import { Head } from '../../components/Head';
import { Heading, HeadingSize } from '../../components/Heading';
import { Text, TextColor } from '../../components/Text';
import { COURSE_SYSTEM_BANNER, COURSE_SYSTEM_FILES, COURSE_SYSTEM_HOME, usePageContent } from '../../store/moodleAPI';
import { CardFile, CardGrid } from '../../components/Card';
import { PathBuilder } from '../../app/routes';
import { ModuleName } from '../../store/moodleAPI/moodleTypes/Module';
import s from './HomePage.module.scss';

const ANIMATION_DURATION = 500;

export function HomePage() {
  const { title: homeTitle, content: homeContent } = usePageContent({
    pageName: COURSE_SYSTEM_HOME,
    inline: true
  });

  const { attachments: bannerAttachments } = usePageContent({
    pageName: COURSE_SYSTEM_BANNER,
    hasModules: true
  });

  const {
    title: filesTitle,
    content: filesContent,
    course: filesCourse,
    resources: filesResources
  } = usePageContent({
    pageName: COURSE_SYSTEM_FILES,
    hasModules: true,
    inline: true
  });

  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  // Сохраняем смещение в процентах / 100
  const [translate, setTranslate] = useState<number>(0);
  const innerRef = useRef<HTMLDivElement>(null);

  const moveToIndex = useCallback(
    (index: number) => {
      if (innerRef.current) {
        const inner = innerRef.current;

        let nextIndex = index;
        if (nextIndex >= bannerAttachments.length) {
          // Если пытаемся выйти за последней элемент, устанавлиаем индекс в 0, но контейнер смещаем дальше
          //  на специально подготовленный плейсхолдер, и после окончания анимации возвращаем его на настоящий элемент

          nextIndex = 0;
          setTranslate(bannerAttachments.length + 1);

          setTimeout(() => {
            inner.style.transition = 'none';
            setTranslate(1);
          }, ANIMATION_DURATION);
        } else if (nextIndex < 0) {
          // По аналогии обрабатываем выход за первый элемент

          nextIndex = bannerAttachments.length - 1;
          setTranslate(0);

          setTimeout(() => {
            inner.style.transition = 'none';
            setTranslate(bannerAttachments.length + 1);
          }, ANIMATION_DURATION);
        } else {
          inner.style.removeProperty('transition');
          setTranslate(nextIndex + 1);
        }

        setCurrentBannerIndex(nextIndex);
      }
    },
    [bannerAttachments]
  );

  useEffect(() => {
    if (bannerAttachments.length <= 1) {
      return;
    }

    const interval = setInterval(() => {
      moveToIndex(currentBannerIndex + 1);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [bannerAttachments, currentBannerIndex, moveToIndex]);

  useEffect(() => {
    // После загрузки баннеров, если их больше 1, то смещаем на один слайд, прячем плейсхолдер
    if (bannerAttachments.length > 1) {
      setTranslate(1);
    }
  }, [bannerAttachments]);

  return (
    <>
      <Head title={'Главная'} />

      <div className={s.HomePage}>
        <div className={s.HomePage__text}>
          <Heading className={s.HomePage__heading} size={HeadingSize.H1}>
            {homeTitle}
          </Heading>

          <Text colorVariant={TextColor.SECONDARY_70}>{homeContent}</Text>
        </div>

        <img className={s.HomePage__greetingImage} src={homepageGreetingSrc} alt="Главная страница" />

        <div className={s.HomePage__bannerContainer}>
          <div
            className={s.HomePage__bannerInner}
            ref={innerRef}
            style={{ transform: `translateX(-${translate * 100}%)` }}>
            {/* Если элементов больше 1 и необходима смена слайдов, то добавляем слева и справа "плейсхолдеры" */}
            {/*  для бесшовного переключения с последнего на первый и наоборот */}
            {(bannerAttachments.length > 1
              ? [bannerAttachments[bannerAttachments.length - 1], ...bannerAttachments, bannerAttachments[0]]
              : bannerAttachments
            )
              .filter(Boolean)
              .map((attachment, index) => (
                <div className={s.HomePage__banner} key={index}>
                  <img className={s.HomePage__bannerImage} src={attachment.url} alt={attachment.name} />
                </div>
              ))}
          </div>

          <div className={s.HomePage__bannerSteps}>
            {bannerAttachments.map((_, index) => (
              <button
                className={clsx(s.HomePage__bannerStep, {
                  [s.HomePage__bannerStep_active]: index === currentBannerIndex
                })}
                onClick={() => moveToIndex(index)}
                key={index}
              />
            ))}
          </div>
        </div>

        {filesCourse && filesResources.length !== 0 && (
          <>
            <div className={clsx(s.HomePage__text, s.HomePage__text_files)}>
              <Heading size={HeadingSize.H2}>{filesTitle}</Heading>

              <Text colorVariant={TextColor.SECONDARY_70}>{filesContent}</Text>
            </div>

            <CardGrid
              className={s.HomePage__filesAttachments}
              component={CardFile}
              items={filesResources.map((item) => {
                const fileSize =
                  item.modname === ModuleName.resource
                    ? item.contents?.reduce((size, file) => size + file.filesize, 0)
                    : undefined;

                return {
                  to: PathBuilder.getFilePath(item.id),
                  title: item.name,
                  fileLength: item.contents.length,
                  fileSize: fileSize
                };
              })}
            />
          </>
        )}
      </div>
    </>
  );
}
