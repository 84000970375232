import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import { CardBaseProps, CardContentSkeleton, CardLayout, CardTitle } from '../_shared';
import { CardText } from '../_shared/CardText';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../IconButton';
import { Icons } from '../../Icons';
import { useHover } from '../../../hooks/useHover';
import { sizeFormat } from '../../../utils/sizeFormat';
import s from './CardFile.module.scss';

export interface CardFileProps extends CardBaseProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Количество файлов
   */
  fileLength?: number;
  /**
   * Общий вес файлов
   */
  fileSize?: number;
}

const enOrdinalRules = new Intl.PluralRules('ru-RU');

const filePluralWords = new Map([
  ['one', 'файл'],
  ['few', 'файла'],
  ['many', 'файлов']
]);

const formatFilesPlural = (n: number) => {
  const rule = enOrdinalRules.select(n);
  const pluralWord = filePluralWords.get(rule);
  return `${n} ${pluralWord}`;
};

export const CardFile: ReactFCC<CardFileProps> = (props) => {
  const { className, title, loading, size, fileLength, fileSize, ...layoutProps } = props;

  const { hovered, ...hoverProps } = useHover();

  return (
    <CardLayout className={clsx(s.CardFile, className)} loading={loading} size={size} {...layoutProps} {...hoverProps}>
      <div className={s.CardFile__content}>
        {loading ? (
          <CardContentSkeleton />
        ) : (
          <>
            <CardTitle className={s.CardFile__title} size={size}>
              {title}
            </CardTitle>

            <CardText
              className={s.CardFile__text}
              content={`${formatFilesPlural(fileLength || 0)} | ${sizeFormat(fileSize || 0)}`}
            />
          </>
        )}
      </div>

      <IconButton
        className={s.CardFile__button}
        variant={IconButtonVariant.ghost}
        icon={Icons.DOWNLOAD}
        size={IconButtonSize.small_x}
        hovered={hovered}
      />
    </CardLayout>
  );
};
