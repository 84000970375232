import { useQuizAttemptAccessInformation } from 'store/moodleAPI/hooks/useQuizAttemptAccessInformation';
import { useQuizAttempt } from 'store/moodleAPI/hooks/useQuizAttempt';
import { useQuizAttemptSummary } from 'store/moodleAPI/hooks/useQuizAttemptSummary';
import { useQuizViewAttempt } from 'store/moodleAPI/hooks/useQuizViewAttempt';

export function useQuizAttemptData(
  quizId: number | undefined,
  attemptId: number | undefined,
  currentQuestionPage: number | undefined
) {
  const currentPage = currentQuestionPage || 0;

  const {
    data: viewAttemptData,
    error: viewAttemptError,
    clearResult: clearViewAttempt
  } = useQuizViewAttempt({
    attemptid: attemptId,
    page: currentPage
  });
  const viewAttemptStatus = viewAttemptData?.status;

  const {
    data: accessData,
    isLoading: isAccessLoading,
    error: accessError,
    clearResult: clearAccessData
  } = useQuizAttemptAccessInformation({
    quizid: !viewAttemptStatus ? undefined : quizId,
    attemptid: !viewAttemptStatus ? undefined : attemptId
  });

  const {
    data: attemptData,
    isLoading: isAttemptDataLoading,
    error: attemptDataError,
    clearResult: clearAttemptData
  } = useQuizAttempt({
    attemptid: !accessData ? undefined : attemptId,
    page: !accessData ? undefined : currentPage
  });

  const skipSummary = !accessData || attemptData?.questions.length === 0;
  const {
    data: attemptSummary,
    isLoading: isAttemptSummaryLoading,
    error: attemptSummaryError,
    clearResult: clearAttemptSummary
  } = useQuizAttemptSummary({
    attemptid: skipSummary ? undefined : attemptId
  });

  const clearCache = () => {
    clearAccessData();
    clearAttemptData();
    clearAttemptSummary();
    clearViewAttempt();
  };

  const clearAllQuestions = () => {
    clearAttemptSummary();
  };

  const questions = attemptData?.questions || [];
  const allQuestions = attemptSummary?.questions || [];

  const isLoading =
    !attemptData || isAttemptDataLoading || isAccessLoading || isAttemptSummaryLoading || questions.length === 0;
  const error = attemptDataError || viewAttemptError || accessError || attemptSummaryError;

  return {
    attemptData,
    accessData,
    questions,
    allQuestions,
    isLoading,
    error,
    clearCache,
    clearAllQuestions
  };
}
