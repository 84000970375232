import { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorPage } from '../../pages/error';
import { AuthProvider } from './auth';
import { AxiosClientProvider } from './axiosClient';
import { QueryParamProvider } from './query-param';
import { SiteInfoProvider } from './siteInfo';

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <ErrorBoundary fallbackRender={(props) => <ErrorPage {...props} />}>
          <AuthProvider>
            <AxiosClientProvider>
              <SiteInfoProvider>
                <QueryParamProvider>{children}</QueryParamProvider>
              </SiteInfoProvider>
            </AxiosClientProvider>
          </AuthProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </BrowserRouter>
  );
};
