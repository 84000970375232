import MoodleMessage from '@auditc/moodle-hook';
import { MoodleIframeBridge } from './message-base';

export function scorm(target?: Window | null) {
  return new MoodleIframeBridge<MoodleMessage.ScormRequest, MoodleMessage.ScormResponse>(
    target ?? window,
    'SCORM',
    'SCORM'
  ).send();
}
