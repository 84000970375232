import { FC } from 'react';
import clsx from 'clsx';
import { Text, TextColor, TextVariant } from '../Text';
import { ProgressLine, ProgressLineProps, ProgressLineSize } from './ProgressLine';
import s from './Progress.module.scss';

export interface ProgressProps extends Pick<ProgressLineProps, 'size' | 'value'> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы для дочерних элементов
   */
  classes?: {
    line?: string;
    text?: string;
  };
  /**
   * Числа: пройденные шаги и общее число
   */
  counts?: [number, number];
}

export const Progress: FC<ProgressProps> = (props) => {
  const { className, classes, value = 0, counts, ...lineProps } = props;

  return (
    <div className={clsx(s.Progress, className)}>
      {counts && (
        <Text
          className={clsx(s.Progress__text, classes?.text)}
          variant={TextVariant.BODY_S}
          colorVariant={TextColor.SECONDARY_70}>
          {counts[0]} из {counts[1]}
        </Text>
      )}

      <ProgressLine
        className={clsx(s.Progress__line, classes?.line)}
        size={ProgressLineSize.large}
        value={value}
        {...lineProps}
      />

      {!!value && (
        <Text
          className={clsx(s.Progress__text, classes?.text)}
          variant={TextVariant.BODY_S}
          colorVariant={TextColor.SECONDARY_70}>
          {`${value}%`}
        </Text>
      )}
    </div>
  );
};
