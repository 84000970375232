import { isNil } from 'lodash-es';
import {
  ModQuizGetQuizRequiredQtypesData,
  ModQuizGetQuizRequiredQtypesQuery
} from '../moodleTypes/_functions/mod_quiz_get_quiz_required_qtypes';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizQuestionTypes(quizid?: number) {
  return useMoodleAPI<ModQuizGetQuizRequiredQtypesQuery, ModQuizGetQuizRequiredQtypesData>(
    !isNil(quizid) && {
      wsfunction: 'mod_quiz_get_quiz_required_qtypes',
      quizid
    }
  );
}
