import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { Divider } from 'components/Divider';
import { Checkbox } from 'components/Checkbox';
import { QuestionLayout } from '../QuestionLayout/QuestionLayout';
import { QuizQuestionTypeProps } from '../types';
import { useProcessQuestion } from '../useProcessQuestion';
import s from '../QuizQuestion.module.scss';

export function MultiChoiceSetQuestion({
  id,
  content,
  sequenceCheck,
  description,
  title,
  prompt,
  setPageProcessData
}: QuizQuestionTypeProps) {
  const [answers, setAnswers] = useState<{ name: string; value: string; label: string }[]>([]);

  const contentRef = useRef(content);

  useEffect(() => {
    const $answers = Array.from(contentRef.current?.querySelectorAll('.answer > div') ?? []);

    const initialAnswers = $answers.map((answer) => {
      const label = answer.querySelector('[data-region="answer-label"]')?.textContent;

      const $input = answer.querySelector('[type="checkbox"]');
      const name = $input?.getAttribute('name');
      const checked = $input?.getAttribute('checked') === 'checked';

      if (!label || !name) {
        return {
          name: '',
          label: '',
          value: ''
        };
      }

      return {
        name,
        label,
        value: checked ? '1' : '0'
      };
    });

    setAnswers(initialAnswers);
  }, []);

  const checkboxChangeHandler = (checked: boolean, e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.getAttribute('id');

    if (!name) {
      return;
    }

    setAnswers((prev) =>
      prev.map((answer) => ({
        ...answer,
        value: answer.name === name ? (checked ? '1' : '0') : answer.value
      }))
    );
  };

  useProcessQuestion({
    answers,
    setPageProcessData,
    sequenceCheck
  });

  return (
    <QuestionLayout id={id} title={title} prompt={prompt}>
      <MoodleContent>{description}</MoodleContent>

      <Divider className={s.QuizQuestion__divider} />

      {answers.map(({ name, label, value }, index) => (
        <div key={`${name}-${index}`} className={s.QuizQuestion__answer}>
          <Checkbox id={name} label={label} checked={value === '1'} onChange={checkboxChangeHandler} />
        </div>
      ))}
    </QuestionLayout>
  );
}
