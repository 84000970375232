import React, { useMemo } from 'react';
import { formatDuration, intervalToDuration } from 'date-fns';
import { isNil } from 'lodash-es';
import { QuizDataType } from 'store/moodleAPI/moodleTypes/Quiz';
import { MoodleContent } from 'components/MoodleContent';
import { Button, ButtonSize } from 'components/Button';
import { Icons } from 'components/Icons';
import { Text, TextColor } from 'components/Text';
import { dateLocale } from '../../../../utils/formatDate';
import { formatGrade } from '../formatGrade';
import s from './QuizAbout.module.scss';

export type QuizAboutProps = Required<Pick<QuizDataType, 'quiz' | 'gradeData'>> & {
  attemptsDoneAmount: number;
  hasAttemptAccess: boolean;
  startQuizLoading: boolean;
  startQuiz: () => void;
  startButtonText: string;
  accessMessages: string[];
};

export const QuizAbout = ({
  quiz,
  gradeData,
  attemptsDoneAmount,
  hasAttemptAccess,
  startQuizLoading,
  startQuiz,
  startButtonText,
  accessMessages
}: QuizAboutProps) => {
  const infoData = useMemo(() => {
    const attemptsAmount = quiz.attempts;
    const attemptsAllowed = attemptsAmount ? attemptsAmount - attemptsDoneAmount : 0;
    const attempts = attemptsAmount ? `${attemptsAllowed} из ${attemptsAmount}` : undefined;

    const timeLimit = quiz?.timelimit
      ? formatDuration(
          intervalToDuration({
            start: 0,
            end: new Date(quiz.timelimit * 1000)
          }),
          {
            locale: dateLocale
          }
        )
      : undefined;

    const gradetopass = gradeData?.gradetopass;
    const gradeMax = quiz?.grade;
    const decimalpoints = quiz?.decimalpoints;

    const passGrade =
      !isNil(gradetopass) && !isNil(gradeMax) ? formatGrade(gradetopass, gradeMax, decimalpoints, ' из ') : undefined;

    return {
      timeLimit,
      attempts,
      passGrade
    };
  }, [quiz, gradeData, attemptsDoneAmount]);

  return (
    <>
      {quiz.intro && <MoodleContent className={s.QuizAbout__intro}>{quiz.intro}</MoodleContent>}

      {Object.values(infoData).some(Boolean) && (
        <div className={s.QuizAbout__total}>
          {/*<Text className={s.QuizAbout__totalTitle} colorVariant={TextColor.SECONDARY_60}>Количество вопросов:</Text>*/}
          {/*<Text className={s.QuizAbout__totalValue}>4 вопроса</Text>*/}

          {infoData.timeLimit && (
            <>
              <Text className={s.QuizAbout__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                Время прохождения:
              </Text>
              <Text className={s.QuizAbout__totalValue}>{infoData.timeLimit}</Text>
            </>
          )}

          {infoData.passGrade && (
            <>
              <Text className={s.QuizAbout__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                Проходная оценка:
              </Text>
              <Text className={s.QuizAbout__totalValue}>{infoData.passGrade}</Text>
            </>
          )}

          {infoData.attempts && (
            <>
              <Text className={s.QuizAbout__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                Осталось попыток:
              </Text>
              <Text className={s.QuizAbout__totalValue}>{infoData.attempts}</Text>
            </>
          )}
        </div>
      )}

      {!!accessMessages.length && (
        <div className={s.QuizAbout__access}>
          {accessMessages.map((msg, iMsg) => (
            <Text key={iMsg}>{msg}</Text>
          ))}
        </div>
      )}

      {hasAttemptAccess && (
        <div className={s.QuizAbout__actions}>
          <Button
            className={s.QuizAbout__submitButton}
            size={ButtonSize.medium}
            rightIcon={Icons.CHEVRON_RIGHT}
            isLoading={startQuizLoading}
            onClick={!startQuizLoading ? startQuiz : undefined}>
            {startButtonText}
          </Button>
        </div>
      )}
    </>
  );
};
