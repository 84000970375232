import { useMoodleAPILazyQuery } from '../useMoodleAPILazyQuery';
import { ModPageViewPageData, ModPageViewPageQuery } from '../moodleTypes/_functions/mod_page_view_page';

export const useViewPage = () => {
  const requestMoodleApi = useMoodleAPILazyQuery();

  return (id?: number | false) => {
    if (id) {
      return requestMoodleApi<ModPageViewPageQuery, ModPageViewPageData>({
        wsfunction: 'mod_page_view_page',
        pageid: id
      });
    }
  };
};
