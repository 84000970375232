import React, { Fragment, ReactNode, RefObject, useCallback, useState } from 'react';
import clsx from 'clsx';
import Tippy, { TippyProps } from '@tippyjs/react';
import { Instance } from 'tippy.js';
import { Icon, IconProps } from '../Icon';
import { ReactFCC } from '../../utils/ReactFCC';
import s from './ContextMenu.module.scss';

export interface ContextMenuItem {
  children?: ReactNode | string;
  icon?: IconProps['icon'];
  onClick?: (e: React.MouseEvent) => void;
  divider?: boolean;
}

export interface ContextMenuContentProps extends TippyProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Дополнительные css-классы
   */
  classes?: {
    dropdown?: string;
    item?: string;
  };
  /**
   * Реф для референсного элемента
   */
  referenceRef: RefObject<HTMLElement>;
  /**
   * Элементы контекстного меню
   */
  items: ContextMenuItem[];
  /**
   * Состояние меню
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия меню
   */
  onClose?: () => void;
}

export const ContextMenu: ReactFCC<ContextMenuContentProps> = (props) => {
  const { className, classes, items, referenceRef, isOpen, onClose, ...tippyProps } = props;

  const [isVisible, setIsVisible] = useState(false);

  const onMount = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onHide = useCallback((instance: Instance) => {
    const unmountInstance = () => {
      instance.unmount();

      instance.popper.firstChild?.removeEventListener('transitionend', unmountInstance);
      setIsVisible(false);
    };

    instance.popper.firstChild?.addEventListener('transitionend', unmountInstance);
  }, []);

  return (
    <Tippy
      animation
      appendTo={document.getElementById('root') as HTMLElement}
      onMount={onMount}
      onHide={onHide}
      reference={referenceRef}
      render={(attrs) => (
        <div
          className={clsx('tippy-box', s.ContextMenu, className)}
          data-state={isOpen && isVisible ? 'visible' : 'hidden'}
          data-animation="scale-subtle"
          style={{ transitionDuration: isOpen ? '0.2s' : '0.15s' }}
          {...attrs}>
          <div className={clsx(s.ContextMenu__dropdown, classes?.dropdown)}>
            {items.map(({ ...item }, index) => (
              <Fragment key={index}>
                <button
                  className={clsx(s.ContextMenu__dropdownItem, classes?.item)}
                  onClick={(e) => {
                    e.preventDefault();
                    onClose?.();
                    item.onClick?.(e);
                  }}>
                  {item.icon && <Icon className={s.ContextMenu__dropdownItemIcon} icon={item.icon} />}
                  <div className={s.ContextMenu__dropdownItemContent}>{item.children}</div>
                </button>

                {item.divider && <div className={s.ContextMenu__divider} />}
              </Fragment>
            ))}
          </div>
        </div>
      )}
      visible={isOpen}
      placement={'bottom-end'}
      interactive={true}
      offset={[0, 4]}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top-start', 'right']
            }
          }
        ]
      }}
      onClickOutside={onClose}
      {...tippyProps}
    />
  );
};
