import { ModQuizGetQuizAccessInformationData } from '../moodleTypes/_functions/mod_quiz_get_quiz_access_information';

export type UseQuizIsAccessEnabledProps = Partial<
  Pick<ModQuizGetQuizAccessInformationData, 'accessrules' | 'preventaccessreasons'>
>;

const QUIZ_ENDING_STRING = 'Тестирование закончено';

export function useQuizAccessEnabled({ accessrules, preventaccessreasons }: UseQuizIsAccessEnabledProps = {}) {
  if (!accessrules || !preventaccessreasons) {
    return {
      isAccessEnabled: false,
      accessClosedReason: ''
    };
  }

  const accessClosedReason = accessrules.find((r) => r.includes(QUIZ_ENDING_STRING)) || '';

  return {
    accessClosedReason,
    isAccessEnabled: preventaccessreasons.length === 0 && !accessClosedReason
  };
}
