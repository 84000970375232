import { Link } from 'react-router-dom';
import { ReactFCC } from '../../utils/ReactFCC';
import { Head } from '../../components/Head';
import { useUrlParam } from '../../hooks/useUrlParam';
import { FILE_PAGE_PARAM, HOME_PAGE_ROUTE } from '../../app/routes';
import { COURSE_SYSTEM_FILES, usePageContent } from '../../store/moodleAPI';
import { AttachmentDownload } from '../../components/Attachment';
import { Heading, HeadingSize } from '../../components/Heading';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { Icons } from '../../components/Icons';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../components/IconButton';
import { insertAccessKeyIntoUrls } from '../../utils/insertAccessKeyIntoUrls';
import { useSiteInfo } from '../../app/providers/siteInfo';
import s from './FilePage.module.scss';

export const FilePage: ReactFCC = () => {
  const isDesktop = useIsDesktop();

  const moduleId = useUrlParam(FILE_PAGE_PARAM, { parser: (value: string) => Number(value) || void 0 });

  const { data } = useSiteInfo();
  const userprivateaccesskey = data?.userprivateaccesskey;

  const { resources, isLoading } = usePageContent({
    pageName: COURSE_SYSTEM_FILES,
    hasModules: true
  });

  const resource = resources.find((item) => item.id === moduleId);

  return (
    <>
      <Head title={`${resource ? `${resource.name} —` : ''} Файлы для скачивания`} />

      <div className={s.FilePage}>
        <div className={s.FilePage__headerContainer}>
          <div className={s.FilePage__header}>
            <IconButton
              component={Link}
              to={HOME_PAGE_ROUTE}
              icon={Icons.CHEVRON_LEFT}
              variant={IconButtonVariant.ghost}
              size={IconButtonSize.small}
            />

            <Heading size={isDesktop ? HeadingSize.H3 : HeadingSize.H2}>{resource?.name}</Heading>
          </div>
        </div>

        <div className={s.FilePage__contentContainer}>
          {!isLoading && !!resource && (
            <div className={s.FilePage__content}>
              {resource?.contents.map((item, index) => (
                <AttachmentDownload
                  filename={item.filename}
                  size={item.filesize}
                  url={insertAccessKeyIntoUrls(item.fileurl as string, userprivateaccesskey)}
                  key={index}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
