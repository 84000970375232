import { ReactFCC } from '../../utils/ReactFCC';
import { Head } from '../../components/Head';
import { InfoLayout } from '../_layouts/InfoLayout';
import { HOME_PAGE_ROUTE, POLICY_PAGE_ROUTE } from '../../app/routes';
import { Heading, HeadingSize } from '../../components/Heading';
import { COURSE_SYSTEM_POLICY, usePageContent } from '../../store/moodleAPI';
import { FullScreenLoader } from '../../components/Loader';
import s from './PolicyPage.module.scss';

const breadcrumbs = [
  {
    children: 'Главная',
    to: HOME_PAGE_ROUTE
  },
  {
    children: 'Политика конфиденциальности',
    to: POLICY_PAGE_ROUTE,
    selected: true
  }
];

export const PolicyPage: ReactFCC = () => {
  const { content, isLoading } = usePageContent({ pageName: COURSE_SYSTEM_POLICY });

  return (
    <>
      <Head title={'Политика конфиденциальности'} />

      <InfoLayout breadcrumbs={breadcrumbs}>
        <div className={s.PolicyPage}>
          {isLoading ? (
            <FullScreenLoader />
          ) : (
            <>
              <Heading size={HeadingSize.H4}>Политика конфиденциальности</Heading>

              <div dangerouslySetInnerHTML={{ __html: content }} />
            </>
          )}
        </div>
      </InfoLayout>
    </>
  );
};
