import { isNil } from 'lodash-es';
import { ICourse } from '../moodleTypes/_functions/core_course_get_courses_by_field';
import { CompletionState, IModule } from '../moodleTypes/Module';
import { QuizDataType } from '../moodleTypes/Quiz';
import { useQuizAccessEnabled } from './useQuizAccessEnabled';
import { useQuiz } from './useQuiz';
import { useQuizAccessInformation } from './useQuizAccessInformation';
import { useQuizUserAttempts } from './useQuizUserAttempts';
import { useQuizAttemptAccessInformation } from './useQuizAttemptAccessInformation';
import { useQuizQuestionTypes } from './useQuizQuestionTypes';
import { useQuizUserBestGrade } from './useQuizUserBestGrade';
import { useQuizViewQuiz } from './useQuizViewQuiz';

export type UseQuizDataProps = {
  courseId?: ICourse['id'];
  instanceId?: IModule['instance'];
  completionData?: IModule['completiondata'];
};

export type UseQuizDataReturn = QuizDataType & {
  isLoading: boolean;
  error?: any;
};

export function useQuizData({ courseId, instanceId, completionData }: UseQuizDataProps): UseQuizDataReturn {
  // Запрашиваем квиз
  const {
    quiz,
    isLoading: isQuizzesLoading,
    error: quizzesError
  } = useQuiz({
    courseid: courseId,
    quizid: instanceId
  });

  // Проверяем доступ к курсу
  const {
    data: accessData,
    isLoading: isAccessLoading,
    error: accessError
  } = useQuizAccessInformation(isNil(completionData?.state) ? undefined : quiz?.id);

  const { isAccessEnabled } = useQuizAccessEnabled({
    preventaccessreasons: accessData?.preventaccessreasons,
    accessrules: accessData?.accessrules
  });

  // Получаем попытку и проверяем наличие последней активной попытки
  const {
    data: attemptsData,
    isLoading: isAttemptsLoading,
    error: attemptsError
  } = useQuizUserAttempts({
    quizid: !isAccessEnabled ? undefined : quiz?.id,
    status: 'all',
    includepreviews: 1
  });

  const attempts = attemptsData?.attempts || [];

  const lastAttempt = attemptsData?.attempts?.[attemptsData.attempts.length - 1];
  const lastAttemptId = lastAttempt?.id;

  const attemptInProgress = lastAttempt?.state === 'inprogress' ? lastAttempt : null;

  const {
    data: attemptAccessData,
    isLoading: isAttemptAccessLoading,
    error: attemptAccessError
  } = useQuizAttemptAccessInformation({
    quizid: isNil(lastAttemptId) ? undefined : quiz?.id,
    attemptid: lastAttemptId
  });

  // Запрашиваем все типы вопросов, которые используются в тесте
  const skipQuizQuestionType =
    completionData?.state === undefined || completionData?.state === CompletionState.CompletePass || !isAccessEnabled;
  const {
    data: questionTypesData,
    isLoading: isQuestionTypesLoading,
    error: questionTypesError
  } = useQuizQuestionTypes(skipQuizQuestionType ? undefined : quiz?.id);

  // Запрашиваем информацию об оценке
  const {
    data: gradeData,
    isLoading: isGradeLoading,
    error: gradeError
  } = useQuizUserBestGrade({
    quizid: quiz?.id
  });

  // "Смотрим" курс и проверяем, что нет ошибки
  const { error: viewQuizError, isLoading: isViewQuizLoading } = useQuizViewQuiz(!attemptsData ? undefined : quiz?.id);

  // const clearCache = () => {
  //   mutateQuizzesData(undefined, { revalidate: true });
  //   mutateAccessData(undefined, { revalidate: true });
  //   mutateAttemptsData(undefined, { revalidate: true });
  //   mutateQuestionTypesData(undefined, { revalidate: true });
  //   mutateViewQuiz(undefined, { revalidate: true });
  // };

  // Склеиваем состояния
  // На уровень выше обязательно нужно добавить сюда состояния загрузки и ошибки course и module
  const error =
    viewQuizError ||
    accessError ||
    attemptsError ||
    questionTypesError ||
    attemptAccessError ||
    gradeError ||
    quizzesError;

  const isLoading =
    isViewQuizLoading ||
    isAccessLoading ||
    isAttemptsLoading ||
    isQuestionTypesLoading ||
    isAttemptAccessLoading ||
    isGradeLoading ||
    isQuizzesLoading;

  return {
    quiz,
    accessData,
    attemptAccessData,
    gradeData,
    questionTypesData,
    attemptsData,
    attempts,
    lastAttemptId,
    attemptInProgress,
    isLoading,
    error
  };
}
