import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { CardSize } from '../types';
import s from './CardTitle.module.scss';

export interface CardTitleProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Размер текста
   */
  size?: CardSize;
}

export const CardTitle: ReactFCC<CardTitleProps> = (props) => {
  const { children, className, size = CardSize.medium } = props;

  return <h6 className={clsx(s.CardTitle, className, s[`CardTitle_${size}`])}>{children}</h6>;
};
