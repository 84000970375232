import { ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import s from './BreadcrumbItem.module.scss';

export interface BreadcrumbItemProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояние выбранности
   */
  selected?: boolean;
  /**
   * Ссылка
   */
  to: To;
  /**
   * Содержимое элемента
   */
  children?: ReactNode | string;
}

export const BreadcrumbItem: ReactFCC<BreadcrumbItemProps> = (props) => {
  const { children, className, selected, to } = props;

  return (
    <Link
      className={clsx(s.BreadcrumbItem, className, {
        [s.BreadcrumbItem_selected]: selected
      })}
      to={to}>
      <div className={s.BreadcrumbItem__content}>{children}</div>
    </Link>
  );
};
