import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import emptyImage from '../../assets/empty.png';
import { Text, TextColor } from '../../../../components/Text';
import s from './CoursesEmpty.module.scss';

export interface CoursesEmptyProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const CoursesEmpty: ReactFCC<CoursesEmptyProps> = (props) => {
  const { className } = props;

  return (
    <div className={clsx(s.CoursesEmpty, className)}>
      <img className={s.CoursesEmpty__image} src={emptyImage} alt={'Нет доступных курсов'} />

      <Text colorVariant={TextColor.SECONDARY_60}>Нет доступных курсов</Text>
    </div>
  );
};
