import { format, isValid, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale/ru';
import { FormatOptions } from 'date-fns/format';

export const dateLocale = ru;

export const DATETIME_FORMAT_FULL = 'dd MMMM yyyy, HH:mm';
export const DATETIME_FORMAT_FULL_NO_TIME = 'dd MMMM yyyy';

/**
 * tryParseIso - проверяет дату на валидность и возвращает ее же, если она валидна, и null, если нет
 *
 * @param string | undefined
 * @return Date | null
 */
export function tryParseIso(string?: string): Date | null {
  if (!string) return null;
  const date = parseISO(string);
  if (isValid(date)) {
    return date;
  }
  return null;
}

/**
 * formatDate - возвращает отформатированную дату (в соотв. с переданными formatString и options) или null, если дата невалидна
 *
 * @return string | null
 * @param dateIso - Date | string | undefined | null
 * @param formatString - string
 * @param options - FormatDateOptionsType | undefined
 */

export function formatDate(dateIso: Date | string, formatString?: string, options?: FormatOptions): string;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString?: string,
  options?: FormatOptions
): string | null;
export function formatDate(
  dateIso: Date | string | undefined | null,
  formatString: string = DATETIME_FORMAT_FULL_NO_TIME,
  options?: FormatOptions
): string | null {
  const date = typeof dateIso === 'string' ? tryParseIso(dateIso) : dateIso || null;
  return (
    (date &&
      format(date, formatString, {
        locale: ru,
        ...options
      })) ||
    null
  );
}
