import { useMemo } from 'react';
import { Head } from '../../components/Head';
import { useMoodleAPI } from '../../store/moodleAPI';
import {
  CoreCourseGetEnrolledCoursesByTimelineClassificationData,
  CoreCourseGetEnrolledCoursesByTimelineClassificationQueryVarsParams,
  ICourse
} from '../../store/moodleAPI/moodleTypes/_functions/core_course_get_enrolled_courses_by_timeline_classification';
import { CardCourse, CardSize } from '../../components/Card';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Heading, HeadingSize } from '../../components/Heading';
import { Tabs } from '../../components/Tabs';
import { useBatchedQueryParam } from '../../app/providers/query-param';
import { PathBuilder, URL_KEY_TAB } from '../../app/routes';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { checkIsSystemCourse } from '../../store/moodleAPI/utils/checkIsSystemCourse';
import { htmlToInlineText } from '../../utils/stringUtils';
import { CardGrid, CoursesEmpty } from './components';
import s from './CoursesPage.module.scss';

export function CoursesPage() {
  const [tab, setTab] = useBatchedQueryParam(URL_KEY_TAB, { defaultValue: '' });

  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();

  const { data, isLoading } = useMoodleAPI<
    CoreCourseGetEnrolledCoursesByTimelineClassificationQueryVarsParams,
    CoreCourseGetEnrolledCoursesByTimelineClassificationData
  >({
    wsfunction: 'core_course_get_enrolled_courses_by_timeline_classification',
    classification: 'all'
  } as any);

  const courses = useMemo(() => data?.courses.filter((course) => !checkIsSystemCourse(course.shortname)) || [], [data]);

  const categories = useMemo(() => {
    if (courses.length === 0) {
      return {};
    }

    const categories: { [key: string]: ICourse[] } = {};

    courses.forEach((item) => {
      if (!categories[item.coursecategory]) {
        categories[item.coursecategory] = [];
      }

      categories[item.coursecategory].push(item);
    });

    return categories;
  }, [courses]);

  const filteredCourses = useMemo(() => {
    if (tab === '') {
      return courses;
    }

    return categories[tab];
  }, [categories, courses, tab]);

  const tabs = useMemo(
    () => [
      {
        id: '',
        children: `Все курсы ${courses.length !== 0 ? `(${courses.length})` : ''}`,
        className: s.CoursesPage__tab_first
      },
      ...(courses
        ? Object.keys(categories).map((item, index, { length }) => ({
            id: item,
            children: `${item} (${categories[item].length})`,
            className: index === length - 1 && s.CoursesPage__tab_last
          }))
        : [])
    ],
    [categories, courses]
  );

  return (
    <>
      <Head title={'Мои курсы'} />

      <div className={s.CoursesPage}>
        <div className={s.CoursesPage__header}>
          <Heading className={s.CoursesPage__title} size={isDesktop ? HeadingSize.H3 : HeadingSize.H2}>
            Мои курсы
          </Heading>

          <Tabs className={s.CoursesPage__tabs} activeId={tab} setActive={setTab} tabs={tabs} />
        </div>

        {isLoading || courses.length !== 0 ? (
          <CardGrid
            className={s.CoursesPage__content}
            loading={isLoading}
            component={CardCourse}
            items={filteredCourses?.map((item) => ({
              to: PathBuilder.getCoursePath(item.id),
              state: {
                name: item.shortname,
                description: htmlToInlineText(item.summary)
              },
              size: isMobile ? CardSize.medium : CardSize.large,
              title: item.shortname,
              content: htmlToInlineText(item.summary),
              progress: item.progress,
              tags: [{ children: item.coursecategory }]
            }))}
          />
        ) : (
          <CoursesEmpty className={s.CoursesPage__empty} />
        )}
      </div>
    </>
  );
}
