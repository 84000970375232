import { isNil } from 'lodash-es';
import { MoodleQueryOptions, useMoodleAPI } from '../useMoodleAPI';
import {
  ModQuizGetAttemptDataData,
  ModQuizGetAttemptDataQuery,
  ModQuizGetAttemptDataQueryArgs
} from '../moodleTypes/_functions/mod_quiz_get_attempt_data';

export function useQuizAttempt(
  { attemptid, page, ...props }: Partial<ModQuizGetAttemptDataQueryArgs> = {},
  options?: MoodleQueryOptions<ModQuizGetAttemptDataData>
) {
  return useMoodleAPI<ModQuizGetAttemptDataQuery, ModQuizGetAttemptDataData>(
    !!attemptid &&
      !isNil(page) && {
        wsfunction: 'mod_quiz_get_attempt_data',
        attemptid,
        page,
        ...props
      },
    options
  );
}
