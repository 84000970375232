import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import { Footer } from '../components';
import { BreadcrumbsProps } from '../../../components/Breadcrumbs';
import { Header } from './Header';
import s from './InfoLayout.module.scss';

export interface InfoLayoutProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Хлебные крошки
   */
  breadcrumbs?: BreadcrumbsProps['items'];
}

export const InfoLayout: ReactFCC<InfoLayoutProps> = (props) => {
  const { children, className, breadcrumbs } = props;

  return (
    <div className={clsx(s.InfoLayout, className)}>
      <Header className={s.InfoLayout__header} breadcrumbs={breadcrumbs} />

      <div className={s.InfoLayout__contentOuter}>
        <div className={s.InfoLayout__content}>{children}</div>
      </div>

      <div className={s.InfoLayout__footerOuter}>
        <Footer className={s.InfoLayout__footer} />
      </div>
    </div>
  );
};
