import { Link, LinkProps, To } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { CardSize } from '../types';
import { AnchorPropsWithoutRef } from '../../../../utils/types';
import s from './CardLayout.module.scss';

export interface CardLayoutProps extends Omit<AnchorPropsWithoutRef, 'children' | 'content'> {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Ссылка, на которую ведет клик по карточке
   */
  to?: To;
  /**
   * Дополнительные параметры перехода по ссылке
   */
  state?: LinkProps['state'];
  /**
   * Состояние дизейбла
   */
  disabled?: boolean;
  /**
   * Состояние загрузки
   */
  loading?: boolean;
  /**
   * Размер карточки ("large", "medium")
   */
  size?: CardSize;
}

export const CardLayout: ReactFCC<CardLayoutProps> = (props) => {
  const { children, className, to = '.', state, disabled, loading, size = CardSize.medium, ...restProps } = props;

  return (
    <Link
      className={clsx(s.CardLayout, className, s[`CardLayout_size_${size}`], {
        [s.CardLayout_disabled]: disabled,
        [s.CardLayout_loading]: loading
      })}
      to={to}
      state={state}
      {...restProps}>
      {children}
    </Link>
  );
};
