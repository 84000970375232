import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { IconButton, IconButtonVariant } from '../../../../components/IconButton';
import { Icons } from '../../../../components/Icons';
import { useContextMenu } from '../../../../components/ContextMenu/useContextMenu';
import { ContextMenu } from '../../../../components/ContextMenu';
import { PROFILE_PAGE_ROUTE } from '../../../../app/routes';
import { useAuth } from '../../../../app/providers/auth';
import { useUser } from '../../../../app/providers/siteInfo';
import s from './Header.module.scss';

export interface HeaderProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Обработчик закрытия сайдбара
   */
  onOpenSidebar: () => void;
}

export const Header: ReactFCC<HeaderProps> = (props) => {
  const { children, className, onOpenSidebar } = props;

  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const { data } = useUser();

  const { logOut } = useAuth();

  const referenceRef = useRef<HTMLButtonElement>(null);
  const { toggleContextMenu, ...contextMenuProps } = useContextMenu({
    items: [
      {
        children: 'Профиль',
        icon: Icons.USER,
        divider: true,
        onClick: () => navigate(PROFILE_PAGE_ROUTE)
      },
      {
        children: 'Выход',
        icon: Icons.LOGOUT,
        onClick: () => logOut()
      }
    ],
    referenceRef
  });

  return (
    <div className={clsx(s.Header, className)}>
      <div className={s.Header__main}>
        <button className={s.Header__user} onClick={() => toggleContextMenu()} ref={referenceRef}>
          <div className={s.Header__name}>{data?.fullname}</div>
          <div className={s.Header__email}>{data?.email}</div>
        </button>

        {isMobile && <IconButton icon={Icons.BURGER} variant={IconButtonVariant.ghost} onClick={onOpenSidebar} />}

        <ContextMenu {...contextMenuProps} />
      </div>

      <div className={s.Header__additional}>{children}</div>
    </div>
  );
};
