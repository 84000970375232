import { useCallback, useEffect, useRef, useState } from 'react';
import { useSingleTimeout } from 'hooks/useSingleTimeout';

export type UseQuizTimerProps = {
  endtime: number;
  onTimeEnd: () => void;
};

export function useQuizTimer({ endtime, onTimeEnd }: UseQuizTimerProps) {
  const [time, setTime] = useState(endtime);

  const timeout = useSingleTimeout();

  const onceRef = useRef(false);

  const processTimeout = useCallback(() => {
    if (!endtime) {
      return;
    }

    if (endtime * 1000 <= Date.now()) {
      timeout.clear();
      onTimeEnd();
      return;
    }

    let time = Math.floor((endtime * 1000 - Date.now()) / 1000);
    if (time < 0) time = 0;
    setTime(time);
    timeout.set(processTimeout, 1000);
  }, [endtime, onTimeEnd, timeout]);

  useEffect(() => {
    if (onceRef.current) {
      return;
    }

    if (endtime) {
      processTimeout();
    }
  }, [endtime, processTimeout, timeout]);

  return time;
}
