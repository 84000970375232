import {
  CoreCourseGetCourseModuleData,
  CoreCourseGetCourseModuleQuery
} from '../moodleTypes/_functions/core_course_get_course_module';
import { useMoodleAPI } from '../useMoodleAPI';

export const useCourseModule = (cmid?: string | number) => {
  return useMoodleAPI<CoreCourseGetCourseModuleQuery, CoreCourseGetCourseModuleData>(
    !!cmid && {
      wsfunction: 'core_course_get_course_module',
      cmid
    }
  );
};
