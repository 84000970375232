import { ElementType, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import clsx from 'clsx';
import { ReactFCC } from 'utils/ReactFCC';
import { Head } from 'components/Head';
import { useUrlParam } from 'hooks/useUrlParam';
import {
  COURSE_PAGE_PARAM,
  COURSES_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  LESSON_PAGE_PARAM,
  PathBuilder,
  URL_KEY_TAB
} from 'app/routes';
import { IconButton, IconButtonSize, IconButtonVariant } from 'components/IconButton';
import { Icons } from 'components/Icons';
import { Heading, HeadingSize } from 'components/Heading';
import { Text, TextColor, TextVariant } from 'components/Text';
import { Button } from 'components/Button';
import { useIsMobile } from 'hooks/useIsMobile';
import { ProgressLine, ProgressLineSize } from 'components/Progress';
import { MoodleError, MoodleErrorType, useReadableModuleData, useViewScorm, useViewResource } from 'store/moodleAPI';
import { ModuleName } from 'store/moodleAPI/moodleTypes/Module';
import { checkIsSystemCourse } from 'store/moodleAPI/utils/checkIsSystemCourse';
import { Tabs } from 'components/Tabs';
import { useIsTablet } from 'hooks/useIsTablet';
import { useBatchedQueryParam } from 'app/providers/query-param';
import { useViewPage } from '../../store/moodleAPI/hooks/useViewPage';
import { usePageData } from '../../store/moodleAPI/hooks/usePageData';
import { TabsQuiz } from './lesson.types';
import { LessonPageContentScorm } from './LessonPageContentScorm';
import { LessonPageContentFile } from './LessonPageContentFile';
import { LessonPageContentPage } from './LessonPageContentPage';
import { LessonPageContentQuiz } from './LessonPageContentQuiz';
import s from './LessonPage.module.scss';

export const LessonPage: ReactFCC = () => {
  const { pathname } = useLocation();

  const courseId = useUrlParam(COURSE_PAGE_PARAM, { parser: (value: string) => Number(value) || void 0 });
  const lessonId = useUrlParam(LESSON_PAGE_PARAM, { parser: (value: string) => Number(value) || void 0 });

  const { showBoundary } = useErrorBoundary();

  const {
    isLoading,
    instanceId,
    course,
    title,
    modname,
    moduleIndex,
    modulesLength,
    prevModuleId,
    nextModuleId,
    resources,
    scormId,
    error,
    launch: scoId,
    completiondata
  } = useReadableModuleData(courseId, lessonId);

  const { content: pageContent, isLoading: isPageLoading } = usePageData(
    modname === ModuleName.page && courseId,
    modname === ModuleName.page && lessonId
  );

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const [titleExtended, setTitleExtended] = useState(!isMobile);

  const ButtonComponent = (isTablet ? IconButton : Button) as ElementType;

  const progressRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const completedRef = useRef(false);

  const viewPage = useViewPage();
  const viewScorm = useViewScorm();
  const viewResource = useViewResource();

  const showProgress = modname && modname !== ModuleName.quiz;

  const getProgressPercentage = useCallback(() => {
    if (containerRef.current) {
      // Прогресс прочтения это: отношение прочитанного к общему объёму, где:
      //  прочитанное = положение низа тела урока к нижней границе экрана,
      //  общий объём = высота тела урока
      const { top: contentTop, height: contentHeight } = containerRef.current.getBoundingClientRect();
      // Прочитанный объём (высота прочитанной части тела урока)
      const viewedHeight = document.documentElement.clientHeight - contentTop;
      // Получаем отношение, учитывая, что более 100% - 1.0 - рассматривать не нужно
      const progress = Math.min(1, viewedHeight / contentHeight);

      return progress * 100;
    }

    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  useEffect(() => {
    function scrollHandler() {
      const scrollPercentage = getProgressPercentage();

      if (progressRef.current) {
        progressRef.current.style.width = `${scrollPercentage}%`;
      }

      if (completedRef.current || scrollPercentage < 100) {
        return;
      }

      completedRef.current = true;

      switch (modname) {
        case ModuleName.resource: {
          viewResource(instanceId);
          break;
        }
        case ModuleName.page: {
          viewPage(instanceId);
          break;
        }
        case ModuleName.scorm: {
          viewScorm(scormId);
          break;
        }
      }
    }

    if (showProgress) {
      document.addEventListener('scroll', scrollHandler);
      scrollHandler();
    }

    return () => {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [showProgress, isLoading, getProgressPercentage, instanceId, scormId, modname, viewPage, viewScorm, viewResource]);

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = `0%`;
    }
    setTitleExtended(false);
  }, [pathname]);

  useEffect(() => {
    setTitleExtended(!isMobile);
  }, [isMobile]);

  const tabs = useMemo(() => {
    switch (modname) {
      case ModuleName.quiz:
        return [
          {
            id: TabsQuiz.About,
            children: 'Описание теста'
          },
          {
            id: TabsQuiz.Result,
            children: 'Результат'
          }
        ];
      default:
        return [];
    }
  }, [modname]);

  const defaultActiveTabId = (() => {
    if (!tabs.length) return '';
    switch (modname) {
      case ModuleName.quiz:
        return TabsQuiz.About;
      default:
        return tabs[0].id;
    }
  })();

  const [tab, setTab] = useBatchedQueryParam(URL_KEY_TAB, { defaultValue: defaultActiveTabId });

  const activeTabId = tab || defaultActiveTabId;

  const isSystemCourse = !!course && checkIsSystemCourse(course.shortname);

  if (isSystemCourse) {
    showBoundary(new MoodleError("The module haven't been found", MoodleErrorType.ERR_NOT_FOUND));
    return null;
  }

  if (error) {
    showBoundary(error);
  }

  const moduleContent = (() => {
    if (!modname) return null;

    switch (modname) {
      case ModuleName.page:
        return (
          <LessonPageContentPage
            className={s.LessonPage__content}
            ref={containerRef}
            content={pageContent}
            isLoading={isPageLoading}
          />
        );
      case ModuleName.quiz:
        return (
          <LessonPageContentQuiz
            className={s.LessonPage__content}
            tab={activeTabId as TabsQuiz}
            courseId={courseId}
            instanceId={instanceId}
            completionData={completiondata}
          />
        );
      case ModuleName.resource:
        return (
          <LessonPageContentFile className={s.LessonPage__attachments} items={resources || []} ref={containerRef} />
        );
      default:
        return (
          <LessonPageContentScorm
            className={clsx(s.LessonPage__content, s.LessonPage__content_scorm)}
            scormId={scormId}
            moduleId={lessonId}
            scoId={scoId}
            title={title}
            ref={containerRef}
          />
        );
    }
  })();

  const lessonPageCount = (
    <Text className={s.LessonPage__count} variant={TextVariant.BODY_M} colorVariant={TextColor.SECONDARY_70}>
      Лекция {moduleIndex + 1} из {modulesLength}
    </Text>
  );

  return (
    <>
      <Head title={'Лекция'} />

      <div className={s.LessonPage}>
        {!isLoading && (
          <div className={s.LessonPage__headerContainer}>
            <div
              className={clsx(s.LessonPage__header, {
                [s.LessonPage__header_extended]: titleExtended
              })}>
              <div className={s.LessonPage__title}>
                <IconButton
                  className={s.LessonPage__backButton}
                  component={Link}
                  to={
                    isSystemCourse
                      ? HOME_PAGE_ROUTE
                      : courseId
                      ? PathBuilder.getCoursePath(courseId)
                      : COURSES_PAGE_ROUTE
                  }
                  icon={Icons.CHEVRON_LEFT}
                  variant={IconButtonVariant.ghost}
                  size={IconButtonSize.small}
                />

                <Heading
                  className={clsx(s.LessonPage__heading, {
                    [s.LessonPage__heading_extended]: titleExtended
                  })}
                  size={HeadingSize.H3}
                  onClick={() => setTitleExtended(!titleExtended)}>
                  {title}
                </Heading>
              </div>

              {!isTablet && lessonPageCount}

              {titleExtended && !!course && !checkIsSystemCourse(course.shortname) && (
                <Heading className={s.LessonPage__course} size={HeadingSize.H5}>
                  {course.shortname}
                </Heading>
              )}

              <div className={s.LessonPage__controls}>
                <ButtonComponent
                  className={s.LessonPage__control}
                  component={Link}
                  to={courseId && prevModuleId ? PathBuilder.getLessonPath(courseId, prevModuleId) : '.'}
                  variant={'tertiary'}
                  size={'medium'}
                  disabled={!prevModuleId}
                  {...(isTablet ? { icon: Icons.CHEVRON_LEFT } : { leftIcon: Icons.CHEVRON_LEFT })}>
                  {!isTablet && 'Предыдущий урок'}
                </ButtonComponent>
                {isTablet && lessonPageCount}
                <ButtonComponent
                  className={s.LessonPage__control}
                  component={Link}
                  to={courseId && nextModuleId ? PathBuilder.getLessonPath(courseId, nextModuleId) : '.'}
                  variant={'tertiary'}
                  size={'medium'}
                  disabled={!nextModuleId}
                  {...(isTablet ? { icon: Icons.CHEVRON_RIGHT } : { rightIcon: Icons.CHEVRON_RIGHT })}>
                  {!isTablet && 'Следующий урок'}
                </ButtonComponent>
              </div>
            </div>

            {!!tabs.length && (
              <Tabs className={s.LessonPage__tabs} activeId={activeTabId} setActive={setTab} tabs={tabs} />
            )}

            {showProgress && (
              <ProgressLine className={s.LessonPage__progress} size={ProgressLineSize.small} valueRef={progressRef} />
            )}
          </div>
        )}

        <div
          className={clsx(s.LessonPage__contentContainer, {
            [s.LessonPage__contentContainer_full]: modname === ModuleName.scorm
          })}>
          {moduleContent}
        </div>
      </div>
    </>
  );
};
