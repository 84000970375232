import { Link } from 'react-router-dom';
import { ReactFCC } from '../../utils/ReactFCC';
import { Head } from '../../components/Head';
import { InfoLayout } from '../_layouts/InfoLayout';
import { Heading, HeadingSize } from '../../components/Heading';
import { Text, TextColor, TextVariant } from '../../components/Text';
import { Button, ButtonSize } from '../../components/Button';
import { HOME_PAGE_ROUTE } from '../../app/routes';
import { MoodleError, MoodleErrorType } from '../../store/moodleAPI';
import notFoundImage from './assets/404.png';
import serverErrorImage from './assets/500.png';
import s from './ErrorPage.module.scss';

export enum ErrorVariant {
  not_found = 'not_found',
  server_error = 'server_error'
}

export interface ErrorPageProps {
  variant?: ErrorVariant;
  error?: any;
  resetErrorBoundary?: () => void;
}

const notFoundMessages = ['Курс или элемент курса не доступны', 'Не удается найти данную запись'];

function getErrorVariant(error: Error): ErrorVariant {
  if (!(error instanceof MoodleError)) {
    return ErrorVariant.server_error;
  }

  if (error.type === MoodleErrorType.ERR_NOT_FOUND) {
    return ErrorVariant.not_found;
  }

  if (notFoundMessages.some((message) => error.message.includes(message))) {
    return ErrorVariant.not_found;
  }

  return ErrorVariant.server_error;
}

export const ErrorPage: ReactFCC<ErrorPageProps> = (props) => {
  const { variant: variantProp, error, resetErrorBoundary } = props;

  let variant: ErrorVariant;

  if (!variantProp && error) {
    variant = getErrorVariant(error);
  } else {
    variant = ErrorVariant.not_found;
  }

  return (
    <>
      <Head title={'Ошибка'} />

      <InfoLayout>
        <div className={s.ErrorPage}>
          <div className={s.ErrorPage__content}>
            {variant === ErrorVariant.not_found ? (
              <img className={s.ErrorPage__image} src={notFoundImage} alt={'404'} />
            ) : (
              <img className={s.ErrorPage__image} src={serverErrorImage} alt={'500'} />
            )}

            <Heading className={s.ErrorPage__heading} size={HeadingSize.H2}>
              {variant === ErrorVariant.not_found ? '404: страница не найдена' : '500: ошибка на сервере'}
            </Heading>

            <Text className={s.ErrorPage__text} variant={TextVariant.BODY_M} colorVariant={TextColor.SECONDARY_70}>
              {variant === ErrorVariant.not_found
                ? 'Неправильно набран адрес или такой страницы не существует'
                : 'На сервере произошла непредвиденная ошибка. Пожалуйста, подождите, скоро она будет исправлена'}
            </Text>

            <Button
              className={s.ErrorPage__button}
              component={Link}
              to={HOME_PAGE_ROUTE}
              onClick={() => resetErrorBoundary?.()}
              size={ButtonSize.large}>
              На главную
            </Button>
          </div>
        </div>
      </InfoLayout>
    </>
  );
};
