import { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../utils/ReactFCC';
import { TabItem, TabItemProps } from './TabItem';
import s from './Tabs.module.scss';

export interface TabsProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Элементы табов
   */
  tabs: TabItemProps[];
  /**
   * ID активной вкладки
   */
  activeId: TabItemProps['id'];
  /**
   * Сеттер ID активной вкладки
   */
  setActive: Dispatch<SetStateAction<TabItemProps['id']>>;
}

export const Tabs: ReactFCC<TabsProps> = (props) => {
  const { className, tabs, activeId, setActive } = props;

  return (
    <div className={clsx(s.Tabs, className)}>
      {tabs.map((tab, index) => (
        <TabItem active={tab.id === activeId} onClick={(id) => setActive(id)} {...tab} key={index} />
      ))}
    </div>
  );
};
