import { useMoodleAPI } from '../useMoodleAPI';
import {
  ModPageGetPagesByCoursesData,
  ModPageGetPagesByCoursesQuery
} from '../moodleTypes/_functions/mod_page_get_pages_by_courses';

export const usePageData = (courseId?: string | number | false, moduleId?: string | number | false) => {
  const {
    data: pagesData,
    isLoading: pagesLoading,
    error: pagesError
  } = useMoodleAPI<ModPageGetPagesByCoursesQuery, ModPageGetPagesByCoursesData>(
    !!courseId && {
      wsfunction: 'mod_page_get_pages_by_courses',
      courseids: [+courseId]
    }
  );

  const page = pagesData?.pages.find((p) => p.coursemodule === moduleId);

  return {
    content: page?.content,
    isLoading: pagesLoading,
    error: pagesError
  };
};
