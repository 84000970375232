import { isNil } from 'lodash-es';
import {
  ModQuizViewAttemptData,
  ModQuizViewAttemptQuery,
  ModQuizViewAttemptQueryArgs
} from '../moodleTypes/_functions/mod_quiz_view_attempt';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizViewAttempt({ attemptid, page, ...props }: Partial<ModQuizViewAttemptQueryArgs> = {}) {
  return useMoodleAPI<ModQuizViewAttemptQuery, ModQuizViewAttemptData>(
    !!attemptid &&
      !isNil(page) && {
        wsfunction: 'mod_quiz_view_attempt',
        attemptid,
        page,
        ...props
      }
  );
}
