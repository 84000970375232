import { ComponentProps, ElementType, JSX, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { Icon, IconProps, IconSize } from 'components/Icon';
import { Loader, LoaderSize } from '../Loader';
import { PolyExtends } from '../../utils/types';
import s from './IconButton.module.scss';

export enum IconButtonSize {
  small_xx = 'small_xx',
  small_x = 'small_x',
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export enum IconButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  ghost = 'ghost'
}

export const IconButtonDefaultComponent = 'button' as const;
export type IconButtonDefaultComponentType = typeof IconButtonDefaultComponent;

export type IconButtonPropsSelf<ComponentType extends ElementType = IconButtonDefaultComponentType> = {
  /**
   * Базовый компонент
   *
   * @type React.ElementType
   */
  component?: ComponentType;
  /**
   * Размер IconButton ('small_xx', `small_x`, `small`, `medium`, `large`)
   */
  size?: IconButtonSize;
  /**
   * Размер Icon
   */
  iconSize?: IconSize;
  /**
   * Вариант оформления Button (`primary`, `secondary`, `tertiary`, 'ghost')
   */
  variant?: IconButtonVariant;
  /**
   * Заблокированное состояние Button
   */
  disabled?: boolean;
  /**
   * Проп для контролируемого включения состояния ховера
   */
  hovered?: boolean;
  /**
   * Состояние со спиннером
   */
  isLoading?: boolean;
  /**
   * Иконка
   */
  icon?: IconProps['icon'];
  /**
   * Цвет кнопки
   */
  color?: string;
  /**
   * Цвет иконки
   */
  iconColor?: string;
  /**
   * Дополнительные css-классы элементов:
   * * content – контейнер содержимого
   * * icon – класс иконки
   */
  classes?: {
    content?: string;
    icon?: string;
  };
  /**
   * Слот для произвольного контента кнопки. Приоритетнее, чем icon.
   */
  children?: ReactNode;
  /**
   * Реф на корневой элемент
   */
  innerRef?: Ref<HTMLElement>;
};

export type IconButtonProps<ComponentType extends ElementType = IconButtonDefaultComponentType> = PolyExtends<
  ComponentType,
  IconButtonPropsSelf<ComponentType>,
  ComponentProps<ComponentType>
>;

export function IconButton(props: IconButtonProps<'button'>): JSX.Element;
export function IconButton<ComponentType extends ElementType>(props: IconButtonProps<ComponentType>): JSX.Element;
export function IconButton<ComponentType extends ElementType>({
  component,
  className,
  size = IconButtonSize.medium,
  iconSize = IconSize.medium,
  variant = IconButtonVariant.primary,
  disabled,
  hovered,
  isLoading,
  children,
  color,
  icon,
  iconColor,
  classes,
  innerRef,
  style,
  ...props
}: IconButtonProps<ComponentType>) {
  const Base = (component || 'button') as ElementType;

  return (
    <Base
      ref={innerRef}
      className={clsx(
        s.IconButton,
        {
          [s[`IconButton_variant_${variant}`]]: variant,
          [s[`IconButton_size_${size}`]]: size,
          [s.IconButton_disabled]: disabled,
          [s.IconButton_hovered]: hovered
        },
        className
      )}
      type="button"
      style={{
        backgroundColor: color,
        ...style
      }}
      disabled={disabled}
      {...props}>
      {isLoading ? (
        <Loader
          className={s.Button__loader}
          size={size === IconButtonSize.small ? LoaderSize.small : LoaderSize.medium}
        />
      ) : (
        <div className={clsx(s.IconButton__content, classes?.content)}>
          {children}

          {!children && icon && (
            <Icon
              className={classes?.icon}
              icon={icon}
              size={iconSize}
              color={(!disabled && iconColor) || 'currentColor'}
            />
          )}
        </div>
      )}
    </Base>
  );
}
