import { Transition } from 'react-transition-group';
import clsx from 'clsx';
import { EXITED } from 'react-transition-group/Transition';
import { ReactFCC } from '../../../../utils/ReactFCC';
import { Logo } from '../../../../components/Logo';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../../../components/IconButton';
import { Icons } from '../../../../components/Icons';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import {
  COURSES_PAGE_ROUTE,
  HOME_PAGE_ROUTE,
  MESSAGES_PAGE_ROUTE,
  TESTS_PAGE_ROUTE,
  VIDEO_PAGE_ROUTE
} from '../../../../app/routes';
import { SidebarItem, SidebarItemProps } from './SidebarItem';
import s from './Sidebar.module.scss';

export interface SidebarProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Состояние открытости сайдбара
   */
  isOpen: boolean;
  /**
   * Сеттер состояния открытости сайдбара
   */
  setOpen: (open: boolean) => void;
}

const menu: SidebarItemProps[] = [
  {
    to: HOME_PAGE_ROUTE,
    icon: Icons.HOME,
    children: 'Главная'
  },
  {
    to: COURSES_PAGE_ROUTE,
    icon: Icons.LEARNING,
    children: 'Мои курсы'
  },
  {
    to: TESTS_PAGE_ROUTE,
    icon: Icons.TEST,
    children: 'Тестирование'
  },
  {
    to: MESSAGES_PAGE_ROUTE,
    icon: Icons.MESSAGES,
    children: 'Мессенджер'
  },
  {
    to: VIDEO_PAGE_ROUTE,
    icon: Icons.VIDEO,
    children: 'Видеоконференцсвязь'
  }
];

export const Sidebar: ReactFCC<SidebarProps> = (props) => {
  const { className, isOpen, setOpen } = props;

  const isMobile = useIsMobile();

  const onClickLink = () => {
    if (isMobile) {
      setOpen(false);
    }
  };

  return (
    <Transition timeout={150} in={isOpen}>
      {(state) => {
        const openState = state !== EXITED || isMobile;
        return (
          <div
            className={clsx(
              s.Sidebar,
              {
                [s.Sidebar_open]: isOpen
              },
              className
            )}>
            <div
              className={clsx(s.Sidebar__header, {
                [s.Sidebar__header_open]: openState
              })}>
              <Logo className={s.Sidebar__logo} shrink={!openState} small />
              <IconButton
                classes={{
                  icon: s.Sidebar__toggleIcon
                }}
                icon={isMobile ? Icons.CANCEL : Icons.FORWARD}
                variant={IconButtonVariant.ghost}
                size={IconButtonSize.small}
                onClick={() => setOpen(!isOpen)}
              />
            </div>

            <div className={s.Sidebar__menu}>
              {menu.map((item, index) => (
                <SidebarItem {...item} isOpen={openState} onClick={onClickLink} key={index} />
              ))}
            </div>
          </div>
        );
      }}
    </Transition>
  );
};
