import {
  COURSE_SYSTEM_AUTH,
  COURSE_SYSTEM_BANNER,
  COURSE_SYSTEM_FILES,
  COURSE_SYSTEM_HOME,
  COURSE_SYSTEM_POLICY,
  COURSE_SYSTEM_RULES
} from '../constants';

export const checkIsSystemCourse = (name: string) => {
  return [
    COURSE_SYSTEM_POLICY,
    COURSE_SYSTEM_RULES,
    COURSE_SYSTEM_BANNER,
    COURSE_SYSTEM_FILES,
    COURSE_SYSTEM_AUTH,
    COURSE_SYSTEM_HOME
  ].includes(name);
};
