import {
  ModQuizGetAttemptSummaryData,
  ModQuizGetAttemptSummaryQuery,
  ModQuizGetAttemptSummaryQueryArgs
} from '../moodleTypes/_functions/mod_quiz_get_attempt_summary';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizAttemptSummary({ attemptid, ...props }: Partial<ModQuizGetAttemptSummaryQueryArgs> = {}) {
  return useMoodleAPI<ModQuizGetAttemptSummaryQuery, ModQuizGetAttemptSummaryData>(
    !!attemptid && {
      wsfunction: 'mod_quiz_get_attempt_summary',
      attemptid,
      ...props
    }
  );
}
