import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { useQuizData, UseQuizDataProps } from 'store/moodleAPI/hooks/useQuizData';
import { Loader, LoaderSize } from 'components/Loader';
import { useStartQuiz } from 'store/moodleAPI/hooks/useStartQuiz';
import { PathBuilder } from 'app/routes';
import { useQuizAccessOptions } from 'store/moodleAPI/hooks/useQuizAccessOptions';
import { CompletionState } from 'store/moodleAPI/moodleTypes/Module';
import { TabsQuiz } from '../lesson.types';
import { QuizAbout } from './QuizAbout/QuizAbout';
import { QuizResult } from './QuizResult/QuizResult';
import s from './LessonPageContentQuiz.module.scss';

export interface LessonPageContentQuizProps extends UseQuizDataProps {
  className?: string;
  tab: TabsQuiz;
}

export const LessonPageContentQuiz = ({
  className,
  tab,
  courseId,
  instanceId,
  completionData
}: LessonPageContentQuizProps) => {
  const navigate = useNavigate();
  const {
    quiz,
    accessData,
    attemptAccessData,
    attemptInProgress,
    questionTypesData,
    attemptsData,
    lastAttemptId,
    gradeData,
    isLoading,
    error
  } = useQuizData({
    courseId,
    instanceId,
    completionData
  });

  const quizId = quiz?.id;
  const cmid = quiz?.coursemodule;
  const accessrules = accessData?.accessrules || [];
  const preventaccessreasons = accessData?.preventaccessreasons || [];
  const preventnewattemptreasons = attemptAccessData?.preventnewattemptreasons || [];
  const attemptsDoneAmount = attemptsData?.attempts?.length || 0;

  const hasData = !isNil(courseId) && !isNil(quizId) && !isNil(cmid);
  const canAttempt =
    hasData && (!!attemptInProgress || preventnewattemptreasons.length === 0 || accessData?.canpreview);

  const { startQuiz, isLoading: startQuizLoading } = useStartQuiz({
    quizId,
    attemptInProgress: attemptInProgress
  });

  const { accessDisabledByOptions, accessClosedReason, isAccessEnabled } = useQuizAccessOptions({
    error,
    questionTypesData,
    completionState: completionData?.state,
    activerulenames: accessData?.activerulenames || [],
    accessrules,
    preventaccessreasons
  });

  const handleStartQuiz = useCallback(() => {
    if (!canAttempt) return;

    startQuiz().then((result) => {
      if (result && !isNil(result.id)) {
        navigate(PathBuilder.getQuizAttemptPath(courseId, quizId, result.id, cmid, result.currentpage));
      }
    });
  }, [courseId, cmid, quizId, startQuiz, canAttempt, navigate]);

  // TODO: хорошо бы проверить условия
  const accessMessages = (() => {
    const msgArray: string[] = [];

    if (!isAccessEnabled && accessClosedReason) {
      msgArray.push(accessClosedReason);
    } else {
      msgArray.push(...preventaccessreasons);

      if (!canAttempt) {
        msgArray.push(...preventnewattemptreasons);
      }
    }

    if (isAccessEnabled && canAttempt && accessDisabledByOptions) {
      msgArray.push(accessDisabledByOptions);
    }

    return msgArray.filter(Boolean);
  })();

  const hasAttemptAccess = !accessDisabledByOptions && isAccessEnabled && canAttempt;

  const startButtonText = attemptInProgress
    ? 'Продолжить последнюю попытку'
    : // todo не привязывать проверку к completionState
    completionData?.state === CompletionState.Incomplete && !lastAttemptId
    ? 'Начать тестирование'
    : 'Повторить тестирование';

  return (
    <div className={className}>
      {isLoading ? (
        <Loader className={s.Loader} size={LoaderSize.large} />
      ) : (
        quiz && (
          <>
            {tab === TabsQuiz.About && (
              <QuizAbout
                quiz={quiz}
                gradeData={gradeData}
                attemptsDoneAmount={attemptsDoneAmount}
                hasAttemptAccess={!!hasAttemptAccess}
                accessMessages={accessMessages}
                startQuiz={handleStartQuiz}
                startQuizLoading={startQuizLoading}
                startButtonText={startButtonText}
              />
            )}
            {tab === TabsQuiz.Result && (
              <QuizResult
                quiz={quiz}
                gradeData={gradeData}
                lastAttemptId={lastAttemptId}
                inProgress={!!attemptInProgress}
                hasAttemptAccess={!!hasAttemptAccess}
                completionData={completionData}
                startQuiz={handleStartQuiz}
                startQuizLoading={startQuizLoading}
              />
            )}
          </>
        )
      )}
    </div>
  );
};
