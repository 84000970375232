import { CardLayoutProps } from './CardLayout';

export enum CardSize {
  large = 'large',
  medium = 'medium'
}

export interface CardBaseProps extends CardLayoutProps {
  title?: string;
  content?: string;
}
