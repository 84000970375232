import {
  ModQuizGetAttemptReviewData,
  ModQuizGetAttemptReviewQuery,
  ModQuizGetAttemptReviewQueryArgs
} from '../moodleTypes/_functions/mod_quiz_get_attempt_review';
import { useMoodleAPI } from '../useMoodleAPI';

export function useQuizAttemptReview({ attemptid, ...props }: Partial<ModQuizGetAttemptReviewQueryArgs> = {}) {
  return useMoodleAPI<ModQuizGetAttemptReviewQuery, ModQuizGetAttemptReviewData>(
    !!attemptid && {
      wsfunction: 'mod_quiz_get_attempt_review',
      attemptid,
      ...props
    }
  );
}
