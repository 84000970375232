import React from 'react';
import axios from 'axios';
import qs from 'qs';
import { AuthContext, IAuthContext } from '../auth/AuthContext';
import { AuthenticationError, AuthenticationErrorMessage } from '../auth/AuthenticationError';
import { API_URL } from '../../../config';
import { AxiosClientContext } from './AxiosClientContext';

interface IAxiosClientProviderProps {
  children: React.ReactNode;
}

export class AxiosClientProvider extends React.Component<IAxiosClientProviderProps> {
  // AuthContext
  static contextType = AuthContext;
  context!: IAuthContext;

  axiosInstance = axios.create({
    method: 'POST',
    baseURL: `${API_URL}/webservice/rest/server.php`,
    timeout: 10000
  });

  constructor(props: IAxiosClientProviderProps, context: IAuthContext) {
    super(props, context);

    this.axiosInstance.interceptors.request.use((config) =>
      context.getToken().then((token) => {
        return {
          ...config,
          data: qs.stringify({
            ...config.data,
            moodlewssettinglang: 'ru',
            moodlewsrestformat: 'json',
            moodlewssettingfilter: true,
            wstoken: config.data.wstoken ?? token
          })
        };
      })
    );

    // error handling
    this.axiosInstance.interceptors.response.use((response) => {
      // response.data может не быть объектом (а быть, например, числом или строкой)
      if (typeof response.data === 'object' && response.data !== null) {
        // попытка в нормальные сообщения об ошибках
        if ('errorcode' in response.data) {
          if (response.data.errorcode === 'invalidtoken') {
            context.logOut(new AuthenticationError(AuthenticationErrorMessage.ERR_AUTH));
          }

          return Promise.reject(response.data?.message || JSON.stringify(response.data));
        }
      } else if (typeof response.data === 'string') {
        // и для двойных склеенных ошибок
        if (response.data.includes('errorcode')) {
          try {
            // попытка вытащить сообщение об ошибке и отсюда
            return Promise.reject(JSON.parse(response.data.split('\n')[0]).message);
          } catch (err) {
            return Promise.reject(response.data);
          }
        }
      }

      return response;
    });
  }

  render() {
    return <AxiosClientContext.Provider value={this.axiosInstance}>{this.props.children}</AxiosClientContext.Provider>;
  }
}
