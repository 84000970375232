import { useMoodleAPILazyQuery } from '../useMoodleAPILazyQuery';
import {
  ModResourceViewResourceData,
  ModResourceViewResourceQuery
} from '../moodleTypes/_functions/mod_resource_view_resource';

export const useViewResource = () => {
  const requestMoodleApi = useMoodleAPILazyQuery();

  return (id?: number | string | false) => {
    if (id) {
      return requestMoodleApi<ModResourceViewResourceQuery, ModResourceViewResourceData>({
        wsfunction: 'mod_resource_view_resource',
        resourceid: id
      });
    }
  };
};
