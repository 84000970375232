export enum MoodleErrorType {
  ERR_NOT_FOUND = 'ERR_NOT_FOUND',
  ERR_UNKNOWN = 'ERR_UNKNOWN'
}

export class MoodleError extends Error {
  type: MoodleErrorType;

  constructor(message?: string, type: MoodleErrorType = MoodleErrorType.ERR_UNKNOWN) {
    super(message);
    this.name = 'MoodleError';
    this.type = type;
  }
}
