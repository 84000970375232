import React, { useMemo } from 'react';
import { formatDuration, intervalToDuration } from 'date-fns';
import { isNil } from 'lodash-es';
import { Heading, HeadingSize } from 'components/Heading';
import { Text, TextColor, TextVariant } from 'components/Text';
import { Button, ButtonSize, ButtonVariant } from 'components/Button';
import { Icons } from 'components/Icons';
import { useQuizAttemptReview } from 'store/moodleAPI/hooks/useQuizAttemptReview';
import { dateLocale, formatDate } from 'utils/formatDate';
import { formatUnits } from 'utils/formatUnits';
import { Loader, LoaderSize } from 'components/Loader';
import { Badge, BadgeProps, BadgeStatus, BadgeVariant } from 'components/Badge';
import dataPlaceholderSrc from 'assets/images/data-placeholder.svg';
import { QuizDataType } from 'store/moodleAPI/moodleTypes/Quiz';
import { CompletionState, IModule, IModuleCompletionDataDetail } from 'store/moodleAPI/moodleTypes/Module';
import { formatGrade } from '../formatGrade';
import s from './QuizResult.module.scss';

function getDetailCompletionState(
  details: IModuleCompletionDataDetail[],
  name: IModuleCompletionDataDetail['rulename']
): CompletionState | undefined {
  return details.find((d) => d.rulename === name)?.rulevalue.status;
}

export type QuizResultProps = Required<Pick<QuizDataType, 'quiz' | 'gradeData'>> & {
  lastAttemptId?: number;
  inProgress: boolean;
  hasAttemptAccess: boolean;
  startQuizLoading: boolean;
  completionData?: IModule['completiondata'];
  startQuiz: () => void;
};

export const QuizResult = ({
  quiz,
  gradeData,
  lastAttemptId,
  inProgress,
  hasAttemptAccess,
  completionData,
  startQuizLoading,
  startQuiz
}: QuizResultProps) => {
  const { data: attemptReviewData, isLoading: attemptReviewLoading } = useQuizAttemptReview({
    attemptid: inProgress ? undefined : lastAttemptId
  });

  const reviewData = useMemo(() => {
    if (!attemptReviewData) return null;

    const timeStart = (attemptReviewData.attempt.timestart ?? 0) * 1000;
    const dStart = timeStart ? new Date(timeStart) : undefined;
    const dateStart = dStart ? formatDate(dStart, 'dd.MM.yyyy HH:mm') : undefined;

    const timeEnd = (attemptReviewData.attempt.timefinish ?? 0) * 1000;
    const dEnd = timeEnd ? new Date(timeEnd) : undefined;
    const dateEnd = dEnd ? formatDate(dEnd, 'dd.MM.yyyy HH:mm') : undefined;

    const timeSpent =
      dStart && dEnd
        ? formatDuration(
            intervalToDuration({
              start: dStart,
              end: dEnd
            }),
            {
              locale: dateLocale
            }
          )
        : undefined;

    const rightQuestionsAmount = attemptReviewData.questions.filter((q) => q.state === 'gradedright').length;

    const grade = gradeData?.grade;
    const gradetopass = gradeData?.gradetopass;
    const gradeMax = quiz?.grade;
    const decimalpoints = quiz?.decimalpoints;

    const passGrade =
      !isNil(gradetopass) && !isNil(gradeMax) ? formatGrade(gradetopass, gradeMax, decimalpoints, ' из ') : undefined;
    const userGrade = !isNil(grade) && !isNil(gradeMax) ? formatGrade(grade, gradeMax, decimalpoints) : undefined;

    return {
      dateStart,
      dateEnd,
      timeSpent,
      rightQuestionsAmount,
      passGrade,
      userGrade
    };
  }, [quiz, gradeData, attemptReviewData]);

  const status: BadgeProps | null = (() => {
    if (!completionData) return null;

    if (completionData.state === CompletionState.Complete || completionData.state === CompletionState.CompletePass) {
      return {
        status: BadgeStatus.good,
        iconLeft: Icons.CHECK,
        children: 'Пройден'
      };
    }

    if (completionData.state === CompletionState.CompleteFail) {
      return {
        status: BadgeStatus.bad,
        iconLeft: Icons.CANCEL,
        children: 'Не пройден'
      };
    }

    const details = completionData.details;

    if (
      details.length &&
      getDetailCompletionState(details, 'completionview') === CompletionState.Complete &&
      getDetailCompletionState(details, 'completionusegrade') !== CompletionState.Complete
    ) {
      return {
        status: BadgeStatus.neutral,
        iconLeft: Icons.CLOCK,
        children: 'На проверке'
      };
    }

    return null;
  })();

  return (
    <>
      {attemptReviewLoading ? (
        <Loader className={s.Loader} size={LoaderSize.large} />
      ) : !reviewData ? (
        <div className={s.Placeholder}>
          <img src={dataPlaceholderSrc} alt="" />
          <Text variant={TextVariant.BODY_M} colorVariant={TextColor.SECONDARY_60}>
            По этому тестированию еще нет результа
          </Text>
        </div>
      ) : (
        <>
          <div className={s.QuizResult__head}>
            <Heading size={HeadingSize.H4}>Последняя попытка</Heading>
            {status && <Badge variant={BadgeVariant.filled} {...status} />}
          </div>

          {Object.values(reviewData).some(Boolean) && (
            <div className={s.QuizResult__total}>
              {reviewData.dateStart && (
                <>
                  <Text className={s.QuizResult__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                    Тест начат:
                  </Text>
                  <Text className={s.QuizResult__totalValue}>{reviewData.dateStart}</Text>
                </>
              )}

              {reviewData.dateEnd && (
                <>
                  <Text className={s.QuizResult__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                    Тест закончен:
                  </Text>
                  <Text className={s.QuizResult__totalValue}>{reviewData.dateEnd}</Text>
                </>
              )}

              {reviewData.timeSpent && (
                <>
                  <Text className={s.QuizResult__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                    Потрачено времени:
                  </Text>
                  <Text className={s.QuizResult__totalValue}>{reviewData.timeSpent}</Text>
                </>
              )}

              {reviewData.passGrade && (
                <>
                  <Text className={s.QuizResult__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                    Проходная оценка:
                  </Text>
                  <Text className={s.QuizResult__totalValue}>{reviewData.passGrade}</Text>
                </>
              )}

              {reviewData.userGrade && (
                <>
                  <Text className={s.QuizResult__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                    Итоговая оценка за тест:
                  </Text>
                  <Text className={s.QuizResult__totalValue}>{reviewData.userGrade}</Text>
                </>
              )}

              {/*<Text colorVariant={TextColor.SECONDARY_60}>Осталось попыток:</Text>*/}
              {/*<Text>2 из 3</Text>*/}

              <Text className={s.QuizResult__totalTitle} colorVariant={TextColor.SECONDARY_60}>
                Верно отвечено:
              </Text>
              <Text className={s.QuizResult__totalValue}>
                {formatUnits(reviewData.rightQuestionsAmount, ['вопрос', 'вопроса', 'вопросов'])?.join(' ')}
              </Text>
            </div>
          )}

          {hasAttemptAccess && (
            <div className={s.QuizResult__actions}>
              <Button
                className={s.QuizResult__submitButton}
                variant={ButtonVariant.secondary}
                size={ButtonSize.medium}
                rightIcon={Icons.CHEVRON_RIGHT}
                isLoading={startQuizLoading}
                onClick={!startQuizLoading ? startQuiz : undefined}>
                Повторить тестирование
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
