import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Button, ButtonSize, ButtonVariant } from '../../Button';
import { Icons } from '../../Icons';
import s from './DropZone.module.scss';

export interface DropZoneProps extends DropzoneOptions {
  className?: string;
  buttonText?: string;
  errorMessage?: string | React.ReactNode;
  hintMessage?: string | React.ReactNode;
}

export function DropZone({ className, buttonText, hintMessage, errorMessage, ...options }: DropZoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    preventDropOnDocument: true,
    noDrag: true,
    ...options
  });

  return (
    <div className={clsx(s.DropZone, className)}>
      <div className={s.DropZone__zone} {...getRootProps()}>
        <input {...getInputProps()} />
        <Button
          className={s.DropZone__button}
          variant={ButtonVariant.ghost}
          size={ButtonSize.medium}
          leftIcon={Icons.ATTACHMENT}
          tabIndex={-1}>
          {buttonText}
        </Button>
      </div>

      <div
        className={clsx(s.DropZone__message, {
          [s.DropZone__message_error]: !!errorMessage
        })}>
        {errorMessage || hintMessage}
      </div>
    </div>
  );
}
