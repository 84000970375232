import { useMemo } from 'react';
import { isNil } from 'lodash-es';
import { useMoodleAPI } from '../useMoodleAPI';
import {
  ModQuizGetQuizzesByCoursesData,
  ModQuizGetQuizzesByCoursesQuery
} from '../moodleTypes/_functions/mod_quiz_get_quizzes_by_courses';

export type UseQuizProps = {
  courseid?: number;
  quizid?: number;
};

export function useQuiz({ courseid, quizid }: UseQuizProps = {}) {
  const { data, ...query } = useMoodleAPI<ModQuizGetQuizzesByCoursesQuery, ModQuizGetQuizzesByCoursesData>(
    !isNil(courseid) && {
      wsfunction: 'mod_quiz_get_quizzes_by_courses',
      courseids: [courseid]
    }
  );

  const quiz = useMemo(() => data?.quizzes.find((i) => i.id === quizid), [quizid, data]);

  return {
    quiz,
    ...query
  };
}
