import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import { CardBaseProps, CardContentSkeleton, CardLayout, CardTitle } from '../_shared';
import { CardText } from '../_shared/CardText';
import { Tag, TagProps, TagSize } from '../../Tag';
import { ProgressLine, ProgressLineSize } from '../../Progress';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { Text, TextColor, TextVariant } from '../../Text';
import s from './CardCourse.module.scss';

export interface CardCourseProps extends CardBaseProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Массив тегов
   */
  tags?: TagProps[];
  /**
   * Прогресс курса (0-100)
   */
  progress?: number;
}

export const CardCourse: ReactFCC<CardCourseProps> = (props) => {
  const { className, title, content, tags, progress, loading, size, ...layoutProps } = props;

  const isMobile = useIsMobile();

  return (
    <CardLayout className={clsx(s.CardCourse, className)} loading={loading} size={size} {...layoutProps}>
      <div className={s.CardCourse__tags}>
        {!loading && tags?.map((tag, index) => <Tag size={TagSize.small} {...tag} key={index} />)}
      </div>

      <div className={s.CardCourse__content}>
        {loading ? (
          <CardContentSkeleton />
        ) : (
          <>
            <CardTitle className={s.CardCourse__title} size={size}>
              {title}
            </CardTitle>

            <CardText className={s.CardCourse__text} content={content} />
          </>
        )}
      </div>

      {!loading && (
        <div className={s.CardCourse__progress}>
          <ProgressLine
            className={s.CardCourse__progressLine}
            size={isMobile ? ProgressLineSize.medium : ProgressLineSize.large}
            value={progress}
          />
          <Text variant={TextVariant.BODY_S} colorVariant={TextColor.SECONDARY_70}>
            {progress}%
          </Text>
        </div>
      )}
    </CardLayout>
  );
};
