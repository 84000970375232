import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';

export const useResetBoundaryOnRouteChange = () => {
  const { pathname } = useLocation();
  const { resetBoundary } = useErrorBoundary();

  useLayoutEffect(() => {
    resetBoundary();
  }, [pathname, resetBoundary]);
};
