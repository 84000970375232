import clsx from 'clsx';
import { ReactFCC } from '../../../../utils/ReactFCC';
import s from './CardText.module.scss';

export interface CardTitleProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Содержимое текста карточки (выводится через dangerouslySetInnerHTML!)
   */
  content?: string;
}

export const CardText: ReactFCC<CardTitleProps> = (props) => {
  const { className, content } = props;

  return <p className={clsx(s.CardText, className)} dangerouslySetInnerHTML={{ __html: content || '' }} />;
};
