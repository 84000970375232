import React from 'react';
import clsx from 'clsx';
import { intervalToDuration } from 'date-fns';
import { Icon } from 'components/Icon';
import { Icons } from 'components/Icons';
import { Text, TextColor, TextVariant } from 'components/Text';
import { Heading, HeadingSize } from 'components/Heading';
import { joinNonEmpty } from 'utils/joinNonEmpty';
import { useQuizTimer } from '../../_hooks/useQuizTimer';
import s from './QuizTimer.module.scss';

const zeroPad = (num: number) => String(num).padStart(2, '0');

function formatDuration(ms: number) {
  const hasHours = ms >= 60 * 60 * 1000;
  const hasMinutes = ms >= 60 * 1000;
  const duration = intervalToDuration({ start: 0, end: ms });
  return joinNonEmpty(
    [
      hasHours && zeroPad(duration.hours || 0),
      hasMinutes && zeroPad(duration.minutes || 0),
      zeroPad(duration.seconds || 0)
    ],
    ':'
  );
}

export interface QuizTimerProps {
  endTime: number;
  onTimeEnd: () => void;
}

export const QuizTimer = ({ endTime, onTimeEnd }: QuizTimerProps) => {
  const time = useQuizTimer({
    endtime: endTime,
    onTimeEnd
  });

  return (
    <div className={s.QuizTimer}>
      <Icon className={s.QuizTimer__icon} icon={Icons.CLOCK} />
      <Text className={s.QuizTimer__label} variant={TextVariant.BODY_M} colorVariant={TextColor.SECONDARY_70}>
        Осталось времени:
      </Text>
      <Heading
        className={clsx(s.QuizTimer__time, {
          [s.QuizTimer__time_bad]: time <= 60
        })}
        size={HeadingSize.H5}>
        {formatDuration(time * 1000)}
      </Heading>
    </div>
  );
};
