import { useEffect, useRef, useState } from 'react';
import { ModQuizGetAttemptDataData } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_data';
import { ISummaryQuestion } from 'store/moodleAPI/moodleTypes/_functions/mod_quiz_get_attempt_summary';

export type UseQuizPaginationData = NonNullable<ReturnType<typeof useQuizPagination>>;

export type UseQuizPaginationDataProps = {
  attemptData: ModQuizGetAttemptDataData | null;
  questions: ISummaryQuestion[];
  page: number;
  onPageChange: (page: number) => void;
};

type QuizPageData = {
  pagesCount: number | undefined;
  /**
   * { [page]: slots array }
   */
  layoutMap: Record<string, string[]> | undefined;
};

export function useQuizPagination({ attemptData, questions, page, onPageChange }: UseQuizPaginationDataProps) {
  const [pageData, setPageData] = useState<QuizPageData>({
    pagesCount: undefined,
    layoutMap: undefined
  });

  const onceRef = useRef(false);

  useEffect(() => {
    if (attemptData && questions.length && !onceRef.current) {
      const { attempt } = attemptData;

      const layout = (attempt.layout ?? '')
        .replace(/,0$/, '')
        .split(',0,')
        .map((i) => i.replaceAll(/^,|,$/g, '').split(','));

      const layoutMap = Object.fromEntries(layout.map((item, index) => [index, item]));
      const pagesCount = layout.length;

      setPageData({
        layoutMap,
        pagesCount
      });

      onceRef.current = true;
    }
  }, [attemptData, questions]);

  const hasPrevPage = page > 0;
  const hasNextPage = !!attemptData?.nextpage && attemptData.nextpage !== -1;

  const setPage = (page: number) => {
    onPageChange(page);
  };

  const goPrevPage = () => {
    if (hasPrevPage) {
      setPage(page - 1);
    }
  };

  const goNextPage = () => {
    if (hasNextPage) {
      setPage(page + 1);
    }
  };

  return {
    ...pageData,
    hasPrevPage,
    hasNextPage,
    setPage,
    goPrevPage,
    goNextPage
  };
}
