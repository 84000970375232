import React from 'react';
import { MoodleContent } from 'components/MoodleContent/MoodleContent';
import { QuizQuestionTypeProps } from '../types';
import { QuestionLayout } from '../QuestionLayout/QuestionLayout';

export function DescriptionQuestion({ id, description, prompt }: QuizQuestionTypeProps) {
  return (
    <QuestionLayout id={id} title={'Информационный блок'} prompt={prompt}>
      <MoodleContent>{description}</MoodleContent>
    </QuestionLayout>
  );
}
