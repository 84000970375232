import stubImage from 'assets/images/stub.png';
import { ReactFCC } from '../../utils/ReactFCC';
import { Head } from '../../components/Head';
import { Text, TextColor, TextVariant } from '../../components/Text';
import s from './TestsPage.module.scss';

export const TestsPage: ReactFCC = () => {
  return (
    <>
      <Head title={'Тестирование'} />

      <div className={s.TestsPage}>
        <img className={s.TestsPage__image} src={stubImage} alt={'stub'} />

        <Text className={s.TestsPage__text} variant={TextVariant.BODY_M} colorVariant={TextColor.SECONDARY_70}>
          Раздел находится в разработке
        </Text>
      </div>
    </>
  );
};
