import { ReactComponent as Spinner } from 'assets/icons/spinner.svg';
import { ReactComponent as EyeConturOpen } from 'assets/icons/eye-contur-open.svg';
import { ReactComponent as EyeConturClosed } from 'assets/icons/eye-contur-closed.svg';
import { ReactComponent as Forward } from 'assets/icons/forward.svg';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { ReactComponent as Learning } from 'assets/icons/learning.svg';
import { ReactComponent as Test } from 'assets/icons/test.svg';
import { ReactComponent as Burger } from 'assets/icons/burger.svg';
import { ReactComponent as Cancel } from 'assets/icons/cancel.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as Check } from 'assets/icons/check.svg';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as Messages } from 'assets/icons/messages.svg';
import { ReactComponent as Video } from 'assets/icons/video.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Attachment } from 'assets/icons/attachment.svg';
import { ReactComponent as Checkbox } from 'assets/icons/checkbox.svg';
import { ReactComponent as TrashCan } from 'assets/icons/trash-can.svg';

export enum EIconsTypes {
  SPINNER = 'SPINNER',
  EYE_CONTUR_OPEN = 'EYE_CONTUR_OPEN',
  EYE_CONTUR_CLOSED = 'EYE_CONTUR_CLOSED',
  FORWARD = 'FORWARD',
  HOME = 'HOME',
  LEARNING = 'LEARNING',
  TEST = 'TEST',
  BURGER = 'BURGER',
  CANCEL = 'CANCEL',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  USER = 'USER',
  LOGOUT = 'LOGOUT',
  CHECK = 'CHECK',
  DOWNLOAD = 'DOWNLOAD',
  MESSAGES = 'MESSAGES',
  VIDEO = 'VIDEO',
  CLOCK = 'CLOCK',
  ATTACHMENT = 'ATTACHMENT',
  CHECKBOX = 'CHECKBOX',
  TRASH_CAN = 'TRASH_CAN'
}

type IconsMap = {
  [key in EIconsTypes]: JSX.Element;
};

export const ICONS_MAP: IconsMap = {
  [EIconsTypes.SPINNER]: <Spinner />,
  [EIconsTypes.EYE_CONTUR_OPEN]: <EyeConturOpen />,
  [EIconsTypes.EYE_CONTUR_CLOSED]: <EyeConturClosed />,
  [EIconsTypes.FORWARD]: <Forward />,
  [EIconsTypes.HOME]: <Home />,
  [EIconsTypes.LEARNING]: <Learning />,
  [EIconsTypes.TEST]: <Test />,
  [EIconsTypes.BURGER]: <Burger />,
  [EIconsTypes.CANCEL]: <Cancel />,
  [EIconsTypes.CHEVRON_RIGHT]: <ChevronRight />,
  [EIconsTypes.CHEVRON_LEFT]: <ChevronLeft />,
  [EIconsTypes.USER]: <User />,
  [EIconsTypes.LOGOUT]: <Logout />,
  [EIconsTypes.CHECK]: <Check />,
  [EIconsTypes.DOWNLOAD]: <Download />,
  [EIconsTypes.MESSAGES]: <Messages />,
  [EIconsTypes.VIDEO]: <Video />,
  [EIconsTypes.CLOCK]: <Clock />,
  [EIconsTypes.ATTACHMENT]: <Attachment />,
  [EIconsTypes.CHECKBOX]: <Checkbox />,
  [EIconsTypes.TRASH_CAN]: <TrashCan />
};

export const Icons = {
  ...EIconsTypes
};

export type IconTypes = keyof typeof Icons;
