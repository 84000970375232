import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { ReactFCC } from '../../utils/ReactFCC';
import { Head } from '../../components/Head';
import { Heading, HeadingSize } from '../../components/Heading';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { useIsMobile } from '../../hooks/useIsMobile';
import { CardFile, CardGrid, CardLesson, CardSize } from '../../components/Card';
import { COURSE_PAGE_PARAM, COURSES_PAGE_ROUTE, PathBuilder } from '../../app/routes';
import { MoodleError, MoodleErrorType, useCourse, useReadableModules } from '../../store/moodleAPI';
import { useUrlParam } from '../../hooks/useUrlParam';
import { ModuleName } from '../../store/moodleAPI/moodleTypes/Module';
import { Icons } from '../../components/Icons';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../components/IconButton';
import { Text, TextColor, TextVariant } from '../../components/Text';
import { Progress } from '../../components/Progress';
import { htmlToInlineText } from '../../utils/stringUtils';
import { checkIsSystemCourse } from '../../store/moodleAPI/utils/checkIsSystemCourse';
import { checkIsModuleCompleted } from '../../store/moodleAPI/utils/checkIsModuleCompleted';
import s from './CoursePage.module.scss';

export const CoursePage: ReactFCC = () => {
  const id = useUrlParam(COURSE_PAGE_PARAM, { parser: (value: string) => Number(value) || void 0 });

  const isDesktop = useIsDesktop();
  const isMobile = useIsMobile();

  const { state } = useLocation();

  const { showBoundary } = useErrorBoundary();

  const { course } = useCourse(!!id && (!state?.name || !state?.description) ? id : undefined);

  const courseInfo = useMemo(() => {
    if (course) {
      return {
        name: course.shortname,
        description: htmlToInlineText(course.summary)
      };
    }

    return {
      name: state?.name as string,
      description: state?.description as string
    };
  }, [course, state]);

  const { modules, isLoading, error } = useReadableModules(id);

  const { totalLength, completedLength, progress } = useMemo(() => {
    const completedLength = modules.filter((item) => checkIsModuleCompleted(item.completiondata?.state)).length;
    const progress = modules.length ? Math.floor((completedLength / modules.length) * 100) : 0;

    return {
      totalLength: modules.length,
      completedLength,
      progress
    };
  }, [modules]);

  const isSystemCourse = !!course && checkIsSystemCourse(course.shortname);

  if (!id || isSystemCourse) {
    showBoundary(new MoodleError("The module haven't been found", MoodleErrorType.ERR_NOT_FOUND));
    return null;
  }

  if (error) {
    showBoundary(error);
    return null;
  }

  return (
    <>
      <Head title={courseInfo.name} />

      <div className={s.CoursePage}>
        <div className={s.CoursePage__header}>
          <div className={s.CoursePage__title}>
            <IconButton
              component={Link}
              to={COURSES_PAGE_ROUTE}
              icon={Icons.CHEVRON_LEFT}
              variant={IconButtonVariant.ghost}
              size={IconButtonSize.small}
            />

            <Heading size={isDesktop ? HeadingSize.H3 : HeadingSize.H2}>{courseInfo.name}</Heading>
          </div>

          <Text
            className={s.CoursePage__description}
            variant={TextVariant.BODY_M}
            colorVariant={TextColor.SECONDARY_60}>
            {courseInfo.description}
          </Text>

          <Progress className={s.CoursePage__progress} value={progress} counts={[completedLength, totalLength]} />
        </div>

        <CardGrid
          className={s.CoursePage__content}
          loading={isLoading}
          component={CardLesson}
          items={modules.map((item) => {
            const description = item.modname === ModuleName.page ? item.intro : item.description;
            const descriptionInline = htmlToInlineText(description || '');

            const fileSize =
              item.modname === ModuleName.resource
                ? item.resources?.reduce((size, file) => size + file.size, 0)
                : undefined;

            const props =
              item.modname === ModuleName.resource
                ? {
                    component: CardFile,
                    fileLength: item.resources?.length,
                    fileSize: fileSize
                  }
                : {
                    content:
                      descriptionInline.length > 150 ? `${descriptionInline.slice(0, 150)}...` : descriptionInline,
                    completed: checkIsModuleCompleted(item.completiondata?.state)
                  };

            return {
              component: item.modname === ModuleName.resource ? CardFile : CardLesson,
              to: PathBuilder.getLessonPath(id, item.id),
              size: isMobile ? CardSize.medium : CardSize.large,
              title: item.name,
              ...props
            };
          })}
        />
      </div>
    </>
  );
};
