import React from 'react';
import qs from 'qs';
import axios, { AxiosError } from 'axios';
import { LOCAL_STORAGE_KEY_AUTH } from 'app/localStorageKeys';
import { singleton } from 'utils/singleton';
import { API_URL } from 'config';
import { login, LoginPayload } from 'pages/_moodleSync/model/login';
import { logout } from 'pages/_moodleSync/model/logout';
import { checkProfile } from 'pages/_moodleSync/model/check-profile';
import { getLmsLink, toLmsPage } from 'pages/_moodleSync/model/to-lms-page';
import { AuthContext, IAuthContext, IAuthData } from 'app/providers/auth/AuthContext';
import { AuthenticationError, AuthenticationErrorMessage } from 'app/providers/auth/AuthenticationError';

// парсит данные об авторизации из localStorage
// TODO: верифицировать формат authData.
const parseAuthData = (authData: string) => JSON.parse(authData) as IAuthData;

export class AuthProvider extends React.Component<{ children: React.ReactNode }, IAuthContext> {
  client = axios.create({ baseURL: API_URL });

  private iframeRef = React.createRef<HTMLIFrameElement>();

  isLoggedIn = () => !!this.state.authData.accessToken;

  setToken = (accessToken: string | null, expires: number = 0) => {
    this.setState({ authData: { accessToken, expires } });
    // сохраняет новое значение в localStorage
    this.authData = JSON.stringify({ accessToken, expires });
    localStorage.setItem(LOCAL_STORAGE_KEY_AUTH, this.authData);
  };

  getToken = async () => {
    return this.state.authData.accessToken;
  };

  logIn = singleton(async (username: string, password: string) => {
    if (!this.state.loading) {
      if (!username || !password) return;

      try {
        this.setState({ loading: true });

        const result = await this.client({
          url: '/login/token.php',
          method: 'POST',
          data: qs.stringify({
            username,
            password,
            service: 'moodle_mobile_app'
          })
        });

        if ('error' in result.data) {
          const error = new AuthenticationError(AuthenticationErrorMessage.ERR_AUTH);
          this.setState({ loading: false, error });
          throw error;
        }

        await this.lmsUpdateLogin({
          username,
          password
        });

        const authData = { token: result.data.token };

        this.setToken(result.data.token);
        this.setState({ loading: false, error: null });

        return authData;
      } catch (err: any) {
        let error = err;

        if (err instanceof AxiosError && err.code === AxiosError.ERR_NETWORK) {
          error = new AuthenticationError(AuthenticationErrorMessage.ERR_NETWORK);
        } else if (!(err instanceof AuthenticationError)) {
          error = new AuthenticationError(AuthenticationErrorMessage.ERR_UNKNOWN);
        }

        this.setState({ loading: false, error });
        throw error;
      }
    }
  });

  logOut = (error?: Error) => {
    this.setToken(null);
    if (error) this.setState({ error });
  };

  checkAuth = async (username: string, email: string) => {
    return await this.lmsCheckAuth(username, email);
  };

  // сырые данные из localStorage
  authData = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH);

  state: IAuthContext = {
    // пытаемся использовать данные из localStorage в качестве initialState
    authData: this.authData ? parseAuthData(this.authData) : { accessToken: null, expires: 0 },
    error: null,
    loading: false,
    isLoggedIn: this.isLoggedIn,
    setToken: this.setToken,
    getToken: this.getToken,
    logOut: this.logOut,
    logIn: this.logIn,
    checkAuth: this.checkAuth
  };

  componentDidMount() {
    // подписка на изменения в localStorage, чтобы отслеживать изменения из других вкладок –
    // например, выход из системы
    window.addEventListener('storage', this.handleStorageChange);
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.handleStorageChange);
  }

  handleStorageChange = () => {
    const authData = localStorage.getItem(LOCAL_STORAGE_KEY_AUTH);
    if (authData && authData !== this.authData) {
      const { accessToken, expires } = parseAuthData(authData);
      this.setToken(accessToken, expires);
    }
  };

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}

        <div style={{ display: 'none' }}>
          {/*
           В iframe в параметре sandbox задаются разрешения, необходимые для работы localStorage, необходимый
           Moodle для хранения параметров сессии (в частности, токен).
           */}
          <iframe
            src={getLmsLink(this.MOODLE_PROFILE_URL)}
            ref={this.iframeRef}
            id="moodle-login"
            title="Moodle login page"
            sandbox="allow-same-origin allow-scripts allow-forms"
            allow="*"
            width={1000}
            height={800}
          />
        </div>
      </AuthContext.Provider>
    );
  }

  private lmsUpdateLogin = async (data: LoginPayload) => {
    if (!this.iframeRef.current) {
      return;
    }

    await this.lmsToProfile();
    await logout(this.iframeRef.current.contentWindow);
    await login(data, this.iframeRef.current.contentWindow, (response) => response.logged);
  };

  private lmsCheckAuth = async (username: string, email: string): Promise<boolean> => {
    // Перестраховка - если у пользователя данных нет, значит что-то не так - лучше перелогиниться
    if (!username || !email) {
      return false;
    }

    const request = await this.lmsToProfile();
    if (request.location === '/index/login.php') {
      return false;
    }

    const checkProfileResponse = await checkProfile(
      {
        username,
        email
      },
      this.iframeRef.current!.contentWindow
    );

    if (!checkProfileResponse.logged || !checkProfileResponse.sameUser) {
      return false;
    }

    return true;
  };

  private MOODLE_PROFILE_URL = `/user/profile.php` as const;

  private lmsToProfile() {
    return toLmsPage(this.MOODLE_PROFILE_URL, this.iframeRef.current?.contentWindow);
  }
}
