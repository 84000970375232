import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Button, ButtonVariant } from 'components/Button';
import { Icons } from 'components/Icons';
import { IconButton, IconButtonVariant } from 'components/IconButton';
import { PaginationNumberButton } from 'components/pagination/PaginationNumberButton/PaginationNumberButton';
import { useIsMobile } from 'hooks/useIsMobile';
import { Text, TextColor, TextVariant } from 'components/Text';
import { UseQuizPaginationData } from '../../_hooks/useQuizPagination';
import s from './QuizFooter.module.scss';

export type QuizFooterProps = Required<
  Pick<UseQuizPaginationData, 'pagesCount' | 'hasPrevPage' | 'hasNextPage' | 'setPage'>
> & {
  page: number;
  pagesCount: number;
  onGoPrevPage: () => void;
  onGoNextPage: () => void;
  onFinishQuiz: () => void;
  isLoadingData?: boolean;
  isLoadingSubmit?: boolean;
  sequential: boolean;
};

const DISPLAY_PAGES = 5;

export function QuizFooter({
  pagesCount,
  sequential,
  page,
  hasPrevPage,
  hasNextPage,
  onGoPrevPage,
  onGoNextPage,
  onFinishQuiz,
  setPage,
  isLoadingData,
  isLoadingSubmit
}: QuizFooterProps) {
  const isMobile = useIsMobile();

  const prevButtonProps = {
    disabled: sequential || !hasPrevPage || isLoadingData,
    onClick: () => !isLoadingSubmit && onGoPrevPage()
  };

  const prevButtonComponent = isMobile ? (
    <IconButton icon={Icons.CHEVRON_LEFT} variant={IconButtonVariant.tertiary} {...prevButtonProps} />
  ) : (
    <Button leftIcon={Icons.CHEVRON_LEFT} variant={ButtonVariant.tertiary} {...prevButtonProps}>
      Назад
    </Button>
  );

  const nextButtonProps = {
    className: s.QuizFooter__control_next,
    disabled: isLoadingData || isLoadingSubmit,
    isLoading: isLoadingSubmit,
    onClick: () => (hasNextPage ? onGoNextPage() : onFinishQuiz())
  };

  const nextButtonComponent = isMobile ? (
    <IconButton icon={Icons.CHEVRON_RIGHT} variant={IconButtonVariant.secondary} {...nextButtonProps} />
  ) : (
    <Button rightIcon={Icons.CHEVRON_RIGHT} variant={ButtonVariant.secondary} {...nextButtonProps}>
      {hasNextPage ? 'Далее' : 'Завершить'}
    </Button>
  );

  const pageNumbers = useMemo(() => {
    return generatePageNumbers(pagesCount, page + 1, isMobile ? Infinity : DISPLAY_PAGES);
  }, [isMobile, page, pagesCount]);

  const pageNumbersComponent = pageNumbers.map((p, index) => (
    <PaginationNumberButton
      key={index}
      className={s.QuizFooter__paginationNumber}
      selected={p - 1 === page}
      disabled={p === -1 || sequential}
      onClick={() => !isLoadingSubmit && p !== -1 && setPage(p - 1)}>
      {p === -1 ? '...' : p}
    </PaginationNumberButton>
  ));

  return (
    <div className={s.QuizFooter}>
      <div className={s.QuizFooter__controls}>
        <div>{prevButtonComponent}</div>

        <div className={s.QuizFooter__pagination}>
          {isMobile ? (
            <Text
              className={s.QuizFooter__paginationText}
              variant={TextVariant.BODY_M}
              colorVariant={TextColor.SECONDARY_60}>
              Вопрос {page + 1}
            </Text>
          ) : (
            pageNumbersComponent
          )}
        </div>

        <div>{nextButtonComponent}</div>
      </div>

      {isMobile && (
        <div className={clsx(s.QuizFooter__pagination, s.QuizFooter__pagination_scroll)}>{pageNumbersComponent}</div>
      )}
    </div>
  );
}

function generatePageNumbers(totalPages: number, currentPage: number, displayCount: number = DISPLAY_PAGES) {
  const pages: number[] = [];
  const halfDisplay = Math.floor(displayCount / 2);

  if (totalPages <= displayCount) {
    // Все страницы помещаются на экране
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    // Прокрутка для большого количества страниц
    if (currentPage <= halfDisplay) {
      // Ближе к началу
      for (let i = 1; i <= displayCount - 1; i++) {
        pages.push(i);
      }
      pages.push(-1);
      pages.push(totalPages);
    } else if (currentPage >= totalPages - halfDisplay) {
      // Ближе к концу
      pages.push(1);
      pages.push(-1);
      for (let i = totalPages - displayCount + 2; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Страницы посередине
      pages.push(1);
      pages.push(-1);
      for (let i = currentPage - halfDisplay + 1; i <= currentPage + halfDisplay - 1; i++) {
        pages.push(i);
      }
      pages.push(-1);
      pages.push(totalPages);
    }
  }

  return pages;
}
