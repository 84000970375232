import { Ref } from 'react';
import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import s from './ProgressLine.module.scss';

export enum ProgressLineSize {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export interface ProgressLineProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Размер ("large", "medium", "small")
   */
  size?: ProgressLineSize;
  /**
   * Значение прогресса (1-100)
   */
  value?: number;
  /**
   * Реф на элемент с индикатором
   */
  valueRef?: Ref<HTMLDivElement>;
}

export const ProgressLine: ReactFCC<ProgressLineProps> = (props) => {
  const { className, size = ProgressLineSize.large, value = 0, valueRef } = props;

  return (
    <div className={clsx(s.ProgressLine, className, s[`ProgressLine_size_${size}`])}>
      <div className={s.ProgressLine__container}>
        <div className={s.ProgressLine__inner} style={{ width: `${value}%` }} ref={valueRef}></div>
      </div>
    </div>
  );
};
