import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useScormData } from '../../../store/moodleAPI/hooks/useScormData';
import { ScormContent } from '../../../components/ScormContent';
import { Loader, LoaderSize } from '../../../components/Loader';
import { useAuth } from '../../../app/providers/auth';
import { useUser } from '../../../app/providers/siteInfo';
import { scorm } from '../../_moodleSync/model/scorm';
import s from './LessonPageContentScorm.module.scss';

export interface LessonPageContentScormProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
  /**
   * Заголовок лекции
   */
  title?: string;
  /**
   * Scorm ID
   */
  scormId?: number;
  /**
   * Course module ID
   */
  moduleId?: number;
  /**
   * Sco ID
   */
  scoId?: number;
  innerRef?: React.Ref<HTMLDivElement>;
}

enum AuthingStatus {
  WaitData,
  Checking,
  Done
}

export const LessonPageContentScorm = forwardRef<HTMLDivElement, LessonPageContentScormProps>(
  function LessonPageContentScorm({ className, title, scormId, moduleId, scoId }, ref) {
    const { checkAuth, logOut } = useAuth();
    const { isLoading: isLoadingUser, error, data } = useUser();
    const [authStatus, setAuthStatus] = useState<AuthingStatus>(AuthingStatus.WaitData);
    const [checkedFor, setCheckedFor] = useState<number | null>(null);
    const [scormLoaded, setScormLoaded] = useState(false);

    const { isLoading: scormDataLoading } = useScormData(scormId, scoId);

    const isLoading = authStatus !== AuthingStatus.Done || scormDataLoading;

    const iframeRef = useRef<HTMLIFrameElement>(null);

    const authChecking = useCallback(() => {
      if (!data) {
        return;
      }

      setAuthStatus(AuthingStatus.Checking);
      checkAuth(data.fullname, data.email)
        .then((isAuthSame) => {
          if (isAuthSame) {
            setCheckedFor(scormId ?? null);

            // scorm(iframeRef.current!.contentWindow).then(() => {
            //   setScormLoaded(true);
            // });
          } else {
            logOut();
          }
        })
        .catch(() => {
          logOut();
        })
        .finally(() => {
          setAuthStatus(AuthingStatus.Done);
        });
    }, [data, checkAuth, scormId, logOut]);

    useEffect(() => {
      if (error !== null) {
        logOut();
        return;
      }

      if (isLoadingUser || !data || authStatus === AuthingStatus.Checking) {
        return;
      }

      if (checkedFor !== null && checkedFor === scormId) {
        return;
      }

      authChecking();
    }, [scormId, data, error, checkedFor, isLoadingUser, authStatus, logOut, authChecking]);

    useEffect(() => {
      if (!isLoading) {
        scorm(iframeRef.current!.contentWindow).then(() => {
          // Делаем задержку, потому что moodle-hook не успевает моментально внести изменения в dom
          setTimeout(() => {
            setScormLoaded(true);
          }, 500);
        });
      }
    }, [isLoading]);

    return (
      <>
        {(isLoading || !scormLoaded) && <Loader className={s.Loader} size={LoaderSize.large} />}
        {!isLoading && !!moduleId && !!scoId && (
          <ScormContent
            ref={ref}
            className={className}
            moduleId={moduleId}
            scoId={scoId}
            title={title}
            iframeRef={iframeRef}
            hidden={!scormLoaded}
          />
        )}
        {/*{!!moduleId && !!scoId && (*/}
        {/*  <ScormContent ref={ref} className={className} moduleId={moduleId} scoId={scoId} title={title} />*/}
        {/*)}*/}
      </>
    );
  }
);
