import { useMoodleAPI } from '../useMoodleAPI';
import {
  CoreCourseGetCoursesByFieldData,
  CoreCourseGetCoursesByFieldQuery
} from '../moodleTypes/_functions/core_course_get_courses_by_field';

export const useCourse = (courseId?: number) => {
  const { data, ...response } = useMoodleAPI<CoreCourseGetCoursesByFieldQuery, CoreCourseGetCoursesByFieldData>(
    !!courseId && {
      wsfunction: 'core_course_get_courses_by_field',
      field: 'id',
      value: courseId
    }
  );

  const course = data?.courses[0];

  return {
    data,
    course,
    ...response
  };
};
