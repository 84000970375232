import { useMoodleAPI } from '../useMoodleAPI';
import { ModScormLaunchScoData, ModScormLaunchScoQuery } from '../moodleTypes/_functions/mod_scorm_launch_sco';

export const useScormData = (scormId?: number, scoId?: number) => {
  const { isLoading, error } = useMoodleAPI<ModScormLaunchScoQuery, ModScormLaunchScoData>(
    !!scormId &&
      !!scoId && {
        wsfunction: 'mod_scorm_launch_sco',
        scormid: scormId,
        scoid: scoId
      }
  );

  return {
    isLoading,
    error
  };
};
