import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { DivPropsWithoutRef } from 'utils/types';
import { Heading, HeadingSize } from 'components/Heading';
import { Text, TextVariant } from 'components/Text';
import s from './QuestionLayout.module.scss';

export interface QuestionLayoutProps extends DivPropsWithoutRef {
  className?: string;
  title: string;
  prompt: string;
}

export function QuestionLayout({
  children,
  className,
  title,
  prompt,
  ...props
}: PropsWithChildren<QuestionLayoutProps>) {
  return (
    <div className={clsx(s.QuestionLayout, className)} {...props}>
      <div className={s.QuestionLayout__contentBox}>
        <div className={s.QuestionLayout__header}>
          <Heading size={HeadingSize.H4}>{title}</Heading>

          {prompt && (
            <Text className={s.QuestionLayout__prompt} variant={TextVariant.BODY_S}>
              {prompt}
            </Text>
          )}
        </div>

        <div className={s.QuestionLayout__content}>{children}</div>
      </div>
    </div>
  );
}
