import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { ReactFCC } from 'utils/ReactFCC';
import { Head } from 'components/Head';
import { IconButton, IconButtonSize, IconButtonVariant } from '../../components/IconButton';
import {
  COURSE_PAGE_PARAM,
  COURSES_PAGE_ROUTE,
  LESSON_PAGE_ROUTE,
  NOT_FOUND_ERROR_PAGE_ROUTE,
  QUIZ_PAGE_PARAM
} from '../../app/routes';
import { Icons } from '../../components/Icons';
import { Heading, HeadingSize } from '../../components/Heading';
import { useIsDesktop } from '../../hooks/useIsDesktop';
import { useBatchedQueryParam } from '../../app/providers/query-param';
import { tryNumberLike } from '../../utils/numbers/numberLike';
import { useCourseModule } from '../../store/moodleAPI/hooks/useCourseModule';
import { useQuiz } from '../../store/moodleAPI/hooks/useQuiz';
import { QuizAnswerType } from '../../store/quiz/quiz.types';
import { TabsQuiz } from '../lesson/lesson.types';
import { Loader, LoaderSize } from '../../components/Loader';
import { useQuizPagination, UseQuizPaginationDataProps } from './_hooks/useQuizPagination';
import { useQuizAttemptData } from './_hooks/useQuizAttemptData';
import { useQuizSubmit } from './_hooks/useQuizSubmit';
import { QuizTimer } from './_components/QuizTimer/QuizTimer';
import { QuizFooter } from './_components/QuizFooter/QuizFooter';
import { QuizQuestion } from './_components/QuizQuestion';
import s from './QuizPage.module.scss';

export const QuizPage: ReactFCC = () => {
  const navigate = useNavigate();
  const { courseId: paramCourseId, quizId: paramQuizId } = useParams<{
    [COURSE_PAGE_PARAM]: string;
    [QUIZ_PAGE_PARAM]: string;
  }>();
  const [queryCmid] = useBatchedQueryParam('cmid');
  const [queryAttemptId] = useBatchedQueryParam('attempt');
  const [queryPage, setQueryPage] = useBatchedQueryParam('page');

  const [pageProcessData, setPageProcessData] = useState<QuizAnswerType[]>([]);

  const courseId = Number(paramCourseId);
  const quizId = Number(paramQuizId);
  const moduleId = tryNumberLike(queryCmid, undefined, true);
  const attemptId = tryNumberLike(queryAttemptId, undefined, true);
  const page = tryNumberLike(queryPage, 0, true);

  const isDesktop = useIsDesktop();

  const { data: moduleData, isLoading: isModuleLoading, error: moduleError } = useCourseModule(moduleId);
  const module = moduleData?.cm;

  const {
    quiz,
    isLoading: isQuizLoading,
    error: quizError
  } = useQuiz({ courseid: courseId, quizid: module?.instance });

  const sequential = quiz?.navmethod === 'sequential';

  const {
    attemptData,
    accessData,
    questions,
    allQuestions,
    isLoading: isQuizAttemptLoading,
    error: quizAttemptError,
    clearCache,
    clearAllQuestions
  } = useQuizAttemptData(quizId, attemptId, page);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handlePageChange: UseQuizPaginationDataProps['onPageChange'] = useCallback(
    (page) => {
      setQueryPage(String(page || 0));
    },
    [setQueryPage]
  );

  const { pagesCount, setPage, hasPrevPage, hasNextPage, goPrevPage, goNextPage } = useQuizPagination({
    attemptData,
    questions: allQuestions,
    page,
    onPageChange: handlePageChange
  });

  const {
    submitQuiz,
    isLoading: isQuizSubmitLoading,
    isFinished
  } = useQuizSubmit({
    attemptData,
    pageProcessData
  });

  const backPath = useCallback(
    (tab: TabsQuiz = TabsQuiz.About) => {
      return (
        generatePath(LESSON_PAGE_ROUTE, {
          courseId,
          lessonId: moduleId
        }) + `?tab=${tab}`
      );
    },
    [courseId, moduleId]
  );

  const onGoNextPage = async () => {
    await submitQuiz();
    setPageProcessData([]);
    goNextPage();

    if (sequential) {
      clearAllQuestions();
    }
  };

  const onGoPage = async (page: number) => {
    await submitQuiz();
    setPageProcessData([]);
    setPage(page);
  };

  const onFinishQuiz = useCallback(async () => {
    await submitQuiz({ finish: true });
    clearCache();
    navigate(backPath(TabsQuiz.Result), { replace: true });
  }, [submitQuiz, navigate, backPath, clearCache]);

  const endTime = (!!quiz?.timelimit && accessData?.endtime) || null;

  const loading = isModuleLoading || isQuizAttemptLoading || isQuizLoading;
  const error = moduleError || quizAttemptError || quizError;

  if (error && !isFinished) {
    return <Navigate to={NOT_FOUND_ERROR_PAGE_ROUTE} replace />;
  }

  return (
    <>
      <Head title={'Тест'} />

      <div className={s.QuizPage}>
        <div className={s.Header}>
          <div className={s.Header__title}>
            <IconButton
              component={Link}
              to={quizId ? backPath() : COURSES_PAGE_ROUTE}
              icon={Icons.CHEVRON_LEFT}
              variant={IconButtonVariant.ghost}
              size={IconButtonSize.small}
            />

            <Heading size={isDesktop ? HeadingSize.H3 : HeadingSize.H2}>TEST 1</Heading>
          </div>
          {endTime !== null && (
            <div className={s.Header__timer}>
              <QuizTimer endTime={endTime} onTimeEnd={onFinishQuiz} />
            </div>
          )}
        </div>

        <div className={s.Content}>
          <div className={s.Content__inner}>
            {loading ? (
              <Loader className={s.Content__loader} size={LoaderSize.large} />
            ) : (
              questions.map((question, index) => (
                <QuizQuestion question={question} setPageProcessData={setPageProcessData} key={index} />
              ))
            )}
          </div>
        </div>

        <div className={s.Footer}>
          <QuizFooter
            pagesCount={pagesCount || 1}
            sequential={sequential}
            page={page}
            setPage={onGoPage}
            hasPrevPage={hasPrevPage}
            hasNextPage={hasNextPage}
            onGoPrevPage={goPrevPage}
            onGoNextPage={onGoNextPage}
            onFinishQuiz={onFinishQuiz}
            isLoadingData={loading}
            isLoadingSubmit={isQuizSubmitLoading}
          />
        </div>
      </div>
    </>
  );
};
