import clsx from 'clsx';
import { ReactFCC } from '../../../utils/ReactFCC';
import s from './SkeletonItem.module.scss';

export interface SkeletonItemProps {
  /**
   * Дополнительный css-класс
   */
  className?: string;
}

export const SkeletonItem: ReactFCC<SkeletonItemProps> = (props) => {
  const { className } = props;

  return <div className={clsx(s.SkeletonItem, className)} />;
};
